import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import RedInfoIcon from "@/assets/svgs/RedInfoIcon";
import SideBar from "@/components/layout/SideBar";
import AddWithdrawalMethod from "@/components/modals/AddWithdrawalMethod";
import AddPaypalMethodModal from "@/components/modals/AddPaypalMethodModal";
import AddaBankAccountModal from "@/components/modals/AddaBankAccountModal";
import SetupWithdrawalScheduleModal from "@/components/modals/SetupWithdrawalScheduleModal";
import paymentInformation from "@/rtk-apis/payments/paymentInformation";
import WithdrawMethodsView from "@/pages/Settings/Payments/withdrawMethodsView";
import WithdrawScheduleView from "@/pages/Settings/Payments/withdrawScheduleView";
import Loader from "@/elements/Loader";
import addPaypalDetails from "@/rtk-apis/payments/addPaypalDetails";
import { GetUserDetails } from "@/utils/helper";
import { closeRtkModal } from "@/store/slices/payments/addPaypalDetailsSlice";

const PaymentIndex = () => {
  const dispatch = useDispatch();
  const user = GetUserDetails();
  const [searchParams] = useSearchParams();
  const paypalToken = searchParams.get("id_token");
  const [openPaymentMethodModal, setOpenPaymentMethodModal] = useState(false);
  const [openAddBankAccountModal, setOpenAddBankAccountModal] = useState(false);
  const [
    openSetupWithdrawalScheduleModal,
    setOpenSetupWithdrawalScheduleModal,
  ] = useState(false);
  const [openPaypalMethodModal, setOpenPaypalMethodModal] = useState(false);
  const [withdrawMethods, setwithdrawMethods] = useState([]);

  const { data, loading, meta } = useSelector((globalState) => {
    return globalState.paymentInformation;
  });

  const addPaypalMethodData = useSelector((globalState) => {
    return globalState.addPaypalDetails;
  });

  useEffect(() => {
    getPaymentInformation();
  }, []);

  useEffect(() => {
    if (addPaypalMethodData?.meta?.status) {
      getPaymentInformation();
      dispatch(closeRtkModal());
    }
  }, [addPaypalMethodData?.meta?.status]);

  useEffect(() => {
    if (paypalToken) {
      dispatch(
        addPaypalDetails({
          recruiterId: user?.recruiter?.recruiterId,
          token: paypalToken,
        })
      );
    }
  }, [paypalToken]);

  const getPaymentInformation = () => {
    dispatch(paymentInformation());
  };

  const refreshPaymentInfo = () => {
    getPaymentInformation();
  };

  const addNewMethod = () => {
    setOpenPaymentMethodModal(true);
  };

  const openWithdrawlScheduleModal = (ACHData = "") => {
    if (ACHData) {
      let methods = [];
      methods.push(ACHData);
      setwithdrawMethods(methods);
    } else {
      setwithdrawMethods(data?.payPalDetail.concat(data?.achTransferDetail));
    }

    setOpenSetupWithdrawalScheduleModal(true);
  };

  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid layoutGrid--full">
          <div className="dataCol">
            <div className="dataCol">
              <div className="page page__full">
                <div className="page__head">
                  <h2 className="page__head--title">Payments</h2>
                </div>
                <div className="page__body">
                  <div className="details details--paymentDetails">
                    <section className="details__section">
                      {/* <h4 className="details__section--title">
                        Available Balance
                      </h4> */}
                      <div className="paymentDetail">
                        {/* <div className="price">
                          $0.00
                          <span className="price__pending">
                            +$0.00 pending <InfoIcon />
                          </span>
                        </div> */}
                        {data?.taxDetailsPresent ? (
                          ""
                        ) : (
                          <div className="errorMessage">
                            <p>
                              <RedInfoIcon /> 
                              <span>
                              To withdraw earnings, please
                              update your 
                              <Link className="link" to="/settings/taxinfo">
                                tax information.
                              </Link>
                              </span>
                            </p>
                          </div>
                        )}
                        <div className="paymentDetail__box">
                          <h5 className="title">
                            To withdraw earnings, first you need to set up a
                            withdrawal method.
                          </h5>
                          <p>
                            It may take up to 3 days to activate your withdrawal
                            method.
                          </p>
                          {data?.achTransferDetail?.length ||
                          data?.payPalDetail?.length ? (
                            <button 
                              className="btn btn__primary"
                              onClick={() => addNewMethod()}
                            >
                              Get paid now
                            </button>
                          ) : data?.taxDetailsPresent ? (
                            <button
                              className="btn btn__primary"
                              onClick={() => addNewMethod()}
                            >
                              Add a method
                            </button>
                          ) : ""}
                        </div>
                      </div>
                    </section>
                    {data?.achTransferDetail?.length ? (
                      <WithdrawScheduleView
                        withdrawScheduleInfo={data?.withdrawalSchedule}
                        openEditWithdrawl={() => openWithdrawlScheduleModal()}
                        refreshPage={refreshPaymentInfo}
                      />
                    ) : (
                      ""
                    )}
                    {data?.achTransferDetail?.length ||
                    data?.payPalDetail?.length ? (
                      <WithdrawMethodsView
                        AchInfo={data?.achTransferDetail}
                        refreshPage={refreshPaymentInfo}
                        addNewMethod={addNewMethod}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader />}
      {openPaymentMethodModal && (
        <AddWithdrawalMethod
          openModal={openPaymentMethodModal}
          closeModal={() => setOpenPaymentMethodModal(false)}
          setOpenAddBankAccountModal={() => setOpenAddBankAccountModal(true)}
          setOpenPaypalMethodModal={setOpenPaypalMethodModal}
        />
      )}
      {openAddBankAccountModal && (
        <AddaBankAccountModal
          openModal={openAddBankAccountModal}
          closeModal={() => {
            setOpenAddBankAccountModal(false);
            setOpenPaymentMethodModal(false);
          }}
          setOpenSetupWithdrawalScheduleModal={openWithdrawlScheduleModal}
          refreshPage={refreshPaymentInfo}
          showSeccondStep={
            data?.achTransferDetail?.length || data?.payPalDetail?.length
              ? false
              : true
          }
        />
      )}
      {openPaypalMethodModal && (
        <AddPaypalMethodModal
          openModal={openPaypalMethodModal}
          closeModal={() => {
            setOpenPaypalMethodModal(false);
          }}
        />
      )}
      {openSetupWithdrawalScheduleModal && (
        <SetupWithdrawalScheduleModal
          openModal={openSetupWithdrawalScheduleModal}
          closeModal={() => {
            setOpenPaymentMethodModal(false);
            setOpenSetupWithdrawalScheduleModal(false);
            setOpenAddBankAccountModal(false);
          }}
          refreshPage={refreshPaymentInfo}
          withdrawlMethods={withdrawMethods}
          defaultWithdrawalSchedule={data?.withdrawalSchedule}
        />
      )}
    </>
  );
};

export default PaymentIndex;
