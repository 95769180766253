import { createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "@/rtk-apis/axios-config/http-service";
import { GET_JOB_NOTES } from "@/rtk-apis/end-points";

const getCustomerNotes = createAsyncThunk("getCustomerNotes", async (body) => {
  console.log(body, "body");
  try {
    const response = await httpService.get(
      `${GET_JOB_NOTES}${body?.jobId}?id=${body?.recruiterId}&from=${body?.from}`,
      {}
    );
    return response;
  } catch (error) {
    return error;
  }
});

export default getCustomerNotes;
