import React, { useState, useEffect } from "react";
import SignUpStepOne from "@/components/signUp/SignUpStepOne";
import SignUpStepTwo from "@/components/signUp/SignUpStepTwo";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Loader from "@/elements/Loader";
import signUpRecruiter from "@/rtk-apis/signUpRecruiter";
import recruiterDetailsOnSignUp from "@/rtk-apis/signUp/recruiterDeailsOnSignUp";
import { SuccessModal } from "@/components/modals/SuccessModal";
import ErrorModal from "@/components/modals/error-modal";
import { closeRtkModal } from "@/store/slices/AuthSlice";
import { useNavigate, useSearchParams } from "react-router-dom";

function SignUp() {
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isStepTwo, setStepTwo] = useState(false);
  const [stepOneValues, setStepOneValues] = useState({});

  const { loading, meta } = useSelector((globalState) => {
    return globalState.auth;
  });

  const { data } = useSelector((globalState) => {
    return globalState.recruiterDetailsOnSignUpResponse;
  });

  const goToStepTwo = (props) => {
    setStepOneValues(props);
    setStepTwo(true);
  };

  const closeModal = () => {
    dispatch(closeRtkModal());
    navigate("/signin");
  };

  const finishSignUp = ({
    education,
    institutionName,
    majorStudy,
    skillSet,
    experience,
    linkedin,
    graduationYear,
    qualificationArray
  }) => {
    const { firstName, lastName, email, phoneNumber, password } = stepOneValues;
    dispatch(
      signUpRecruiter({
        firstName,
        lastName,
        email,
        password,
        phoneNumber,
        education,
        institutionName,
        majorStudy,
        skillSet,
        experience: experience.toString(),
        linkedin,
        graduationYear,
        countryCode: "+1",
        role: "recruiter",
        qualificationArray
      })
    );
  };

  const closeErrorModal = () => {
    dispatch(closeRtkModal());
  };

  useEffect(() => {
    if (searchParams?.size && searchParams.get("email")) {
      getSignUpDetails(searchParams.get("email"));
    }
  }, []);

  const getSignUpDetails = (email) => {
    dispatch(recruiterDetailsOnSignUp(email));
  };

  return (
    <>
      {!isStepTwo && (
        <SignUpStepOne
          data={data}
          goToStepTwo={(props) => goToStepTwo(props)}
        />
      )}
      {isStepTwo && (
        <SignUpStepTwo
          data={data}
          finishSignUp={(props) => finishSignUp(props)}
          goBack={()=>setStepTwo(false)}
        />
      )}
      {loading && <Loader />}
      {meta?.status && (
        <SuccessModal
          sucessfullMsg={meta.msg}
          Heading={"Signed up successfully!"}
          closeModal={closeModal}
        />
      )}
      {meta?.status === false && (
        <ErrorModal
          errorMessage={meta?.msg}
          closeModal={closeErrorModal}
          footerVisible={true}
        />
      )}
    </>
  );
}

export default SignUp;
