import React, { useEffect, useState } from "react";
import BackIcon from "@/assets/svgs/BackIcon";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import ErrorModal from "@/components/modals/error-modal";
import { SuccessModal } from "@/components/modals/SuccessModal";
import ProfileIcon from "@/assets/svgs/ProfileIcon";
import EmailIcon from "@/assets/svgs/EmailIcon";
import PhoneIcon from "@/assets/svgs/PhoneIcon";
import DeleteIcon from "@/assets/svgs/DeleteIcon";
import EducationSvg from "@/assets/svgs/EducationSvg";
import InstitutionSvg from "@/assets/svgs/InstitutionSvg";
import { Formik, Form, Field } from "formik";
import { recruiterFormSchema } from "@/components/schema/schema";
import { useDispatch, useSelector } from "react-redux";
import Loader from "@/elements/Loader";
import staticData from "@/rtk-apis/staticData";
import { GetUserDetails, getYears } from "@/utils/helper";
import updateRecruiter from "@/rtk-apis/recruiterProfile/updateRecruiter";
import recruiterDetails from "@/rtk-apis/recruiterProfile/recruiterDetails";
import { closeRtkModal } from "@/store/slices/recruiterProfile/updateRecruiterSlice";
import ArrowDown from "@/assets/svgs/ArrowDown";
import Select from "@mui/material/Select";
import { GRADUATION_MONTH } from "@/utils/constants";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import UniversitySvg from "@/assets/svgs/UniversitySvg";
import { INSTITUTION_LOGO_BUCKET } from "@/utils/constants";
import PlusIcon from "@/assets/svgs/PlusIcon";

function MyProfileEdit({ editMode }) {
  const user = GetUserDetails();
  const dispatch = useDispatch();

  const { data } = useSelector((globalState) => {
    return globalState.static;
  });

  const { data: userDetails } = useSelector((globalState) => {
    return globalState.recruiterDetailsResponse;
  });

  const [initialValues, setInitialValues] = useState({
    firstName: user?.recruiter?.firstName || "",
    lastName: user?.recruiter?.lastName || "",
    phoneNumber: user?.recruiter?.phoneNumber || "",
    email: user?.recruiter?.email || "",
    experience: user?.recruiter?.experience || "",
    linkedin: user?.recruiter?.linkedin || "",
    education: user?.recruiter?.education || "",
    institutionName: user?.recruiter?.institutionName || "",
    graduationYear: user?.recruiter?.graduationYear || "",
    majorStudy: user?.recruiter?.majorStudy || "",
    countryCode: "+1",
    skillSet: user?.recruiter?.skillSet || [],
    qualificationArray: user?.recruiter?.qualificationArray || [],
  });

  const { loading, meta } = useSelector((state) => {
    return state.updateRecruiterResponse;
  });

  useEffect(() => {
    dispatch(closeRtkModal());
    dispatch(staticData());
    getDetails();
  }, []);

  useEffect(() => {
    setInitialValues({
      ...initialValues,
      qualificationArray: userDetails?.qualificationArray,
    });
  }, [userDetails]);

  const getDetails = () => {
    dispatch(recruiterDetails());
  };

  const handleFormSubmit = async (values) => {
    let body = {
      ...values,
      countryCode: values?.countryCode ?? "",
      recruiterId: user?.recruiter?.recruiterId,
    };
    body.graduationYear =
      body?.graduationYear && body.graduationYear.toString();
    delete body.email;
    dispatch(updateRecruiter(body));
  };

  const closeModal = () => {
    dispatch(closeRtkModal());
    editMode();
  };

  const backToDetails = () => {
    editMode();
  };

  const handleEduDetailsChange = (
    index,
    field,
    newValue,
    qualificationArray
  ) => {
    if(field == "institutionName"){
      qualificationArray[index]['logoName'] = getUnivercityLogo(newValue);
    }
    qualificationArray[index][field] = newValue;
    setInitialValues({
      ...initialValues,
      qualificationArray: qualificationArray,
    });
    return qualificationArray;
  };

  const deleteEducationalDetail = (index, qualificationArray) => {
    qualificationArray.splice(index, 1);
    setInitialValues({
      ...initialValues,
      qualificationArray: qualificationArray,
    });
    return qualificationArray;
  };

  const getUnivercityLogo = (univercityName) => {
    let logo =
      data?.nameOfInstitutionObject?.length &&
      data?.nameOfInstitutionObject.find(
        (company) => company.value === univercityName
      )?.logoName;
    return logo;
  };

  const addEducationalDetails = (valuesQualificationArray) => {
    let qualificationArray = JSON.parse(
      JSON.stringify(valuesQualificationArray)
    );
    qualificationArray.splice(0, 0, {
      institutionName: "",
      degree: "",
      fieldOfStudy: "",
      startMonth: "",
      startYear: "",
      endMonth: "",
      endYear: "",
    });
    return qualificationArray;
  };

  function isEmpty(arr, errors) {
    let arrIndex = 0;
    for (const item of arr) {
      for (const value in item) {
        if (!item[value]) {
          if (value == "degree") {
            errors[
              `qualificationArray${arrIndex}degree`
            ] = `Education name is required`;
          } else if (value == "institutionName") {
            errors[
              `qualificationArray${arrIndex}institutionName`
            ] = `Institution name is required`;
          } else if (value == "fieldOfStudy") {
            errors[
              `qualificationArray${arrIndex}majorStudy`
            ] = `Major/Field of study is required`;
          } else if (value == "startMonth") {
            errors[
              `qualificationArray${arrIndex}startMonth`
            ] = `Start month is required`;
          } else if (value == "startYear") {
            errors[
              `qualificationArray${arrIndex}startYear`
            ] = `Start year is required`;
          } else if (value == "endMonth") {
            errors[
              `qualificationArray${arrIndex}endMonth`
            ] = `End month is required`;
          } else if (value == "endYear") {
            errors[
              `qualificationArray${arrIndex}endYear`
            ] = `End year is required`;
          } 
        }
      }
      arrIndex++;
    }
    return errors;
  }

  return (
    <>
      <div className="dataCol">
        <div className="page page__full">
          <Formik
            initialValues={initialValues}
            validationSchema={recruiterFormSchema}
            onSubmit={(values) => handleFormSubmit(values)}
          >
            {({
              errors,
              values,
              handleChange,
              handleBlur,
              touched,
              setFieldValue,
              setErrors,
            }) => (
              <Form className="form">
                <div className="page__head">
                  <h2 className="page__head--title">
                    <button
                      onClick={backToDetails}
                      className="btn btn__transparent btn__back border_0 p_0"
                    >
                      <BackIcon />
                    </button>
                    Edit Profile
                  </h2>
                  <div className="page__head--actions">
                    <button onClick={backToDetails} className="btn btn__white">
                      Cancel
                    </button>
                    <button type="submit" className="btn btn__primary">
                      Update
                    </button>
                  </div>
                </div>
                <div className="page__body p_2">
                  <section className="form__section">
                    <h4 className="form__section--title">Basic Details</h4>
                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.firstName && touched?.firstName
                              ? "fieldError"
                              : ""
                          }`}
                        >
                          <label className="formField__label">First Name</label>
                          <div className="formField__group sm">
                            <Field
                              type="text"
                              className="formField__input sm"
                              placeholder="First Name"
                              name="firstName"
                            />
                            <span className="formField__icon">
                              <ProfileIcon />
                            </span>
                          </div>
                          <span className="formField__error">
                            {errors?.firstName &&
                              touched?.firstName &&
                              errors?.firstName}
                          </span>
                        </div>
                      </div>

                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.lastName &&
                            touched?.lastName &&
                            errors?.lastName
                              ? "fieldError"
                              : ""
                          }`}
                        >
                          <label className="formField__label">Last Name</label>
                          <div className="formField__group sm">
                            <Field
                              type="text"
                              className="formField__input"
                              placeholder="Last Name"
                              name="lastName"
                              onBlur={handleBlur}
                            />
                            <span className="formField__icon">
                              <ProfileIcon />
                            </span>
                          </div>
                          <span className="formField__error">
                            {errors?.lastName &&
                              touched?.lastName &&
                              errors?.lastName}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.email && touched?.email && errors?.email
                              ? "fieldError"
                              : ""
                          }`}
                        >
                          <label className="formField__label">Email Id</label>
                          <div className="formField__group sm">
                            <Field
                              type="email"
                              className="formField__input sm"
                              placeholder=""
                              name="email"
                              disabled
                            />
                            <span className="formField__icon">
                              <EmailIcon />
                            </span>
                          </div>
                          <span className="formField__error">
                            {errors?.email && touched?.email && errors?.email}
                          </span>
                        </div>
                      </div>
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.phoneNumber &&
                            touched?.phoneNumber &&
                            errors?.phoneNumber
                              ? "fieldError"
                              : ""
                          }`}
                        >
                          <label className="formField__label">
                            Phone Number
                          </label>
                          <div className="formField__group sm">
                            <Field
                              type="text"
                              className="formField__input sm"
                              placeholder="Phone Number"
                              name="phoneNumber"
                              maxLength="10"
                            />
                            <span className="formField__icon">
                              <PhoneIcon />
                            </span>
                          </div>
                          <span className="formField__error">
                            {errors?.phoneNumber &&
                              touched?.phoneNumber &&
                              errors?.phoneNumber}
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="form__section">
                    <h4 className="form__section--title form__section--title--flex">
                      Educational Details
                      <button
                        type="button"
                        className="btn btn__white btn--sm"
                        onClick={() => {
                          let newArray = addEducationalDetails(
                            values?.qualificationArray
                          );
                          setFieldValue("qualificationArray", newArray);
                          let newErrors = isEmpty(newArray, errors);
                          setErrors(newErrors);
                        }}
                      >
                        <PlusIcon />
                      </button>
                    </h4>
                    <div className="educationalInfoList">
                      {values?.qualificationArray?.map((item, index) => {
                        return (
                          <>
                            <div className="educationalInfoList_row">
                              <div className="educationalInfoList_row_formbox">
                                <div className="formRow formRow__2">
                                  <div className="formCol">
                                    <div
                                      className={`formField ${
                                        errors[
                                          `qualificationArray${index}degree`
                                        ]
                                          ? "fieldError"
                                          : ""
                                      }`}
                                    >
                                      <label className="formField__label">
                                        Education
                                      </label>
                                      <Autocomplete
                                        id="tags-filled"
                                        options={data?.qualification}
                                        value={
                                          item?.education || item?.degree
                                        }
                                        name={`qualificationArray${index}degree`}
                                        onChange={(event, newValue) => {
                                          const newArray = handleEduDetailsChange(
                                            index,
                                            "degree",
                                            newValue,
                                            values?.qualificationArray
                                          );
                                          setFieldValue(
                                            "qualificationArray",
                                            newArray
                                          );
                                          if (!newValue) {
                                            const newErrors = errors;
                                            newErrors[
                                              `qualificationArray${index}degree`
                                            ] = "Education is required";
                                            setErrors(newErrors);
                                          } else {
                                            const newErrors = errors;
                                            delete newErrors[
                                              `qualificationArray${index}degree`
                                            ];
                                            setErrors(newErrors);
                                          }
                                        }}
                                        freeSolo
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="filled"
                                            placeholder="Select"
                                            className="autoCompleteInput"
                                            onBlur={(e) => {
                                              if (params?.inputProps?.value) {
                                                setFieldValue(
                                                  "education",
                                                  params.inputProps.value
                                                );
                                              }
                                              if (!params?.inputProps?.value) {
                                                const newErrors = errors;
                                                newErrors[
                                                  `qualificationArray${index}degree`
                                                ] = "Education is required";
                                                setErrors(newErrors);
                                              } else {
                                                const newErrors = errors;
                                                delete newErrors[
                                                  `qualificationArray${index}degree`
                                                ];
                                                setErrors(newErrors);
                                              }
                                            }}
                                          />
                                        )}
                                      />
                                      <span className="formField__error">
                                        {
                                          errors[
                                            `qualificationArray${index}degree`
                                          ]
                                        }
                                      </span>
                                    </div>
                                  </div>
                                  <div className="formCol">
                                    <div
                                      className={`formField formField__autoComplete formField__autoComplete--withLogo ${
                                        errors[
                                          `qualificationArray${index}institutionName`
                                        ]
                                          ? "fieldError"
                                          : ""
                                      }`}
                                    >
                                      <label className="formField__label">
                                        Name of Institution
                                      </label>
                                      <Autocomplete
                                        id="tags-filled"
                                        options={data?.nameOfInstitutionObject}
                                        getOptionLabel={(option) =>
                                          option?.value || ""
                                        }
                                        value={{value:item?.institutionName, logoUrl:item?.institutionNameLogo}}
                                        name={`qualificationArray${index}institutionName`}
                                        onChange={(event, newValue) => {
                                          const newArray = handleEduDetailsChange(
                                            index,
                                            "institutionName",
                                            newValue?.value,
                                            values?.qualificationArray
                                          );
                                          setFieldValue(
                                            "qualificationArray",
                                            newArray
                                          );
                                          if (!newValue) {
                                            const newErrors = errors;
                                            newErrors[
                                              `qualificationArray${index}institutionName`
                                            ] = "Institution name is required";
                                            setErrors(newErrors);
                                          } else {
                                            const newErrors = errors;
                                            delete newErrors[
                                              `qualificationArray${index}institutionName`
                                            ];
                                            setErrors(newErrors);
                                          }
                                        }}
                                        freeSolo
                                        renderOption={(props, option) => (
                                          <li {...props}>
                                            {option.logoName ? (
                                              <img
                                                src={
                                                  INSTITUTION_LOGO_BUCKET +
                                                  option.logoName
                                                }
                                                alt={option.value}
                                                style={{
                                                  width: 25,
                                                  marginRight: 5,
                                                }}
                                              />
                                            ) : (
                                              <div className="autocompletelogo">
                                                <UniversitySvg />
                                              </div>
                                            )}
                                            {option?.value || ""}{" "}
                                          </li>
                                        )}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="filled"
                                            placeholder="Select"
                                            className="autoCompleteInput"
                                            InputProps={{
                                              ...params.InputProps,
                                              startAdornment: (
                                                <>
                                                  {
                                                    params.InputProps
                                                      .startAdornment
                                                  }
                                                  {params.inputProps.value &&
                                                  getUnivercityLogo(
                                                    params.inputProps.value
                                                  ) ? (
                                                    <img
                                                      className="autocompletelogo"
                                                      src={
                                                        params.inputProps
                                                          .value &&
                                                        INSTITUTION_LOGO_BUCKET +
                                                          getUnivercityLogo(
                                                            params.inputProps
                                                              .value
                                                          )
                                                      }
                                                      alt="Selected"
                                                    />
                                                  ) : (
                                                    <div className="autocompletelogo">
                                                      <UniversitySvg />
                                                    </div>
                                                  )}
                                                </>
                                              ),
                                            }}
                                            onBlur={(e) => {
                                              if (params?.inputProps?.value) {
                                                setFieldValue(
                                                  "institutionName",
                                                  params.inputProps.value
                                                );
                                              }
                                              if (!params?.inputProps?.value) {
                                                const newErrors = errors;
                                                newErrors[
                                                  `qualificationArray${index}institutionName`
                                                ] =
                                                  "Institution name is required";
                                                setErrors(newErrors);
                                              } else {
                                                const newErrors = errors;
                                                delete newErrors[
                                                  `qualificationArray${index}institutionName`
                                                ];
                                                setErrors(newErrors);
                                              }
                                            }}
                                          />
                                        )}
                                      />
                                      <span className="formField__error">
                                        {
                                          errors[
                                            `qualificationArray${index}institutionName`
                                          ]
                                        }
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="formRow formRow__4">
                                  <div
                                    className={`formField ${
                                      errors[
                                        `qualificationArray${index}startMonth`
                                      ]
                                        ? "fieldError"
                                        : ""
                                    }`}
                                  >
                                    <label className="formField__label">
                                      Start Month
                                    </label>
                                    <FormControl
                                      sx={{ width: "100%" }}
                                      size="small"
                                      className="formField__select"
                                    >
                                      <Select
                                        name={`qualificationArray${index}startMonth`}
                                        onChange={(event) => {
                                          const newArray = handleEduDetailsChange(
                                            index,
                                            "startMonth",
                                            event?.target?.value,
                                            values?.qualificationArray
                                          );
                                          setFieldValue(
                                            "qualificationArray",
                                            newArray
                                          );
                                          if (!event?.target?.value) {
                                            const newErrors = errors;
                                            newErrors[
                                              `qualificationArray${index}startMonth`
                                            ] = "Start month is required";
                                            setErrors(newErrors);
                                          }
                                          else if(event?.target?.value && errors[`qualificationArray${index}startMonth`]){
                                            const newErrors = errors;
                                            delete newErrors[
                                              `qualificationArray${index}startMonth`
                                            ];
                                            setErrors(newErrors);
                                          }
                                        }}
                                        IconComponent={() => <ArrowDown />}
                                        displayEmpty
                                        value={item?.startMonth}
                                        renderValue={(selected) => {
                                          if (!selected) {
                                            return (
                                              <span style={{ opacity: "0.8" }}>
                                                Select Month
                                              </span>
                                            );
                                          }

                                          return selected;
                                        }}
                                      >
                                        {data?.yearMonth?.month.map((item) => (
                                          <MenuItem value={item}>
                                            {item}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      <span className="formField__error">
                                        {
                                          errors[
                                            `qualificationArray${index}startMonth`
                                          ]
                                        }
                                      </span>
                                    </FormControl>
                                  </div>
                                  <div 
                                    className={`formField ${
                                      errors[
                                        `qualificationArray${index}startYear`
                                      ]
                                        ? "fieldError"
                                        : ""
                                    }`}
                                  >
                                    <label className="formField__label">
                                      Start Year
                                    </label>
                                    <FormControl
                                      sx={{ width: "100%" }}
                                      size="small"
                                      className="formField__select"
                                    >
                                      <Select
                                        onChange={(event) => {
                                          const newArray = handleEduDetailsChange(
                                            index,
                                            "startYear",
                                            event?.target?.value,
                                            values?.qualificationArray
                                          );
                                          setFieldValue(
                                            "qualificationArray",
                                            newArray
                                          );
                                          if(event?.target?.value && errors[`qualificationArray${index}startYear`]){
                                              const newErrors = errors;
                                              delete newErrors[
                                                `qualificationArray${index}startYear`
                                              ];
                                              setErrors(newErrors);
                                          }
                                        }}
                                        IconComponent={() => <ArrowDown />}
                                        displayEmpty
                                        value={item?.startYear}
                                        renderValue={(selected) => {
                                          if (!selected) {
                                            return (
                                              <span style={{ opacity: "0.8" }}>
                                                Select Year
                                              </span>
                                            );
                                          }

                                          return selected;
                                        }}
                                      >
                                        {getYears(
                                          data?.yearMonth?.startYear
                                        ).map((item) => (
                                          <MenuItem value={item}>
                                            {item}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                    <span className="formField__error">
                                        {
                                          errors[
                                            `qualificationArray${index}startYear`
                                          ]
                                        }
                                      </span>
                                  </div>
                                  <div 
                                    className={`formField ${
                                      errors[
                                        `qualificationArray${index}endMonth`
                                      ]
                                        ? "fieldError"
                                        : ""
                                    }`}
                                  >
                                    <label className="formField__label">
                                      End Month
                                    </label>
                                    <FormControl
                                      sx={{ width: "100%" }}
                                      size="small"
                                      className="formField__select"
                                    >
                                      <Select
                                        onChange={(event) => {
                                          const newArray = handleEduDetailsChange(
                                            index,
                                            "endMonth",
                                            event?.target?.value,
                                            values?.qualificationArray
                                          );
                                          setFieldValue(
                                            "qualificationArray",
                                            newArray
                                          );
                                          if(event?.target?.value && errors[`qualificationArray${index}endMonth`]){
                                              const newErrors = errors;
                                              delete newErrors[
                                                `qualificationArray${index}endMonth`
                                              ];
                                              setErrors(newErrors);
                                          }
                                        }}
                                        IconComponent={() => <ArrowDown />}
                                        displayEmpty
                                        value={item?.endMonth}
                                        renderValue={(selected) => {
                                          if (!selected) {
                                            return (
                                              <span style={{ opacity: "0.8" }}>
                                                Select Month
                                              </span>
                                            );
                                          }

                                          return selected;
                                        }}
                                      >
                                        {data?.yearMonth?.month.map((item) => (
                                          <MenuItem value={item}>
                                            {item}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                    <span className="formField__error">
                                        {
                                          errors[
                                            `qualificationArray${index}endMonth`
                                          ]
                                        }
                                      </span>
                                  </div>
                                  <div 
                                    className={`formField ${
                                      errors[
                                        `qualificationArray${index}endYear`
                                      ]
                                        ? "fieldError"
                                        : ""
                                    }`}
                                  >
                                    <label className="formField__label">
                                      End Year
                                    </label>
                                    <FormControl
                                      sx={{ width: "100%" }}
                                      size="small"
                                      className="formField__select"
                                    >
                                      <Select
                                        onChange={(event) => {
                                          const newArray = handleEduDetailsChange(
                                            index,
                                            "endYear",
                                            event?.target?.value,
                                            values?.qualificationArray
                                          );
                                          setFieldValue(
                                            "qualificationArray",
                                            newArray
                                          );
                                          if(event?.target?.value && errors[`qualificationArray${index}endYear`]){
                                              const newErrors = errors;
                                              delete newErrors[
                                                `qualificationArray${index}endYear`
                                              ];
                                              setErrors(newErrors);
                                          }
                                          if(event?.target?.value === values?.qualificationArray[index]['startYear']){
                                            let startMonthIndex = values?.qualificationArray[index]['startMonth'] ? data?.yearMonth?.month?.indexOf(values?.qualificationArray[index]['startMonth'])+1 : "";
                                            let endMonthIndex = values?.qualificationArray[index]['endMonth'] ? data?.yearMonth?.month?.indexOf(values?.qualificationArray[index]['endMonth'])+1 : "";
                                            if(startMonthIndex && endMonthIndex && startMonthIndex > endMonthIndex){
                                              const newErrors = errors;
                                              newErrors[
                                                `qualificationArray${index}endYear`
                                              ] = "The start date cannot be later than the end date.";
                                              setErrors(newErrors)
                                            }
                                          }
                                        }}
                                        IconComponent={() => <ArrowDown />}
                                        displayEmpty
                                        value={item?.endYear}
                                        renderValue={(selected) => {
                                          if (!selected) {
                                            return (
                                              <span style={{ opacity: "0.8" }}>
                                                Select Year
                                              </span>
                                            );
                                          }

                                          return selected;
                                        }}
                                      >
                                        {getYears(
                                          data?.yearMonth?.startYear
                                        ).map((item) => (
                                          <MenuItem value={item}>
                                            {item}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                    <span className="formField__error">
                                        {
                                          errors[
                                            `qualificationArray${index}endYear`
                                          ]
                                        }
                                      </span>
                                  </div>
                                </div>
                                <div className="formRow formRow__1">
                                  <div
                                    className={`fieldBox w_100 formField ${
                                      errors[
                                        `qualificationArray${index}majorStudy`
                                      ]
                                        ? "fieldError"
                                        : ""
                                    }`}
                                  >
                                    <label className="formField__label">
                                      Major/Field of Study
                                    </label>
                                    <Autocomplete
                                      id="tags-filled"
                                      options={data?.majorFieldOfStudy}
                                      value={item?.fieldOfStudy}
                                      name={`qualificationArray${index}majorStudy`}
                                      onChange={(event, newValue) => {
                                        const newArray = handleEduDetailsChange(
                                          index,
                                          "fieldOfStudy",
                                          newValue,
                                          values?.qualificationArray
                                        );
                                        setFieldValue(
                                          "qualificationArray",
                                          newArray
                                        );
                                        if (!newValue) {
                                          const newErrors = errors;
                                          newErrors[
                                            `qualificationArray${index}majorStudy`
                                          ] =
                                            "Major/Field of Study is required";
                                          setErrors(newErrors);
                                        } else {
                                          const newErrors = errors;
                                          delete newErrors[
                                            `qualificationArray${index}majorStudy`
                                          ];
                                          setErrors(newErrors);
                                        }
                                      }}
                                      freeSolo
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="filled"
                                          placeholder="Select"
                                          className="autoCompleteInput"
                                          onBlur={(e) => {
                                            if (params?.inputProps?.value) {
                                              const newArray = handleEduDetailsChange(
                                                index,
                                                "fieldOfStudy",
                                                params?.inputProps?.value,
                                                values?.qualificationArray
                                              );
                                              setFieldValue(
                                                "qualificationArray",
                                                newArray
                                              );
                                            }
                                            if (!params?.inputProps?.value) {
                                              const newErrors = errors;
                                              newErrors[
                                                `qualificationArray${index}majorStudy`
                                              ] =
                                                "Major/Field of Study is required";
                                              setErrors(newErrors);
                                            } else {
                                              const newErrors = errors;
                                              delete newErrors[
                                                `qualificationArray${index}majorStudy`
                                              ];
                                              setErrors(newErrors);
                                            }
                                          }}
                                        />
                                      )}
                                    />
                                    <span className="formField__error">
                                      {
                                        errors[
                                          `qualificationArray${index}majorStudy`
                                        ]
                                      }
                                    </span>
                                    {/* <span className="formField__icon">
                                <EducationSvg />
                              </span> */}
                                  </div>
                                </div>
                              </div>
                              <div className="educationalInfoList_row_button">
                                {values?.qualificationArray?.length > 1 && (
                                  <button
                                    type="button"
                                    className="btn btn__danger btn--sm"
                                    onClick={() => {
                                      const newArray = deleteEducationalDetail(
                                        index,
                                        values?.qualificationArray
                                      );
                                      setFieldValue(
                                        "qualificationArray",
                                        newArray
                                      );
                                      const newErrors = errors;
                                      delete newErrors[`qualificationArray${index}degree`];
                                      delete newErrors[`qualificationArray${index}institutionName`];
                                      delete newErrors[`qualificationArray${index}startMonth`];
                                      delete newErrors[`qualificationArray${index}startYear`];
                                      delete newErrors[`qualificationArray${index}endMonth`];
                                      delete newErrors[`qualificationArray${index}endYear`];
                                      delete newErrors[`qualificationArray${index}majorStudy`];
                                      setErrors(newErrors);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </section>
                  <section className="form__section">
                    <h4 className="form__section--title">
                      Professional Details
                    </h4>
                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.skillSet && errors?.skillSet
                              ? "fieldError"
                              : ""
                          }`}
                        >
                          <label className="formField__label">
                            Recruiting Fields
                          </label>
                          <div className="formField">
                            <Autocomplete
                              style={
                                errors?.skillSet && {
                                  border: "1px solid #F04438",
                                  borderRadius: "6px",
                                }
                              }
                              multiple
                              id="tags-filled"
                              options={data?.skillSet}
                              defaultValue={values?.skillSet}
                              name="skillSet"
                              onChange={(event, newValue) => {
                                setFieldValue("skillSet", newValue);
                              }}
                              freeSolo
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    variant="outlined"
                                    label={option}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="filled"
                                  placeholder="Select"
                                  className="autoCompleteInput"
                                />
                              )}
                            />
                            <span className="formField__error">
                              {errors?.skillSet && errors?.skillSet}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.experience &&
                            touched?.experience &&
                            errors?.experience
                              ? "fieldError"
                              : ""
                          }`}
                        >
                          <label className="formField__label">
                            Total Experience (years)
                          </label>
                          <Field
                            type="number"
                            className="formField__input sm"
                            placeholder="Total Experience"
                            name="experience"
                          />
                          <span className="formField__error">
                            {errors?.experience &&
                              touched?.experience &&
                              errors?.experience}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div
                          className={`formField ${
                            errors?.linkedin && touched?.linkedin
                              ? "fieldError"
                              : ""
                          }`}
                        >
                          <label className="formField__label">
                            LinkedIn Account
                          </label>
                          <div className="formField__group sm">
                            <Field
                              type="text"
                              className="formField__input sm"
                              placeholder=""
                              name="linkedin"
                            />
                          </div>
                          <span className="formField__error">
                            {errors?.linkedin &&
                              touched?.linkedin &&
                              errors?.linkedin}
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {loading && <Loader />}
      {((meta?.status === true && meta?.msg) ||
        (meta?.status === true && meta?.msg)) && (
        <SuccessModal
          closeModal={closeModal}
          Heading={"Recruiter Updated!"}
          sucessfullMsg={meta?.msg}
        />
      )}
      {(meta?.status === false && meta?.msg) ||
        (meta?.status === false && meta?.msg && (
          <ErrorModal
            errorMessage={meta?.msg}
            closeModal={closeModal}
            footerVisible={true}
          />
        ))}
    </>
  );
}

export default MyProfileEdit;
