import React, { useState, useEffect } from "react";
import CloseIconGray from "@/assets/svgs/CloseIconGray";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
// import StarIcon from "@/assets/svgs/StarIcon";
import { GetUserDetails } from "@/utils/helper";
import addReviewsRatingsToRecruiter from "@/rtk-apis/customer/myJobs/reviewsRatings/addReviewsRatingsToRecruiter";
import Loader from "@/elements/Loader";
import { closeAddReviewsRatingsModal } from "@/store/slices/customer/myJobs/reviewsRatings/addReviewsRatingsToRecruiterSlice";
import ErrorModal from "@/components/modals/error-modal";
import { useDispatch, useSelector } from "react-redux";
import ThankyouReviewRatingModal from "./ThankyouReviewRatingModal";
import updateReviewsRatings from "@/rtk-apis/customer/myJobs/reviewsRatings/updateReviewsRatings";
import { SuccessModal } from "@/components/modals/SuccessModal";
import { closeupdateReviewsRatingsModal } from "@/store/slices/customer/myJobs/reviewsRatings/updateReviewsRatingsSlice";

const GiveFeedbackModal = ({
  closeModal,
  selectedJob,
  recruiterDetails,
  setRefreshRecruiterDetails,
  refreshRecruiterDetails,
  setOpenReviewRatingsModal,
  ViewReviewRating,
  ReviewRatingDetails,
}) => {
  const dispatch = useDispatch();

  const loggedInUser = GetUserDetails();
  const [ratingsArray, setRatingsArray] = useState([]);
  const [QualityRatingValue, setQualityRatingValue] = useState(0);
  const [CommunicationRatingValue, setCommunicationRatingValue] = useState(0);
  const [ExpertiseRatingvalue, setExpertiseRatingvalue] = useState(0);
  const [ProfessionalismRatingValue, setProfessionalismRatingValue] = useState(
    0
  );
  const [HireAgainRatingValue, setHireAgainRatingValue] = useState(0);
  const [OpenThankyouModal, setOpenThankyouModal] = useState(false);
  const [RatingNotGiven, setRatingNotGiven] = useState(false);
  const [Explainationtext, setExplainationtext] = useState("");
  const [hasChanges, setHasChanges] = useState(false);

  const addReviewsRatingsResponse = useSelector((globalState) => {
    return globalState.addReviewsRatingsToRecruiterResponse;
  });

  const updateReviewsRatingsResponse = useSelector((globalState) => {
    return globalState.updateReviewsRatingsRes;
  });

  useEffect(() => {
    if (ViewReviewRating && ReviewRatingDetails?.length) {
      setExplainationtext(ReviewRatingDetails[0]?.notes);
      setRatingsArray(ReviewRatingDetails[0]?.rating);
      ReviewRatingDetails[0]?.rating?.map((item) => {
        if (item?.type === "quality") {
          setQualityRatingValue(item?.rating);
        } else if (item?.type === "communication") {
          setCommunicationRatingValue(item?.rating);
        } else if (item?.type === "expertise") {
          setExpertiseRatingvalue(item?.rating);
        } else if (item?.type === "professionalism") {
          setProfessionalismRatingValue(item?.rating);
        } else if (item?.type === "hireAgain") {
          setHireAgainRatingValue(item?.rating);
        }
      });
      setHasChanges(false);
    }
  }, [ReviewRatingDetails?.length]);

  useEffect(() => {
    if (
      QualityRatingValue < 1 ||
      CommunicationRatingValue < 1 ||
      ExpertiseRatingvalue < 1 ||
      ProfessionalismRatingValue < 1 ||
      HireAgainRatingValue < 1
    ) {
      setRatingNotGiven(true);
    } else {
      setRatingNotGiven(false);
    }
  }, [
    QualityRatingValue,
    CommunicationRatingValue,
    ExpertiseRatingvalue,
    ProfessionalismRatingValue,
    HireAgainRatingValue,
  ]);

  const handleRatings = (type, value) => {
    const ratingIndex = ratingsArray.findIndex(
      (rating) => rating.type === type
    );
    if (ratingIndex !== -1) {
      const updatedRatingsArray = ratingsArray.map((rating, index) =>
        index === ratingIndex ? { ...rating, rating: value } : rating
      );
      setRatingsArray(updatedRatingsArray);
    } else {
      const newRating = { type: type, rating: value };
      setRatingsArray((prevRatingsArray) => [...prevRatingsArray, newRating]);
    }
    setHasChanges(true);
  };

  const handleTextAreaChange = (e) => {
    setExplainationtext(e?.target?.value);
    setHasChanges(true);
  };

  useEffect(() => {
    if (
      addReviewsRatingsResponse?.meta &&
      addReviewsRatingsResponse?.meta?.status === true &&
      !ViewReviewRating
    ) {
      setOpenThankyouModal(true);
    }
  }, [addReviewsRatingsResponse?.meta?.status]);

  const handleSubmitRatings = async () => {
    if (ViewReviewRating) {
      let body = {
        updatedById: loggedInUser?.customer?.employeeId,
        ratingId: ReviewRatingDetails[0]?.ratingId,
        ratingArray: ratingsArray,
        notes: Explainationtext ? Explainationtext : "",
      };
      await dispatch(updateReviewsRatings(body));
    } else {
      let body = {
        recruiterId: recruiterDetails?.recruiterId,
        companyId: loggedInUser?.customer?.companyId,
        employeeId: loggedInUser?.customer?.employeeId,
        jobId: selectedJob?.jobId,
        ratingArray: ratingsArray,
        notes: Explainationtext ? Explainationtext : "",
      };
      await dispatch(addReviewsRatingsToRecruiter(body));
    }
    setRefreshRecruiterDetails(!refreshRecruiterDetails);
  };
  const closeAddUpdateErrorModal = () => {
    setOpenThankyouModal(false);
    !ViewReviewRating
      ? dispatch(closeAddReviewsRatingsModal())
      : dispatch(closeupdateReviewsRatingsModal());
    setOpenReviewRatingsModal(false);
  };

  const closeUpdateModal = () => {
    dispatch(closeupdateReviewsRatingsModal());
    setOpenReviewRatingsModal(false);
  };
  return (
    <>
      <div className={"modal modalForm modalGiveFeedback modal--open"}>
        <div className="modal__dialog">
          <div className="modal__content modal__content--xxxl">
            <div className="modal__header">
              <h2 className="modal__header--title">Review / Rating</h2>
              <button
                onClick={closeModal}
                className="btn btn__transparent btnClose"
              >
                <CloseIconGray />
              </button>
            </div>
            <div className="modal__body modal-body-left">
              <h4>Your Rating</h4>
              <div className="rattingList">
                <div className="rattingList__item">
                  <div className="label">Quality</div>
                  <div className="ratings">
                    <span className="ratings__star ratings__star--fill">
                      <Rating
                        name="hover-feedback"
                        value={QualityRatingValue}
                        precision={1}
                        onChange={(event, newValue) => {
                          handleRatings("quality", newValue);
                          setQualityRatingValue(newValue);
                        }}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />
                    </span>
                  </div>
                </div>
                <div className="rattingList__item">
                  <div className="label">Communication</div>
                  <div className="ratings">
                    <span className="ratings__star ratings__star--fill">
                      <Rating
                        name="hover-feedback"
                        value={CommunicationRatingValue}
                        precision={1}
                        onChange={(event, newValue) => {
                          handleRatings("communication", newValue);
                          setCommunicationRatingValue(newValue);
                        }}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />
                    </span>
                  </div>
                </div>
                <div className="rattingList__item">
                  <div className="label">Expertise</div>
                  <div className="ratings">
                    <span className="ratings__star ratings__star--fill">
                      <Rating
                        name="hover-feedback"
                        value={ExpertiseRatingvalue}
                        precision={1}
                        onChange={(event, newValue) => {
                          handleRatings("expertise", newValue);
                          setExpertiseRatingvalue(newValue);
                        }}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />
                    </span>
                  </div>
                </div>
                <div className="rattingList__item">
                  <div className="label">Professionalism</div>
                  <div className="ratings">
                    <span className="ratings__star ratings__star--fill">
                      <Rating
                        name="hover-feedback"
                        value={ProfessionalismRatingValue}
                        precision={1}
                        onChange={(event, newValue) => {
                          handleRatings("professionalism", newValue);
                          setProfessionalismRatingValue(newValue);
                        }}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />
                    </span>
                  </div>
                </div>
                <div className="rattingList__item">
                  <div className="label">Hire Again?</div>
                  <div className="ratings">
                    <span className="ratings__star ratings__star--fill">
                      <Rating
                        name="hover-feedback"
                        value={HireAgainRatingValue}
                        precision={1}
                        onChange={(event, newValue) => {
                          handleRatings("hireAgain", newValue);
                          setHireAgainRatingValue(newValue);
                        }}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="">
                <h5>Share Your Experience</h5>
                <p>
                  Your feedback is important! By sharing your experience, you
                  can help others make informed decisions when choosing a
                  recruiter to work with.
                </p>
              </div>
              <div className="formField">
                <div className="fromField__label">
                  Note: Your review will be publicly available to others.
                </div>
                <div className="feedbackCommentBox">
                  <textarea
                    className="formField__textarea"
                    rows={3}
                    value={Explainationtext}
                    onChange={(e) => handleTextAreaChange(e)}
                    placeholder="Add your comments..."
                    maxLength={500}
                  ></textarea>
                  <span className="feedbackCommentBox__charactersText">
                    {Explainationtext?.length}/500 characters
                  </span>
                </div>
              </div>
              <div className="btn__group">
                <button className="btn btn__white" onClick={closeModal}>
                  Cancel
                </button>
                <button
                  className="btn btn__primary"
                  type="submit"
                  onClick={handleSubmitRatings}
                  disabled={RatingNotGiven || (ViewReviewRating && !hasChanges)}
                >
                  {ViewReviewRating ? "Update" : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
      {(addReviewsRatingsResponse?.meta?.status === false ||
        updateReviewsRatingsResponse?.meta?.status === false) && (
        <ErrorModal
          errorMessage={
            ViewReviewRating
              ? updateReviewsRatingsResponse?.meta?.msg
              : addReviewsRatingsResponse?.meta?.msg
          }
          closeModal={closeAddUpdateErrorModal}
          footerVisible={true}
        />
      )}
      <ThankyouReviewRatingModal
        openModal={OpenThankyouModal}
        recruiterDetails={recruiterDetails}
        selectedJob={selectedJob}
        setOpenThankyouModal={setOpenThankyouModal}
        setOpenReviewRatingsModal={setOpenReviewRatingsModal}
        setRefreshRecruiterDetails={setRefreshRecruiterDetails}
        refreshRecruiterDetails={refreshRecruiterDetails}
      />
      {updateReviewsRatingsResponse?.meta?.status && (
        <SuccessModal
          closeModal={closeUpdateModal}
          sucessfullMsg={
            "Review / rating has been updated successfully for the recruiter."
          }
          Heading={"Review Updated!"}
        />
      )}
      {addReviewsRatingsResponse?.loading ||
        (updateReviewsRatingsResponse?.loading && <Loader />)}
    </>
  );
};

export default GiveFeedbackModal;
