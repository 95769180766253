import React, { useState, useEffect } from "react";
import SideBar from "@/components/layout/SideBar";
import MyJobsListing from "@/pages/MyJobs/MyJobsListing";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import BackIcon from "@/assets/svgs/BackIcon";
import JobDetails from "@/pages/MyJobs/JobDetails/JobDetails";
import Interviews from "@/pages/MyJobs/Interviews/Interviews";
import ScheduledInterviews from "@/pages/MyJobs/ScheduledInterviews/ScheduledInterviews";
import Candidates from "@/pages/MyJobs/ApprovedCandidates/Candidates";
import Questionnaire from "@/pages/MyJobs/Questionnaire/Questionnaire";
import Notes from "@/pages/MyJobs/Notes/Notes";
import { useSelector } from "react-redux";

function MyJobs() {
  const [value, setValue] = useState("1");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedJob, setSelectedJob] = useState({});
  const [responsive, setResponsive] = useState(false);
  const [showAllTabs, setShowAllTabs] = useState(true);
  const [middleTabValue, setMiddleTabValue] = useState("1");

  const { data, loading } = useSelector((globalState) => {
    return globalState.myJobsListResponse;
  });

  const handleTablistChange = (event, newValue) => {
    setValue(newValue);
  };

  const indexChanged = (index) => {
    setValue("1");
    setResponsive(true);
    setSelectedIndex(index);
    setSelectedJob(data.rows[index]);
  };

  useEffect(() => {
    if (data?.rows?.length) setSelectedJob(data.rows[0]);
    else setSelectedJob({});
  }, [data]);

  const middleTabChanged = (val) => {
    val === "1" ? setShowAllTabs(true) : setShowAllTabs(false);
    setMiddleTabValue(val);
  };

  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid">
          <MyJobsListing
            changeTab={(value) => middleTabChanged(value)}
            selectedIndex={selectedIndex}
            changeIndex={(i) => indexChanged(i)}
            setNewValue={setValue}
            setSelectedIndex={setSelectedIndex}
          />
          <div
            className={`layoutGrid__col layoutGrid--responsive ${
              responsive ? "layoutGrid--show" : ""
            }`}
          >
            <div className="dataCol">
              <div className="page page__full">
                <div className="page__head">
                  <h2 className="page__head--title">
                    <button
                      onClick={() => setResponsive(false)}
                      className="btn btn__transparent btn__back border_0 p_0"
                    >
                      <BackIcon />
                    </button>
                    {selectedJob?.title || ""}
                  </h2>
                </div>

                <div className="page__body bg--white">
                  <Box className="tabContainer">
                    <TabContext value={value}>
                      <Box className="tabContainer__head tabContainer__head--fixed m_0">
                        <TabList
                          onChange={handleTablistChange}
                          variant="scrollable"
                          scrollButtons
                          allowScrollButtonsMobile
                          className={`${!showAllTabs ? "hideArrow" : ""}`}
                        >
                          {data?.count && <Tab label="Job Details" value="1" />}
                          {showAllTabs && data?.count && (
                            <Tab label="Interviews" value="2" />
                          )}
                          {showAllTabs && data?.count && (
                            <Tab label="Scheduled Interviews" value="3" />
                          )}
                          {showAllTabs && data?.count && (
                            <Tab label="Candidates" value="4" />
                          )}
                          {showAllTabs && data?.count && (
                            <Tab label="Questionnaire" value="5" />
                          )}
                          {showAllTabs && data?.count && (
                            <Tab label="Notes" value="6" />
                          )}
                        </TabList>
                      </Box>
                      <TabPanel value="1" className="tabContainer__item">
                        <JobDetails
                          middleTabValue={middleTabValue}
                          selectedJobData={selectedJob}
                        />
                      </TabPanel>
                      <TabPanel value="2" className="tabContainer__item">
                        <Interviews selectedJob={selectedJob} />
                      </TabPanel>

                      <TabPanel value="3" className="tabContainer__item">
                        <ScheduledInterviews selectedJob={selectedJob} />
                      </TabPanel>

                      <TabPanel value="4" className="tabContainer__item">
                        <Candidates selectedJob={selectedJob} />
                      </TabPanel>

                      <TabPanel value="5" className="tabContainer__item">
                        <Questionnaire selectedJob={selectedJob} />
                      </TabPanel>

                      <TabPanel value="6" className="tabContainer__item">
                        <Notes selectedJob={selectedJob} />
                      </TabPanel>
                    </TabContext>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyJobs;
