import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetUserDetails } from "@/utils/helper";
import recruiterDashboard from "@/rtk-apis/recruiterDashboard/recruiterDashboard";
import SideBar from "@/components/layout/SideBar";
import Loader from "@/elements/Loader";
import JobsIcon from "@/assets/svgs/DashboardIcons/JobsIcon";
import CandidatesRecruitedIcon from "@/assets/svgs/DashboardIcons/CandidatesRecruitedIcon";
import UpcomingInterviewsIcon from "@/assets/svgs/DashboardIcons/UpcomingInterviewsIcon";
import AcceptedJobsIcon from "@/assets/svgs/DashboardIcons/AcceptedJobsIcon";
import DeclinedJobsIcon from "@/assets/svgs/DashboardIcons/DeclinedJobsIcon";

function Dashboard() {
  const dispatch = useDispatch();
  const user = GetUserDetails();
  const navigate = useNavigate();

  const { loading, data } = useSelector((globalState) => {
    return globalState.recruiterDashboardResponse;
  });

  useEffect(() => {
    getDashboardDetails();
  }, []);

  const getDashboardDetails = () => {
    dispatch(recruiterDashboard(user.recruiter.recruiterId));
  };

  return (
    <div className="layoutContainer">
      <SideBar />
      <div className="layoutGrid layoutGrid--full">
        <div className="layoutGrid__col">
          <div className="page page__full">
            <div className="page__head">
              <h2 className="listCol__head--title">Home</h2>
            </div>
            <div className="page__body bg--white">
              <div className="cardContainer">
                <ul className="cardList">
                  <li
                    onClick={() => {
                      navigate("/job-requests");
                    }}
                    className="cardList__item"
                  >
                    <div className="card--icon">
                      <JobsIcon />
                    </div>
                    <div className="card--title">Job Requests</div>
                    <div className="card--subtitle">{data?.jobRequest}</div>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/my-jobs");
                    }}
                    className="cardList__item"
                  >
                    <div className="card--icon">
                      <AcceptedJobsIcon />
                    </div>
                    <div className="card--title">Accepted jobs</div>
                    <div className="card--subtitle">{data?.acceptedJob}</div>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/my-jobs", { state: { activeTab: "2" } });
                    }}
                    className="cardList__item"
                  >
                    <div className="card--icon">
                      <DeclinedJobsIcon />
                    </div>
                    <div className="card--title">Rejected jobs</div>
                    <div className="card--subtitle">{data?.declineJob}</div>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/interviews");
                    }}
                    className="cardList__item"
                  >
                    <div className="card--icon">
                      <UpcomingInterviewsIcon />
                    </div>
                    <div className="card--title">Upcoming Interviews</div>
                    <div className="card--subtitle">
                      {data?.upcomingInterview}
                    </div>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/candidates");
                    }}
                    className="cardList__item"
                  >
                    <div className="card--icon">
                      <CandidatesRecruitedIcon />
                    </div>
                    <div className="card--title">Candidates</div>
                    <div className="card--subtitle">{data?.candidateCount}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
}

export default Dashboard;
