import React from "react";
import "./App.scss";
import MainRoutes from "./routes/MainRoutes";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

function App() {
  let stripePublishkey = process.env.REACT_APP_STRIPE_PUBLISH_KEY
  const stripePromise = loadStripe(stripePublishkey);
  return (
    <div className="App">
      <Elements stripe={stripePromise}>
        <MainRoutes />
      </Elements>
    </div>
  );
}

export default App;
