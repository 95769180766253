import React, { useState, useEffect } from "react";
import RecruiterBillingPaymentsFilter from "./RecruiterBillingPaymentsFilter";
import SearchIcon from "@/assets/svgs/SearchIcon";
import FilterIcon from "@/assets/svgs/FilterIcon";
import DotIcon from "@/assets/svgs/Dot";
import Search from "@/components/Search";
import CustomPagination from "@/elements/pagination/customPagination";
import recruiterJobList from "@/rtk-apis/recruiterBilling/recruiterJobList";
import { useSelector, useDispatch } from "react-redux";
import { GetUserDetails } from "@/utils/helper";
import Loader from "@/elements/Loader";
import Dot from "@/assets/svgs/Dot";

const RecruiterBillingCandidateListing = ({
  onJobSelect,
  ActiveJobId,
  setAddResponsiveClass,
}) => {
  const dispatch = useDispatch();
  const user = GetUserDetails();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const [status, setStatus] = useState("All");
  const [filtersApplied, setFiltersApplied] = useState(false);

  const [showSearch, setShowSearch] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const { data, loading } = useSelector((globalState) => {
    return globalState.getRecuiterBillingJobList;
  });

  useEffect(() => {
    getRecruiterBillingJobListing();
  }, [search, page, filtersApplied]);

  useEffect(() => {
    if (data?.count) {
      onJobSelect(data?.rows[0]?.jobId, data?.totalReceivedAmount);
    }
  }, [data?.rows]);

  const getRecruiterBillingJobListing = () => {
    dispatch(
      recruiterJobList({
        recruiterId: user?.recruiter?.recruiterId,
        limit: 20,
        page: page,
        search: search,
        status: status === "All" ? ["Paid", "Unpaid"] : [status],
      })
    );
  };

  const closeSearch = () => {
    setShowSearch(false);
    setSearch("");
    setPage(1);
  };

  const changePage = (event, value) => {
    setPage(value);
  };

  const ApplyJobFilter = () => {
    setShowFilter(false);
    setFiltersApplied(!filtersApplied);
  };

  const ClearFilter = () => {
    setStatus("All");
    setShowFilter(false);
    setFiltersApplied(false);
  };

  const filterCount = () => {
    let filterCount = 0;
    if (status == "Paid" || status == "Unpaid") {
      filterCount += 1;
    }
    return `(${filterCount})`;
  };

  const getJobPaidUnpaidStatus = (screenings) => {
    if (!screenings.length) {
      return "Unpaid";
    }
    const allPaid = screenings.every(
      (item) => item.recruiterAmountPaid === true
    );

    return allPaid ? "Paid" : "Unpaid";
  };
  return (
    <>
      <div className="layoutGrid__col">
        <div
          className={`listCol ${showFilter && "listCol__filter"} ${showSearch &&
            "listCol__search"}`}
        >
          <div className="listCol__head listing__head">
            <h2 className="listCol__head--title">My Jobs</h2>
            <div className="listCol__head--actions">
              <button
                className="btn btn__white p_0"
                type="button"
                onClick={() => {
                  setShowSearch(true);
                }}
              >
                <SearchIcon />
              </button>

              <button
                className="btn btn__white p_0"
                type="button"
                onClick={() => setShowFilter(true)}
              >
                <FilterIcon />
              </button>
            </div>

            <Search
              closeSearch={closeSearch}
              searchTerm={(value) => setSearch(value)}
            />
          </div>

          <RecruiterBillingPaymentsFilter
            setShowFilter={setShowFilter}
            handleStatusChange={(value) => setStatus(value)}
            status={status}
            ApplyFilter={ApplyJobFilter}
            clearFilter={ClearFilter}
          />

          {filtersApplied && (
            <div className="listColTitle filterClear">
              <p>Filters applied {filterCount()}</p>
              <button
                className="filterClear__btn"
                type="button"
                onClick={ClearFilter}
              >
                Clear filter
              </button>
            </div>
          )}
          <ul className="listCol__list listing__list">
            {data?.rows?.length > 0 ? (
              data?.rows?.map((job, index) => {
                return (
                  <li
                    className={`listCol__list--item ${
                      ActiveJobId === job?.jobId
                        ? "listCol__item--selected"
                        : ""
                    }`}
                    key={index}
                    onClick={() => {
                      onJobSelect(job?.jobId, data?.totalReceivedAmount);
                      setAddResponsiveClass(true);
                    }}
                  >
                    <div className="item item--recruiter item--jobs">
                      <div className="item__info">
                        <h4 className="item__info--title">{job?.title}</h4>
                        <div className="item__info--subtitle">
                          <p>
                            {" "}
                            <span>Department:</span>
                            {job?.department}
                          </p>
                          <span className="status">
                            {job?.department && <DotIcon />}
                            Status:{" "}
                            <span
                              className={`${
                                getJobPaidUnpaidStatus(job?.screenings) ===
                                "Paid"
                                  ? "text--primary"
                                  : "text--danger"
                              }`}
                            >
                              {getJobPaidUnpaidStatus(job?.screenings)}
                            </span>
                          </span>
                        </div>
                        <div className="item__info--subtitle">
                          {job?.company?.companyName && (
                            <>
                              <p>
                                <span>Customer:</span>{" "}
                                {job?.company?.companyName}
                              </p>
                              <span>
                                {job?.company?.companyName && <Dot />}
                                {job?.location}
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <div className="no-data">No Results Found!</div>
            )}
          </ul>

          <div className="pagination pagination__center">
            {data?.rows?.length > 0 ? (
              <CustomPagination
                page={page}
                totalPageCount={Math.ceil(data?.count / 20)}
                changePage={(event, value) => changePage(event, value)}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default RecruiterBillingCandidateListing;
