import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import staticData from "@/rtk-apis/staticData";
import FormControl from "@mui/material/FormControl";
import CloseIconGray from "@/assets/svgs/CloseIconGray";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { GetUserDetails, replaceLast } from "@/utils/helper";
import { creditCardFormSchema } from "../schema/schema";
import VisaImage from "@/assets/images/visa-icon.svg";
import DinersClubImage from "@/assets/images/diners-club-icon.svg";
import DiscoverImage from "@/assets/images/discover-icon.svg";
import MasterCardImage from "@/assets/images/master-card-icon.svg";
import AmericanExpressImage from "@/assets/images/american-express-icon.svg";
import addCreditCardDetails from "@/rtk-apis/customer/payments/addCreditCardDetails";
import Loader from "@/elements/Loader";
import { SuccessModal } from "@/components/modals/SuccessModal";
import ErrorModal from "@/components/modals/error-modal";
import { closeCreditCardModal } from "@/store/slices/customer/payments/addCreditCardDetailsSlice";
import { isValidEmployeeName } from "@/utils/regex";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

const CustomerCardPaymentModal = ({
  openModal,
  closeModal,
  refreshPage,
  setOpenCardModal,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useDispatch();
  const autoCompleteRef = useRef();
  const loggedInUser = GetUserDetails();
  const inputRef = useRef();
  const add3Ref = useRef();
  const add1DivRef = useRef();
  const add3DivRef = useRef();

  const [addressCountry, setAddressCountry] = useState("");
  const [addressCountryCode, setAddressCountryCode] = useState("");
  const [addressState, setAddressState] = useState("");
  const [addressStateCode, setAddressStateCode] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressZipcode, setAddressZipcode] = useState("");
  const [addressAddress1, setAddressAddress1] = useState("");
  const [showAddressFiels, setShowAddressFiels] = useState(false);
  const [CardIncompleteError, setCardIncompleteError] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [Country, setCountry] = useState({
    value: "",
    name: "",
  });
  const [CardError, setCardError] = useState("");

  const { meta, loading } = useSelector((globalState) => {
    return globalState.addCreditCardDetailsRes;
  });

  const { data } = useSelector((globalState) => {
    return globalState.static;
  });

  const initialValues = {
    cardNumber: "",
    firstName: "",
    lastName: "",
    month: "",
    year: "",
    securityCode: "",
    country: "",
    countryCode: "",
    address1: "",
    city: "",
    state: "",
    zipcode: "",
  };

  useEffect(() => {
    dispatch(staticData());
  }, []);

  const handleAddressChange = (value, inputName) => {
    if (value?.length >= 3 && inputName === "address3") {
      add3DivRef.current.style.display = "none";
      add1DivRef.current.style.display = "block";
      inputRef.current.focus();
    } else if (value?.length < 3 && inputName === "address1") {
      add1DivRef.current.style.display = "none";
      add3DivRef.current.style.display = "block";
      add3Ref.current.focus();
    } else {
      if (value?.length < 3) {
        add1DivRef.current.style.display = "none";
        add3DivRef.current.style.display = "block";
        add3Ref.current.focus();
      } else {
        add3DivRef.current.style.display = "none";
        add1DivRef.current.style.display = "block";
        inputRef.current.focus();
      }
    }
  };

  useEffect(() => {
    if (add3DivRef.current) {
      add3DivRef.current.style.display = "none";
    }
    const options = {
      componentRestrictions: {
        country: Country?.value ? Country?.value : "US",
      },
    };
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );

    autoCompleteRef.current.addListener("place_changed", async function() {
      setShowAddressFiels(true);
      const place = await autoCompleteRef.current.getPlace();
      let addressComponents = place["address_components"];
      let address1 = place["formatted_address"];
      let street = "";
      let city = "";
      let state = "";
      let stateCode = "";
      let country = "";
      let countryCode = "";
      let zipCode = "";

      for (let i = 0; i < addressComponents.length; i++) {
        let types = addressComponents[i]["types"];
        if (types.includes("street_number") || types.includes("route")) {
          street = addressComponents[i]["long_name"];
        } else if (types.includes("locality")) {
          city = addressComponents[i]["long_name"];
        } else if (types.includes("administrative_area_level_1")) {
          state = addressComponents[i]["long_name"];
          stateCode = addressComponents[i]["short_name"];
        } else if (types.includes("country")) {
          country = addressComponents[i]["long_name"];
          countryCode = addressComponents[i]["short_name"];
        } else if (types.includes("postal_code")) {
          zipCode = addressComponents[i]["long_name"];
        }
      }
      const addressSplit = address1?.split(city);
      let modifiedAddress = "";
      if (addressSplit?.length && addressSplit[0]?.length) {
        modifiedAddress = replaceLast(addressSplit[0], ", ", "");
      }
      let streetAddress = modifiedAddress.includes(place["name"])
        ? modifiedAddress
        : `${place["name"]}`;
      setAddressCountry(country);
      setAddressCountryCode(countryCode);
      setAddressState(state);
      setAddressStateCode(stateCode);
      setAddressCity(city);
      setAddressZipcode(zipCode);
      setAddressAddress1(streetAddress);
      setCurrentTime(new Date());
    });
  }, [Country]);

  const handleSubmit = async (values) => {
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      // allow_redisplay: "always",
      billing_details: {
        name: `${values?.firstName} ${values?.lastName}`,
        email: loggedInUser?.customer?.email,
        address: {
          city: values?.city ?? null,
          country: values?.countryCode ?? null,
          line1: null,
          line2: null,
          postal_code: values?.zipcode ?? null,
          state: values?.state ?? null,
        },
      },
    });
    if (error) {
      setCardError(error.message);
      return;
    }
    let body = {
      paymentMethodId: paymentMethod?.id,
      companyId: loggedInUser?.customer?.company?.id,
      employeeId: loggedInUser?.customer?.employeeId,
      email: loggedInUser?.customer?.email,
    };
    dispatch(addCreditCardDetails(body));
  };

  const closeSuccessModal = () => {
    dispatch(closeCreditCardModal());
    setOpenCardModal(false);
    refreshPage();
  };

  const isButtonDisabled = (
    error,
    value,
    initialValues,
    CardError,
    CardIncompleteError
  ) => {
    console.log(CardError, "card");
    let status = false;
    // if (value?.state) {
    //   delete error["state"];
    // }
    // if (value?.city) {
    //   delete error["city"];
    // }
    // if (value?.zipcode) {
    //   delete error["zipcode"];
    // }
    if (value?.country) {
      delete error["country"];
    }
    if (Object.keys(error)?.length != 0) {
      status = true;
    } else if (
      Object.keys(error)?.length == 0 &&
      _.isEqual(value, initialValues)
    ) {
      status = true;
    }
    if (
      Object.keys(error)?.length == 0 &&
      value?.country === "United States" &&
      !value?.zipcode
    ) {
      status = true;
    }

    if (CardError) {
      status = true;
    }
    if (CardIncompleteError) {
      status = true;
    }
    return status;
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={creditCardFormSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({
          errors,
          values,
          handleChange,
          handleBlur,
          touched,
          setFieldValue,
          setFieldTouched,
          setFieldError,
        }) => {
          useEffect(() => {
            if (addressCountry) {
              setFieldValue("country", addressCountry);
              setFieldValue("countryCode", addressCountryCode);
              setFieldValue("state", addressState);
              setFieldValue("city", addressCity);
              setFieldValue("zipcode", addressZipcode);
              setFieldValue("address1", addressAddress1);
            }
          }, [
            addressCountry,
            addressState,
            addressCity,
            addressZipcode,
            addressAddress1,
            currentTime,
          ]);
          useEffect(() => {
            if (values?.country) {
              setFieldError("country", "");
              setFieldTouched("country", false);
            }
          }, [values?.country]);

          return (
            <Form>
              <div
                className={`modal modalPaymentWithdrawal  ${
                  openModal ? "modal--open" : ""
                }`}
              >
                <div className="modal__dialog">
                  <Formik>
                    <div className="modal__content modal__content--lg">
                      <div className="modal__header">
                        <h2 className="modal__header--title">
                          Add a Payment Card{" "}
                        </h2>
                        <button
                          className="btn btn__transparent btnClose"
                          onClick={closeModal}
                        >
                          <CloseIconGray />
                        </button>
                      </div>
                      <div className="modal__body modal-body-left">
                        <h4 className="title">Payment Information</h4>
                        <div className="formRow w_100">
                          <div className="formCol">
                            <div className="paymentlabelsWithIcon">
                              <label className="formField__label">
                                Card Number
                              </label>
                              <div className="paymentsIcon">
                                <div className="paymentsIcon__List">
                                  <div className="item">
                                    <img
                                      src={VisaImage}
                                      height="30"
                                      alt="Visa"
                                    />
                                  </div>
                                  {/* <div className="item">
                                    <img
                                      src={DinersClubImage}
                                      height="30"
                                      alt="Diners Club"
                                    /> */}
                                  {/* </div> */}
                                  <div className="item">
                                    <img
                                      src={DiscoverImage}
                                      height="30"
                                      alt="Discover"
                                    />
                                  </div>
                                  <div className="item">
                                    <img
                                      src={MasterCardImage}
                                      height="30"
                                      alt="Master Card"
                                    />
                                  </div>
                                  <div className="item">
                                    <img
                                      src={AmericanExpressImage}
                                      height="30"
                                      alt="American Express"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`formField ${
                                CardError ? "fieldError" : ""
                              }`}
                            >
                              <CardElement
                                onChange={(event) => {
                                  console.log(event, "event");
                                  if (event.error) {
                                    setCardError(event.error.message);
                                  } else if (event?.complete === false) {
                                    setCardIncompleteError(true);
                                  } else if (event?.empty === true) {
                                    setCardIncompleteError(true);
                                  } else {
                                    setCardError("");
                                    setCardIncompleteError(false);
                                  }
                                }}
                              />
                              <span className="formField__error">
                                {CardError}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="formRow formRow__2 w_100">
                          <div className="formCol">
                            <div
                              className={`formField ${
                                errors?.firstName && touched.firstName
                                  ? "fieldError"
                                  : ""
                              }`}
                            >
                              <label className="formField__label">
                                First Name
                              </label>
                              <div className="formField__group sm">
                                <Field
                                  type="text"
                                  className="formField__input sm"
                                  placeholder="First Name"
                                  name="firstName"
                                  value={values?.firstName}
                                  onChange={(e) => {
                                    const newValue = e?.target?.value;
                                    if (
                                      newValue === "" ||
                                      isValidEmployeeName.test(newValue)
                                    ) {
                                      setFieldValue("firstName", newValue);
                                    }
                                  }}
                                />
                              </div>
                              {touched.firstName && errors.firstName && (
                                <span className="formField__error">
                                  {errors?.firstName}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="formCol">
                            <div
                              className={`formField ${
                                errors?.lastName && touched.lastName
                                  ? "fieldError"
                                  : ""
                              }`}
                            >
                              <label className="formField__label">
                                Last Name
                              </label>
                              <div className="formField__group sm">
                                <Field
                                  type="text"
                                  className="formField__input sm"
                                  placeholder="Last Name"
                                  name="lastName"
                                  value={values?.lastName}
                                  onChange={(e) => {
                                    const newValue = e?.target?.value;
                                    if (
                                      newValue === "" ||
                                      isValidEmployeeName.test(newValue)
                                    ) {
                                      setFieldValue("lastName", newValue);
                                    }
                                  }}
                                />
                              </div>
                              {touched.lastName && errors.lastName && (
                                <span className="formField__error">
                                  {errors?.lastName}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <h4 className="title">Billing Address</h4>
                        <div className="formRow w_100">
                          <div
                            className={`formField ${
                              errors?.country && touched.country
                                ? "fieldError"
                                : ""
                            }`}
                          >
                            <label className="formField__label">Country</label>
                            <Autocomplete
                              id="tags-filled"
                              options={data?.taxCountry}
                              getOptionLabel={(option) =>
                                option?.name || option
                              }
                              defaultValue={values?.country || ""}
                              value={values?.country}
                              name="country"
                              onChange={(event, newValue) => {
                                setCountry(newValue);
                                setFieldValue("country", newValue?.name);
                                setFieldValue("countryCode", newValue?.value);
                                setFieldValue("state", "");
                                setFieldValue("city", "");
                                setFieldValue("zipcode", "");
                                setFieldValue("address1", "");
                                setShowAddressFiels(false);
                              }}
                              freeSolo
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="filled"
                                  placeholder="Select"
                                  className="autoCompleteInput"
                                />
                              )}
                            />
                          </div>
                          {errors.country && touched.country && (
                            <span className="formField__error">
                              {errors?.country}
                            </span>
                          )}
                        </div>
                        {values?.country && (
                          <div className="formRow w_100" ref={add1DivRef}>
                            <div className="formCol">
                              <div
                                className={`formField ${
                                  errors?.address1 ? "fieldError" : ""
                                }`}
                              >
                                <label className="formField__label">
                                  Address
                                </label>
                                <div className="formField__group sm">
                                  <input
                                    type="text"
                                    className="formField__input sm"
                                    name="address1"
                                    placeholder="Address"
                                    ref={inputRef}
                                    value={values?.address1}
                                    onChange={(e) => {
                                      handleAddressChange(
                                        e?.target?.value,
                                        "address1"
                                      );
                                      setFieldValue(
                                        "address1",
                                        e?.target?.value
                                      );
                                    }}
                                  />
                                </div>
                                <span className="formField__error">
                                  {errors?.address1}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                        {values?.country && (
                          <div className="formRow w_100" ref={add3DivRef}>
                            <div className="formCol">
                              <div
                                className={`formField ${
                                  errors?.address1 ? "fieldError" : ""
                                }`}
                              >
                                <label className="formField__label">
                                  Address
                                </label>
                                <div className="formField__group sm">
                                  <input
                                    type="text"
                                    className="formField__input sm"
                                    name="address3"
                                    placeholder="Address"
                                    ref={add3Ref}
                                    value={values?.address1}
                                    onChange={(e) => {
                                      handleAddressChange(
                                        e?.target?.value,
                                        "address3"
                                      );
                                      setFieldValue(
                                        "address1",
                                        e?.target?.value
                                      );
                                    }}
                                  />
                                </div>
                                <span className="formField__error">
                                  {errors?.address1}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                        {showAddressFiels && (
                          <>
                            <div className="formRow formRow__2 w_100">
                              <div className="formCol">
                                <div
                                  className={`formField ${
                                    errors?.state ? "fieldError" : ""
                                  }`}
                                >
                                  <label className="formField__label">
                                    State
                                  </label>
                                  <FormControl
                                    fullWidth
                                    size="small"
                                    className="formField__select"
                                  >
                                    <Field
                                      type="text"
                                      className="formField__input sm"
                                      name="state"
                                      value={values?.state}
                                      onChange={handleChange}
                                      placeholder="State"
                                    />
                                  </FormControl>
                                </div>
                                <span className="formField__error">
                                  {errors?.state}
                                </span>
                              </div>
                              <div className="formCol">
                                <div
                                  className={`formField ${
                                    errors?.city ? "fieldError" : ""
                                  }`}
                                >
                                  <label className="formField__label">
                                    City
                                  </label>
                                  <FormControl
                                    fullWidth
                                    size="small"
                                    className="formField__select"
                                  >
                                    <Field
                                      type="text"
                                      className="formField__input sm"
                                      name="city"
                                      placeholder="City"
                                      value={values?.city}
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                </div>
                                <span className="formField__error">
                                  {errors?.city}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                        {showAddressFiels && (
                          <div className="formRow formRow__2 w_100">
                            <div className="formCol">
                              <div className="formField">
                                <label className="formField__label">
                                  Zip code 
                                  {`${
                                    values?.country === "United States"
                                      ? ""
                                      : "(optional)"
                                  }`}
                                </label>
                                <div className="formField__group sm">
                                  <Field
                                    type="text"
                                    className="formField__input sm"
                                    name="zipcode"
                                    placeholder="Zipcode"
                                    value={values?.zipcode}
                                    onChange={(e) => {
                                      if (e?.target?.value?.length <= 10) {
                                        setFieldValue(
                                          "zipcode",
                                          e?.target?.value
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="btn__group">
                          <button
                            className="btn btn__white"
                            onClick={() => closeModal(true)}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn__primary"
                            disabled={isButtonDisabled(
                              errors,
                              values,
                              initialValues,
                              CardError,
                              CardIncompleteError
                            )}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </Formik>
                </div>
                <div className="modal__backdrop"></div>
              </div>
            </Form>
          );
        }}
      </Formik>
      {meta?.status && (
        <SuccessModal
          closeModal={closeSuccessModal}
          sucessfullMsg={meta?.msg}
          Heading={"Card Added!"}
        />
      )}
      {meta?.status === false && (
        <ErrorModal
          errorMessage={meta?.msg}
          closeModal={closeSuccessModal}
          footerVisible={true}
        />
      )}
      {loading && <Loader />}
    </>
  );
};

export default CustomerCardPaymentModal;
