import httpService from "@/rtk-apis/axios-config/http-service";
import moment from "moment";
import CryptoJS from "crypto-js";
import { addYears } from "date-fns";
import { MONTHS_IN_NUMBERS } from "@/utils/constants";

const SaveUserDetails = (data) => {
  // localStorage.setItem("user", JSON.stringify(data));
  const token = JSON.parse(localStorage.getItem("token"));
  token.recruiter = data;
  localStorage.setItem("token", JSON.stringify(token));
};

const GetUserDetails = () => {
  return JSON.parse(localStorage.getItem("token"));
};

export function GetSessionId() {
  const user = JSON.parse(localStorage.getItem("token"));
  if (user && user?.sessionid) {
    return true;
  } else {
    return false;
  }
}

const formatPhoneNumber = (str) => {
  const cleaned = ("" + str).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  } else {
    return ("" + str).replace(/\D/g, "");
  }
};

const firstLetterCapital = (str) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
};

const getInitial = (str) => {
  if (str) {
    return str.charAt(0).toUpperCase();
  }
};

const formattedDate = (date) => {
  const originalDate = new Date(date);
  const options = { year: "numeric", month: "short", day: "2-digit" };
  return originalDate.toLocaleDateString("en-US", options);
};

const getFullName = (firstname, lastname) => {
  const fullName = firstname + " " + lastname;
  return fullName;
};
const saveInterviewDetails = (data) => {
  localStorage.setItem("interview", JSON.stringify(data));
};
const GetInterviewDetails = () => {
  return JSON.parse(localStorage.getItem("interview"));
};

const addScheduledInterviewData = async (postData) => {
  try {
    const response = await httpService.post("/laila/interview/add", postData);
    return response;
  } catch (error) {
    return error;
  }
};

const CheckUserMyJobAccess = (data, role) => {
  if (role === "Employee") {
    const user = GetUserDetails();
    const userId = user?.customer?.employeeId;
    if (data?.hiringLeadArray?.includes(userId)) {
      return true;
    } else if (data?.jobTeamMemberArray?.includes(userId)) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

const checkForAdminAndHiringLead = (data, role) => {
  if (role === "Company Admin") {
    return true;
  } else if (
    role === "Employee" ||
    role === "Finance Admin" ||
    role === "Job Creator"
  ) {
    const user = GetUserDetails();
    const userId = user?.customer?.employeeId;
    if (data?.hiringLeadArray?.includes(userId)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const checkForCompanyAdmin_HiringLead_JTM_JobCreater = (data, role) => {
  const user = GetUserDetails();
  const userId = user?.customer?.employeeId;
  if (role === "Company Admin") {
    return true;
  } else if (data?.hiringLeadArray?.includes(userId)) {
    return true;
  } else if (data?.jobTeamMemberArray?.includes(userId)) {
    return true;
  } else if (role === "Job Creator") {
    return true;
  } else {
    return false;
  }
};

const checkFor_CompanyAdmin_JobCreater_HiringLead = (data, role) => {
  const user = GetUserDetails();
  const userId = user?.customer?.employeeId;
  if (role === "Company Admin") {
    return true;
  } else if (data?.hiringLeadArray?.includes(userId)) {
    return true;
  } else if (role === "Job Creator") {
    return true;
  } else {
    return false;
  }
};

const checkForMyJobCandidate = (data, role) => {
  const user = GetUserDetails();
  const userId = user?.customer?.employeeId;
  if (role === "Company Admin") {
    return true;
  } else if (data?.hiringLeadArray?.includes(userId)) {
    return true;
  } else if (data?.jobTeamMemberArray?.includes(userId)) {
    return true;
  } else {
    return false;
  }
};

const checkForMyJobInterview = (data, role) => {
  const user = GetUserDetails();
  const userId = user?.customer?.employeeId;
  if (role === "Company Admin") {
    return true;
  } else if (data?.hiringLeadArray?.includes(userId)) {
    return true;
  } else if (data?.jobTeamMemberArray?.includes(userId)) {
    return true;
  } else {
    return false;
  }
};

const checkForApprovedTab = (data, role) => {
  const user = GetUserDetails();
  const userId = user?.customer?.employeeId;
  if (role === "Company Admin") {
    return true;
  } else if (data?.hiringLeadArray?.includes(userId)) {
    return true;
  } else if (data?.jobTeamMemberArray?.includes(userId)) {
    return true;
  } else {
    return false;
  }
};

const getApproveOrRejectRole = (data) => {
  const user = GetUserDetails();
  const userId = user?.customer?.employeeId;
  const role = user?.customer?.role;
  if (role === "Company Admin") {
    return role;
  } else if (data?.hiringLeadArray?.includes(userId)) {
    return "Hiring Lead";
  } else {
    return "Employee";
  }
};

const getMyInterviewButtonAccess = (jobData) => {
  const user = GetUserDetails();
  const userId = user?.customer?.employeeId;
  if (user?.customer?.role === "Company Admin") {
    return true;
  } else if (jobData?.hiringLeadArray?.includes(userId)) {
    return true;
  } else {
    return false;
  }
};

const Currency = (amount) => {
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return currencyFormatter.format(amount);
};

function capitalizeEachWord(str) {
  const Name = str?.split(" ");
  const capitalizedName = Name?.map((word) => {
    return word?.charAt(0).toUpperCase() + word.slice(1);
  });
  const finalName = capitalizedName?.join(" ");
  return finalName;
}

const getUTCRecordingTimeSlot = (meetingStartTime, recTimeSlot) => {
  const meetingDate = meetingStartTime.split("T")[0];
  const timeSlot1 = recTimeSlot.split("-")[0].split(" ")[0];
  const timeSlotFormat1 = recTimeSlot.split("-")[0].split(" ")[1];
  const timeSlot2 = recTimeSlot.split("-")[1].split(" ")[1];
  const timeSlotFormat2 = recTimeSlot.split("-")[1].split(" ")[2];
  const UTCDate1 =
    meetingDate + " " + timeSlot1 + ":00" + " " + timeSlotFormat1 + " " + "UTC";
  const UTCDate2 =
    meetingDate + " " + timeSlot2 + ":00" + " " + timeSlotFormat2 + " " + "UTC";
  return (
    moment(new Date(UTCDate1)).format("hh:mm A") +
    " - " +
    moment(new Date(UTCDate2)).format("hh:mm A")
  );
};

const getYears = (startYear) => {
  const currentYear = new Date().getFullYear();
  const yearsArray = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => (startYear + index).toString()
  );
  return yearsArray;
};

function toTitleCase(text) {
  return text.replace(/\w\S*/g, function(word) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
}

const getGraduationYear = (qualification) => {
  const gradYear = `${toTitleCase(qualification?.startMonth)} ${
    qualification?.startYear
  } - ${toTitleCase(qualification?.endMonth)} ${qualification?.endYear}`;
  return gradYear;
};

const decryptData = (data) => {
  if (isNumber(data)) {
    return data;
  } else {
    const key = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const bytes = CryptoJS.AES.decrypt(data, key);
    const text = bytes.toString(CryptoJS.enc.Utf8);
    return text;
  }
};

const maskSSNnumber = (ssn, type) => {
  if (ssn.length >= 5) {
    let decryptedSSN = decryptData(ssn);
    if (type === "SSN") {
      return "XXX-XX-" + decryptedSSN.slice(-4);
    }
    if (type === "TIN") {
      return "XXXXXX" + decryptedSSN.slice(-4);
    } else {
      return "XX-XX" + decryptedSSN.slice(-5);
    }
  } else {
    return decryptedSSN(ssn);
  }
};

const getMonthLastWednesday = () => {
  let currentDate = moment();
  let lastDayOfMonth = moment(currentDate).endOf("month");
  while (lastDayOfMonth.day() !== 3) {
    lastDayOfMonth.subtract(1, "day");
  }
  if (lastDayOfMonth.isBefore(currentDate)) {
    lastDayOfMonth = moment(currentDate)
      .add(1, "month")
      .endOf("month");
    while (lastDayOfMonth.day() !== 3) {
      lastDayOfMonth.subtract(1, "day");
    }
  }
  return lastDayOfMonth.format("D MMM, YYYY");
};

const isNumber = (number) => {
  return /^\d+$/.test(number);
};

const USDNumberFormat = (number) => {
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  let formattedNumber = formatter.format(number);
  return formattedNumber;
};

const replaceLast = (str, pattern, replacement) => {
  const match =
    typeof pattern === "string"
      ? pattern
      : (str.match(new RegExp(pattern.source, "g")) || []).slice(-1)[0];
  if (!match) return str;
  const last = str.lastIndexOf(match);
  return last !== -1
    ? `${str.slice(0, last)}${replacement}${str.slice(last + match.length)}`
    : str;
};

const isButtonDisabled = (error, value, initialValues) => {
  let status = false;
  if (Object.keys(error)?.length != 0) {
    status = true;
  } else if (
    Object.keys(error)?.length == 0 &&
    _.isEqual(value, initialValues)
  ) {
    status = true;
  }
  return status;
};

const getFutureYears = (month) => {
  let currentYear = new Date().getFullYear();
  const currentMonth = ("0" + (new Date().getMonth() + 1)).slice(-2);
  const endYear = addYears(new Date(), 20).getFullYear();
  if (month && month < currentMonth) {
    currentYear = currentYear + 1;
  }
  const years = [];
  for (let year = currentYear; year <= endYear; year++) {
    years.push(year.toString());
  }
  return years;
};

const getCreditCardMonths = (year) => {
  const currentYear = new Date().getFullYear();
  if (year == currentYear) {
    let months = [];
    const currentMonth = ("0" + (new Date().getMonth() + 1)).slice(-2);
    for (let month = currentMonth; month <= 12; month++) {
      const twoDigitMonth = ("0" + month).slice(-2);
      months.push(twoDigitMonth.toString());
    }
    return months;
  } else {
    return MONTHS_IN_NUMBERS;
  }
};

const titleCase = (str) => {
  var splitStr = str?.toLowerCase().split(" ");
  for (var i = 0; i < splitStr?.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr?.join(" ");
};

export {
  SaveUserDetails,
  GetUserDetails,
  formatPhoneNumber,
  firstLetterCapital,
  formattedDate,
  getInitial,
  getFullName,
  saveInterviewDetails,
  GetInterviewDetails,
  addScheduledInterviewData,
  CheckUserMyJobAccess,
  checkForAdminAndHiringLead,
  checkForCompanyAdmin_HiringLead_JTM_JobCreater,
  checkForMyJobCandidate,
  checkForMyJobInterview,
  checkForApprovedTab,
  getApproveOrRejectRole,
  getMyInterviewButtonAccess,
  Currency,
  capitalizeEachWord,
  getUTCRecordingTimeSlot,
  getYears,
  toTitleCase,
  getGraduationYear,
  maskSSNnumber,
  decryptData,
  getMonthLastWednesday,
  isNumber,
  USDNumberFormat,
  replaceLast,
  isButtonDisabled,
  getFutureYears,
  getCreditCardMonths,
  titleCase,
  checkFor_CompanyAdmin_JobCreater_HiringLead,
};
