import React, { useEffect, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CloseIconGray from "@/assets/svgs/CloseIconGray";
import staticData from "@/rtk-apis/staticData";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { GetUserDetails } from "@/utils/helper";
import { SuccessModal } from "@/components/modals/SuccessModal";
import ErrorModal from "@/components/modals/error-modal";
import Loader from "@/elements/Loader";
import PauseAJobPost from "@/rtk-apis/customer/myJobs/pauseAJobPost";
import ConfirmationModal from "./confirmation-modal";
import { closePauseJobModal } from "@/store/slices/customer/myJobs/pauseAjobPostSlice";
import RecruiterReassignModal from "./RecruiterReassignModal";
import ReopenAJobPost from "@/rtk-apis/customer/myJobs/reopenAJobPost";
import { closeReopenJobModal } from "@/store/slices/customer/myJobs/reopenAJobPostSlice";
import deleteCustomerJobPost from "@/rtk-apis/customer/myJobs/deleteCustomerJobPost";
import { closeDeleteJobModal } from "@/store/slices/customer/myJobs/deleteCustomerJobPostSlice";

const PauseStopReopenJobModal = ({
  openModal,
  closeModal,
  jobStatus,
  selectedJob,
  setOpenActionOnJobModal,
  refreshJobListing,
  setOpenRecruiterDetailPage,
  setResetJobModal,
  resetJobModal,
}) => {
  const dispatch = useDispatch();
  const loggedInUser = GetUserDetails();
  const [JobList, setJobList] = useState([]);
  const [checkboxValue, setCheckboxValue] = useState({});
  const [Reason, setReason] = useState("");
  const [explainationtext, setExplainationtext] = useState("");
  const [textareaError, setTextareaError] = useState("");
  const [OpenConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [OpenAssignRecruiterModal, setOpenAssignRecruiterModal] = useState(
    false
  );
  const [RecruiterCheckboxValue, setRecruiterCheckboxValue] = useState("");

  const { data } = useSelector((globalState) => {
    return globalState.static;
  });

  const pauseAPostJobRes = useSelector((globalState) => {
    return globalState.pauseAJobPostResponse;
  });

  const ReopenAPostJobRes = useSelector((globalState) => {
    return globalState.ReopenAJobPostResponse;
  });

  const closeJobPostResponse = useSelector((globalState) => {
    return globalState.deleteJobPostResponse;
  });

  const handleCheckboxChange = (e, item) => {
    if (checkboxValue[item]) {
      const { [item]: removedItem, ...newCheckboxValue } = checkboxValue;
      setCheckboxValue(newCheckboxValue);
      setReason("");
    } else {
      setReason(item);
      setCheckboxValue((prevState) => ({
        [item]: !prevState[item],
      }));
    }
    if (item === "Other") {
      setTextareaError("Reason is required.");
    } else {
      setTextareaError("");
    }
  };

  const handleTextAreaChange = (e) => {
    setExplainationtext(e?.target?.value);
    if (Reason === "Other" && e?.target?.value === "") {
      setTextareaError("Reason is required.");
    } else {
      setTextareaError("");
    }
  };

  const resetJobStatusModal = () => {
    setReason("");
    setExplainationtext("");
    setTextareaError("");
    setCheckboxValue({});
  };

  useEffect(() => {
    dispatch(staticData());
  }, []);
  useEffect(() => {
    if (jobStatus === "pause") {
      if (selectedJob?.status === "Closed") {
        setOpenErrorModal(true);
      } else {
        setJobList(data?.pauseAJobReasonList);
      }
    } else if (jobStatus === "reopen") {
      setJobList(data?.reopenJobReasonList);
    } else if (jobStatus === "close") {
      setJobList(data?.closedJobReasonList);
    }
    resetJobStatusModal();
  }, [jobStatus]);

  const handleNext = (jobStatus) => {
    if (jobStatus === "pause") {
      setOpenConfirmationModal(true);
    } else if (jobStatus === "reopen") {
      if (selectedJob?.assignedId !== null) {
        setOpenAssignRecruiterModal(true);
      } else {
        handleJobStatusChange();
      }
    } else if (jobStatus === "close") {
      setOpenConfirmationModal(true);
    }
  };
  const handleJobStatusChange = () => {
    const employeeId = loggedInUser?.customer?.employeeId;
    if (jobStatus === "pause") {
      let body = {
        jobId: selectedJob?.jobId,
        reason: Reason,
        otherReason: explainationtext ?? "",
        updatedBy: employeeId,
      };
      dispatch(PauseAJobPost(body));
      setOpenConfirmationModal(false);
    } else if (jobStatus === "reopen") {
      let body = {
        jobId: selectedJob?.jobId,
        reason: Reason,
        otherReason: explainationtext ?? "",
        updatedBy: employeeId,
        recruiterOption:
          selectedJob?.assignedId !== null ? RecruiterCheckboxValue : "new",
      };
      dispatch(ReopenAJobPost(body));
      setOpenActionOnJobModal(false);
    } else if (jobStatus === "close") {
      let body = {
        jobId: selectedJob?.jobId,
        reason: Reason,
        otherReason: explainationtext ?? "",
        updatedBy: employeeId,
      };
      dispatch(deleteCustomerJobPost(body));
      setOpenConfirmationModal(false);
      setOpenActionOnJobModal(false);
    }
    setOpenAssignRecruiterModal(false);
  };

  const handleCloseJobModal = () => {
    closeModal();
    resetJobStatusModal();
  };

  const closeSuccessfullModal = () => {
    handleCloseJobModal();
    refreshJobListing();
    if (jobStatus === "pause") {
      dispatch(closePauseJobModal());
    } else if (jobStatus === "reopen") {
      dispatch(closeReopenJobModal());
      setRecruiterCheckboxValue("");
    } else if (jobStatus === "close") {
      dispatch(closeDeleteJobModal());
    }
  };

  return (
    <>
      <div className={`modal modalJobStatus ${openModal ? "modal--open" : ""}`}>
        <div className="modal__dialog">
          <div className="modal__content modal__content--md">
            <div className="modal__header">
              <h2 className="modal__header--title">{selectedJob?.title}</h2>
              <button
                className="btn btn__transparent btnClose"
                onClick={() => {
                  handleCloseJobModal();
                  setResetJobModal(!resetJobModal);
                }}
              >
                <CloseIconGray />
              </button>
            </div>
            <div className="modal__body modal-body-left">
              <h3 className="tagline">
                <span
                  className={`tagline--label ${
                    jobStatus === "reopen"
                      ? "tagline--label--primary"
                      : jobStatus === "pause"
                      ? "tagline--label--warning"
                      : "tagline--label--danger"
                  }`}
                >
                  {jobStatus === "reopen"
                    ? "Reopen job"
                    : jobStatus === "pause"
                    ? "Pause Job"
                    : "Close Job"}
                </span>{" "}
                {jobStatus === "reopen" && selectedJob?.assignedId !== null && (
                  <span className="tagline--text">(Step 1 of 2)</span>
                )}
              </h3>
              <h4 className="title">
                Reason for{" "}
                {jobStatus === "reopen"
                  ? "Reopening"
                  : jobStatus === "pause"
                  ? "Pausing"
                  : "Closing"}{" "}
                this job?
              </h4>
              <div className="formField">
                {JobList?.map((item, index) => {
                  return (
                    <div className="formField__checkbox" key={index}>
                      <FormControlLabel
                        className="pb15"
                        onChange={(e) => handleCheckboxChange(e, item)}
                        checked={checkboxValue[item] || false}
                        control={<Checkbox />}
                      />
                      <span className="pl5">{item}</span>
                    </div>
                  );
                })}
              </div>
              <div className={`formField ${textareaError ? "fieldError" : ""}`}>
                <label className="formField__label">
                  Please Explain Further{" "}
                  <span style={{ opacity: ".5" }}>
                    {`${
                      Object.keys(checkboxValue)[0] !== "Other"
                        ? "(Optional)"
                        : ""
                    }`}
                  </span>
                </label>
                <textarea
                  className="formField__textarea"
                  value={explainationtext}
                  onChange={(e) => handleTextAreaChange(e)}
                  rows={5}
                  maxLength={300}
                  placeholder="Enter your note here..."
                ></textarea>
                <span className="formField__error">{textareaError}</span>
              </div>
            </div>
            <div className="modal__footer">
              <div className="btn__group">
                <button
                  type="button"
                  className="btn btn__white"
                  onClick={() => {
                    handleCloseJobModal();
                    setResetJobModal(!resetJobModal);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn__primary"
                  onClick={() => handleNext(jobStatus)}
                  disabled={
                    Object.keys(checkboxValue).length <= 0 || textareaError
                      ? true
                      : false
                  }
                >
                  {jobStatus === "close"
                    ? "Close Job"
                    : jobStatus === "reopen" && selectedJob?.assignedId !== null
                    ? "Next"
                    : jobStatus === "pause"
                    ? "Pause Job"
                    : "Reopen Job"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
      {OpenConfirmationModal && (
        <ConfirmationModal
          closeModal={() => setOpenConfirmationModal(false)}
          onSubmit={handleJobStatusChange}
          heading={jobStatus === "pause" ? "Pause job!" : "Close Job!"}
          confirmationMessage={`Are you sure you want to ${
            jobStatus === "pause" ? "pause" : "close"
          } this job?`}
        />
      )}
      {(pauseAPostJobRes?.meta?.status ||
        ReopenAPostJobRes?.meta?.status ||
        closeJobPostResponse?.meta?.status) && (
        <SuccessModal
          closeModal={closeSuccessfullModal}
          sucessfullMsg={
            jobStatus === "pause"
              ? pauseAPostJobRes?.meta?.msg
              : jobStatus === "reopen"
              ? ReopenAPostJobRes?.meta?.msg
              : jobStatus === "close"
              ? closeJobPostResponse?.meta?.msg
              : ""
          }
          Heading={
            jobStatus === "pause"
              ? "Job Paused!"
              : jobStatus === "reopen"
              ? "Job Reopened!"
              : jobStatus === "close"
              ? "Job Closed!"
              : ""
          }
        />
      )}
      {(pauseAPostJobRes?.meta?.status === false ||
        ReopenAPostJobRes?.meta?.status === false ||
        closeJobPostResponse?.meta?.status === false) && (
        <ErrorModal
          errorMessage={
            jobStatus === "pause"
              ? pauseAPostJobRes?.meta?.msg
              : jobStatus === "reopen"
              ? ReopenAPostJobRes?.meta?.msg
              : jobStatus === "close"
              ? closeJobPostResponse?.meta?.msg
              : ""
          }
          closeModal={closeSuccessfullModal}
          footerVisible={true}
        />
      )}
      <RecruiterReassignModal
        openModal={OpenAssignRecruiterModal}
        closeModal={() => setOpenAssignRecruiterModal(false)}
        selectedJob={selectedJob}
        staticData={data}
        setRecruiterCheckboxValue={setRecruiterCheckboxValue}
        RecruiterCheckboxValue={RecruiterCheckboxValue}
        onSubmit={handleJobStatusChange}
        setOpenAssignRecruiterModal={setOpenAssignRecruiterModal}
        setOpenActionOnJobModal={setOpenActionOnJobModal}
        setOpenRecruiterDetailPage={setOpenRecruiterDetailPage}
      />
      {pauseAPostJobRes?.loading && <Loader />}
      {openErrorModal && (
        <ErrorModal
          errorMessage={
            "The job is currently closed. Kindly reopen it to proceed with further actions."
          }
          closeModal={() => {
            setOpenErrorModal(false);
            refreshJobListing();
          }}
          footerVisible={true}
        />
      )}
    </>
  );
};

export default PauseStopReopenJobModal;
