import * as Yup from "yup";
import {
  phoneNumberRegex,
  LinkedInRegex,
  isValidName,
  TinNumberRegx,
  SSNNumberRegx,
  EINNumberRegx,
  NumberRegx,
} from "@/utils/regex";
import * as CONSTANTS from "@/utils/constants";

export const recruiterFormSchema = Yup.object().shape({
  email: Yup.string()
    .email(CONSTANTS.EMAIL.validation.valid)
    .required(CONSTANTS.EMAIL.validation.required),
  linkedin: Yup.string().matches(
    LinkedInRegex,
    CONSTANTS.LINKEDIN.validation.valid
  ),
  firstName: Yup.string()
    .min(1, CONSTANTS.FIRST_NAME.validation.valid)
    .required(CONSTANTS.FIRST_NAME.validation.valid),
  lastName: Yup.string()
    .min(1, CONSTANTS.LAST_NAME.validation.valid)
    .required(CONSTANTS.LAST_NAME.validation.valid),
  skillSet: Yup.array()
    .min(1, CONSTANTS.SELECTED_SKILL_SET.validation.valid)
    .of(Yup.string().required()),
  experience: Yup.number()
    .required()
    .min(0)
    .max(99),
  phoneNumber: Yup.string().matches(
    phoneNumberRegex,
    CONSTANTS.PHONE_NUMBER.validation.valid
  ),
  // graduationYear: Yup.number()
  //   .min(1930, CONSTANTS.GRADUATION_YEAR.validation.valid)
  //   .max(2034, CONSTANTS.GRADUATION_YEAR.validation.valid)
});

export const candidateFormSchema = Yup.object().shape({
  email: Yup.string()
    .email(CONSTANTS.EMAIL.validation.valid)
    .required(CONSTANTS.EMAIL.validation.required),
  firstName: Yup.string()
    .min(1, CONSTANTS.FIRST_NAME.validation.valid)
    .required(CONSTANTS.FIRST_NAME.validation.valid),
  lastName: Yup.string()
    .min(1, CONSTANTS.LAST_NAME.validation.valid)
    .required(CONSTANTS.LAST_NAME.validation.valid),
  skillset: Yup.array()
    .required("Skill set must have at least 1 item.")
    .min(1, "Skill set must have at least 1 item.")
    .of(Yup.string().required()),
  experience: Yup.number()
    .required("Experience is required.")
    .min(0, "Experience must be greater than or equal to 0.")
    .max(99, "Experience must be less than or equal to 99."),
  education: Yup.string().required("Education is required."),
  graduationYear: Yup.number()
    .min(1930, CONSTANTS.GRADUATION_YEAR.validation.valid)
    .max(2034, CONSTANTS.GRADUATION_YEAR.validation.valid),
  linkedin: Yup.string().matches(
    LinkedInRegex,
    CONSTANTS.LINKEDIN.validation.valid
  ),
  phoneNumber: Yup.string()
    .required("Phone number is required.")
    .matches(phoneNumberRegex, CONSTANTS.PHONE_NUMBER.validation.valid),
});

export const registerStepOneSchema = Yup.object().shape({
  email: Yup.string()
    .email(CONSTANTS.EMAIL.validation.valid)
    .required(CONSTANTS.EMAIL.validation.required),
  firstName: Yup.string()
    .min(1, CONSTANTS.FIRST_NAME.validation.valid)
    .required(CONSTANTS.FIRST_NAME.validation.valid),
  lastName: Yup.string()
    .min(1, CONSTANTS.LAST_NAME.validation.valid)
    .required(CONSTANTS.LAST_NAME.validation.valid),
  password: Yup.string()
    .required(CONSTANTS.PASSWORD.validation.required)
    .min(8, CONSTANTS.PASSWORD.validation.valid)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]+$/,
      "Password must be at least one uppercase letter, one lowercase letter, one special character, and one digit."
    ),
  confirmPassword: Yup.string()
    .required(CONSTANTS.CONFIRM_PASSWORD.validation.required)
    .oneOf(
      [Yup.ref("password"), null],
      CONSTANTS.CONFIRM_PASSWORD.validation.match
    ),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(phoneNumberRegex, CONSTANTS.PHONE_NUMBER.validation.valid),
});

export const registerStepTwoSchema = Yup.object().shape({
  skillSet: Yup.array()
    .min(1, CONSTANTS.SELECTED_SKILL_SET.validation.valid)
    .of(Yup.string().required()),
  experience: Yup.number()
    .required(CONSTANTS.EXPERIENCE.validation.valid)
    .min(0)
    .max(99),
  graduationYear: Yup.number()
    .min(1930, CONSTANTS.GRADUATION_YEAR.validation.valid)
    .max(2034, CONSTANTS.GRADUATION_YEAR.validation.valid),
  linkedin: Yup.string().matches(
    LinkedInRegex,
    CONSTANTS.LINKEDIN.validation.valid
  ),
});

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email(CONSTANTS.EMAIL.validation.valid)
    .required(CONSTANTS.EMAIL.validation.required),
  password: Yup.string()
    .required(CONSTANTS.PASSWORD.validation.required)
    .min(8, CONSTANTS.PASSWORD.validation.valid),
});

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email(CONSTANTS.EMAIL.validation.valid)
    .required(CONSTANTS.EMAIL.validation.required),
});

export const resetSchema = Yup.object().shape({
  password: Yup.string()
    .required(CONSTANTS.PASSWORD.validation.required)
    .min(8, CONSTANTS.PASSWORD.validation.valid)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]+$/,
      "Password must be at least one uppercase letter, one lowercase letter, one special character, and one digit."
    ),
  confirmPassword: Yup.string()
    .required(CONSTANTS.CONFIRM_PASSWORD.validation.required)
    .oneOf(
      [Yup.ref("password"), null],
      CONSTANTS.CONFIRM_PASSWORD.validation.match
    ),
});

export const meetingSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .matches(isValidName, "Please enter valid name")
    .min(1, CONSTANTS.NAME.validation.valid)
    .required(CONSTANTS.NAME.validation.valid),
  email: Yup.string()
    .email(CONSTANTS.EMAIL.validation.valid)
    .required(CONSTANTS.EMAIL.validation.required),
});

export const addEducationFormSchema = Yup.object().shape({
  education: Yup.string().required("Education is required."),
  institutionName: Yup.string().required("Institution name is required."),
  graduationStartMonth: Yup.string().required("Start month is required."),
  graduationStartYear: Yup.string().required("Start year is required."),
  graduationEndMonth: Yup.string().required("End month is required."),
  graduationEndYear: Yup.string()
    .required("End year is required.")
    .test(
      "valid-end-year",
      "End year should not be less than start year.",
      function(value, { parent }) {
        const startYear = parseInt(parent.graduationStartYear, 10);
        const endYear = parseInt(value, 10);
        return !isNaN(startYear) && !isNaN(endYear) && endYear >= startYear;
      }
    )
    .test(
      "valid-end-month",
      "The start date cannot be later than the end date.",
      function(value, { parent }) {
        const startYear = parseInt(parent.graduationStartYear, 10);
        const endYear = parseInt(value, 10);
        if (startYear === endYear) {
          const startMonth = parent.graduationStartMonth;
          const endMonth = parent.graduationEndMonth;
          const month = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          return month.indexOf(endMonth) >= month.indexOf(startMonth);
        } else {
          return true;
        }
      }
    ),
  majorStudy: Yup.string().required("Major study is required."),
});

export const taxResidentSchema = Yup.object().shape({
  country: Yup.string().required(CONSTANTS.COUNTRY.validation.valid),
  address1: Yup.string().required(CONSTANTS.ADDRESS.validation.valid),
  city: Yup.string().required(CONSTANTS.CITY.validation.valid),
  state: Yup.string().required(CONSTANTS.STATE.validation.valid),
  zipcode: Yup.string().when("country", {
    is: (country) => country === "United States",
    then: (s) => s.required("Please enter zipcode"),
  }),
});

export const creditCardFormSchema = Yup.object().shape({
  // cardNumber: Yup.string().required(CONSTANTS.CARD_NUMBER.validation.required),
  // .matches(NumberRegx, CONSTANTS.CARD_NUMBER.validation.valid)
  // .min(14, CONSTANTS.CARD_NUMBER.validation.valid)
  // .max(16, CONSTANTS.CARD_NUMBER.validation.valid),
  firstName: Yup.string()
    .min(1, CONSTANTS.FIRST_NAME.validation.valid)
    .required(CONSTANTS.FIRST_NAME.validation.valid),
  lastName: Yup.string()
    .min(1, CONSTANTS.LAST_NAME.validation.valid)
    .required(CONSTANTS.LAST_NAME.validation.valid),
  // month: Yup.string().required("Expiration month is required."),
  // year: Yup.string().required("Expiration year is required."),
  // securityCode: Yup.string()
  //   .required(CONSTANTS.SECURITY_CODE.validation.required)
  //   .matches(NumberRegx, CONSTANTS.SECURITY_CODE.validation.valid)
  //   .min(3, CONSTANTS.SECURITY_CODE.validation.valid)
  //   .max(4, CONSTANTS.SECURITY_CODE.validation.valid),
  country: Yup.string().required(CONSTANTS.COUNTRY.validation.valid),
  // address1: Yup.string().required(CONSTANTS.ADDRESS.validation.valid),
  // city: Yup.string().required(CONSTANTS.CITY.validation.valid),
  // state: Yup.string().required(CONSTANTS.STATE.validation.valid),
  // zipcode: Yup.string().when("country", {
  //   is: (country) => country === "United States",
  //   then: (s) => s.required("Please enter zipcode"),
  // }),
});

export const taxIdentificationSchema = Yup.object().shape({
  citizenType: Yup.string().required(CONSTANTS.TAX_PAYER_NAME.validation.valid),
  legalnameoftaxpayer: Yup.string().required(
    CONSTANTS.TAX_PAYER_NAME.validation.valid
  ),
  businessName: Yup.string().required(
    CONSTANTS.TAX_PAYER_NAME.validation.valid
  ),
  taxType: Yup.object().required(
    CONSTANTS.TAX_CLASSIFICATIONS.validation.valid
  ),
  ssnein: Yup.string().when("citizenType", {
    is: (citizenType) => citizenType === "us-person",
    then: (s) =>
      s
        .required("SSN/EIN number is required")
        .test("valid-formate", "Please enter valid number.", function(
          value,
          { parent }
        ) {
          let type = parent.identificationNumberType;
          if (type === "SSN") {
            return SSNNumberRegx.test(value);
          } else if (type === "EIN") {
            return EINNumberRegx.test(value);
          }
          return false;
        }),
  }),
  signaturefield: Yup.string().when("citizenType", {
    is: (citizenType) => citizenType === "us-person",
    then: (s) => s.required(CONSTANTS.SIGNATURE.validation.valid),
  }),
  agreement: Yup.array().when("citizenType", {
    is: (citizenType) => citizenType === "us-person",
    then: (s) =>
      s
        .test(
          "valid-agreement-array",
          "please check term and condition.",
          function(value, { parent }) {
            if (value.includes("electronicSignature")) {
              return true;
            }
            return false;
          }
        )
        .min(1, "please check term and condition"),
  }),
  identificationNumberType: Yup.string().when("citizenType", {
    is: (citizenType) => citizenType === "us-person",
    then: (s) => s.required("Please check one identification number type"),
  }),
  dateOfBirth: Yup.string().when(["citizenType", "taxType"], {
    is: (citizenType, taxType) =>
      citizenType === "none-us-person" && taxType?.value === "Individual",
    then: (s) => s.required("Please select date of birth"),
  }),
  citizenShipCountry: Yup.object().when("citizenType", {
    is: (citizenType) => citizenType === "none-us-person",
    then: (s) => s.required(CONSTANTS.COUNTRY.validation.valid),
  }),
});

export const taxIdentificationNumberSchema = Yup.object().shape({
  tinNumber: Yup.string()
    .required(CONSTANTS.TAX_IDENTIFICATION_NUMBER.validation.valid)
    .max(10, "TIN number should be in 10 digit alphanumeric."),
  // .matches(
  //   TinNumberRegx,
  //   "TIN should be in xxx-xx-xxxx format."
  // ),
  signature1: Yup.string().test(
    "valid-formate",
    "The tax certification signature and affidavit signature don’t match.",
    function(value, { parent }) {
      let sig2 = parent.signature2;
      if (sig2) {
        if (value === sig2) {
          return true;
        }
        return false;
      }
      return true;
    }
  ),
  agreement: Yup.array()
    .required("please check term and condition")
    .min(1, "please check term and condition"),
  signature2: Yup.string().oneOf(
    [Yup.ref("signature1"), null],
    "The tax certification signature and affidavit signature don’t match."
  ),
});

export const BankAccountDetailSchema = Yup.object().shape({
  accountHolderName: Yup.string().required(
    CONSTANTS.ACCOUNT_HOLDER_NAME.validation.valid
  ),
  accountType: Yup.string().required(CONSTANTS.ACCOUNT_TYPE.validation.valid),
  routingNumber: Yup.string()
    .required(CONSTANTS.ROUTING_NUMBER.validation.valid)
    .min(9, CONSTANTS.ROUTING_NUMBER.validation.minValid),
  accountNumber: Yup.string()
    .required(CONSTANTS.ACCOUNT_NUMBER.validation.valid)
    .matches(NumberRegx, CONSTANTS.ACCOUNT_NUMBER.validation.minValid)
    .min(7, CONSTANTS.ACCOUNT_NUMBER.validation.minValid)
    .max(17, CONSTANTS.ACCOUNT_NUMBER.validation.minValid),
  confirmAccountNumber: Yup.string()
    .required(CONSTANTS.ACCOUNT_NUMBER.validation.valid)
    .oneOf(
      [Yup.ref("accountNumber"), null],
      CONSTANTS.ACCOUNT_NUMBER.validation.match
    ),
  bankName: Yup.string().required(CONSTANTS.BANK_NAME.validation.valid),
});

export const WithdrawlScheduleSchema = Yup.object().shape({
  scheduleOption: Yup.string().required(
    "Withdrawl schedule method id required."
  ),
  withdrawalAmount: Yup.string().required("Withdrawl amount is required."),
});

export const VerifyACHMethodSchema = Yup.object().shape({
  // firstDeposit: Yup.string().required("First deposit is required."),
  // secondDeposit: Yup.string().required("Second deposit is required."),
  descriptorCode: Yup.string().required("Descriptor code is required."),
});
