import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SideBar from "@/components/layout/SideBar";
import Loader from "@/elements/Loader";
import TaxResidentDetails from "@/pages/Settings/TaxInformation/TaxResidentDetails.jsx";
import TaxPayerIdentificationDetails from "@/pages/Settings/TaxInformation/TaxPayerIdentificationDetails.jsx";
import TaxPayerIdentificationNumber from "@/pages/Settings/TaxInformation/TaxPayerIdentificationNumber.jsx";
import taxInformation from "@/rtk-apis/tax/taxInformation";

const Settings = () => {
  const dispatch = useDispatch();
  const { data = false, loading, meta } = useSelector((globalState) => {
    return globalState.taxInformationResponse;
  });

  useEffect(() => {
    getTaxInformation();
  }, []);

  const getTaxInformation = () => {
    dispatch(taxInformation());
  };

  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid layoutGrid--full">
          <div className="dataCol">
            <div className="dataCol">
              <div className="page page__full">
                <div className="page__head">
                  <h2 className="page__head--title">Tax Information</h2>
                </div>
                <div className="page__body">
                  <div className="details details--taxDetails">
                    <TaxResidentDetails
                      taxInfoStatus={Object.keys(data).length ? true : false}
                      taxDetails={data?.taxResidence}
                      refreshTaxInfo={getTaxInformation}
                      citizenType={data?.usCitizen}
                    />
                    <TaxPayerIdentificationDetails
                      taxInfoStatus={Object.keys(data).length ? true : false}
                      taxDetails={data?.taxPayerIdentification}
                      refreshTaxInfo={getTaxInformation}
                      residenceCountry={data?.taxResidence?.countryName}
                    />
                    {Object.keys(data).length &&
                      Object.keys(data?.taxPayerIdentification).length &&
                      data?.usCitizen === false ? (
                        <TaxPayerIdentificationNumber
                          taxInfoStatus={
                            Object.keys(data).length ? true : false
                          }
                          taxDetails={data?.taxPayerIdentification}
                          refreshTaxInfo={getTaxInformation}
                          formCompletedStatus={data?.formCompletedStatus}
                        />
                      ):""}
                    {loading && <Loader />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
