import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIconGray from "@/assets/svgs/CloseIconGray";
import ArrowDownTag from "@/assets/svgs/ArrowDownTag";
import TagIcon from "@/assets/svgs/TagIcon";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import getAllTagsList from "@/rtk-apis/customer/tags/getAllTagsList";
import addTagToCandidate from "@/rtk-apis/customer/tags/addTagToCandidate";
import { GetUserDetails, titleCase } from "@/utils/helper";
import ColorPickersDropdown from "@/components/colorPicker/ColorPickersDropdown";
import { closeRtkModal } from "@/store/slices/customer/tags/addTagToCandidateSlice";
import ErrorModal from "@/components/modals/error-modal";
import { SuccessModal } from "@/components/modals/SuccessModal";
import addTagsToJob from "@/rtk-apis/customer/tags/jobTags/addTagsToJob";
import { closeJobTagModal } from "../../store/slices/customer/tags/jobTagsSlice/addTagsToJobSlice";

const AddTagsModal = ({
  openModal,
  closeModal,
  tagData,
  refreshPage,
  fromJobSection,
  setRefreshJobDetails,
  RefreshJobDetails,
}) => {
  const dispatch = useDispatch();
  const userDetails = GetUserDetails();
  const [selectedTagName, setSelectedTagName] = useState(tagData?.tags ?? []);
  const [newTagName, setNewTagName] = useState("");
  const [newTagColorCode, setNewTagColorCode] = useState("#28a0e8");
  const [openColorPicker, setOpenColorPicker] = useState(false);

  const { data } = useSelector((globalState) => {
    return globalState?.getAllTagsListRes;
  });

  const addTagData = useSelector((globalState) => {
    return globalState?.addTagToCandidate;
  });

  const addJobTagsRes = useSelector((globalState) => {
    return globalState?.addTagsToJobResponse;
  });

  useEffect(() => {
    setSelectedTagName(tagData?.tags ?? []);
  }, [tagData?.tags, fromJobSection]);

  useEffect(() => {
    refreshPage();
  }, [addTagData?.meta?.status]);

  useEffect(() => {
    if (fromJobSection) {
      setRefreshJobDetails(!RefreshJobDetails);
    }
  }, [addJobTagsRes?.meta?.status]);

  useEffect(() => {
    dispatch(
      getAllTagsList({
        companyId: userDetails?.customer?.companyId,
        tagName: "",
        page: "1",
        limit: "20",
        from: "addTagFetch",
        status: "activate",
      })
    );
  }, []);

  const handleNewTagColor = (colorCode) => {
    setNewTagColorCode(colorCode);
  };

  const checkAddTagBtnDisable = () => {
    let status = false;
    if (selectedTagName?.length === 0 && newTagName === "") {
      status = true;
    }
    return status;
  };

  const addCandidateTag = () => {
    let newTagArray = [];
    selectedTagName.map((item) => {
      let newTagData = {
        id: item?.id,
        tagName: item?.tagName,
        colorCode: item?.colorCode,
      };
      newTagArray.push(newTagData);
    });
    if (newTagName && newTagColorCode) {
      newTagArray.push({
        id: null,
        tagName: newTagName,
        colorCode: newTagColorCode,
      });
    }
    if (fromJobSection) {
      let body = {
        jobId: tagData?.jobId,
        companyId: userDetails?.customer?.companyId,
        employeeId: userDetails?.customer?.employeeId,
        tagArray: newTagArray,
      };
      dispatch(addTagsToJob(body));
    } else {
      dispatch(
        addTagToCandidate({
          candidateId: tagData?.candidateId,
          companyId: userDetails?.customer?.companyId,
          employeeId: userDetails?.customer?.employeeId,
          interviewId: tagData?.interviewId,
          tagArray: newTagArray,
        })
      );
    }
  };

  const closeAddResModal = () => {
    dispatch(closeJobTagModal());
    dispatch(closeRtkModal());
    closeModal();
    setNewTagName("");
  };

  return (
    <>
      <div
        className={`modal modalForm modalGiveFeedback ${
          openModal ? "modal--open" : ""
        }`}
      >
        <div className="modal__dialog ">
          <div className="modal__content modal__content--xxxl">
            <div className="modal__header">
              <h2 className="modal__header--title">Add Tag</h2>
              <button
                onClick={() => {
                  closeModal();
                  setNewTagName("");
                }}
                className="btn btn__transparent btnClose"
              >
                <CloseIconGray />
              </button>
            </div>
            <div className="modal__body modal-body-left">
              <div className="formField">
                <label className="formField__label">Tags</label>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  getOptionDisabled={(options) =>
                    selectedTagName?.length > 1 ? true : false
                  }
                  options={data?.rows || []}
                  getOptionLabel={(option) =>
                    titleCase(option?.tagName) || option
                  }
                  value={selectedTagName}
                  inputValue={newTagName}
                  name="tagName"
                  onChange={(event, newValue) => {
                    if (newValue?.length) {
                      setSelectedTagName(newValue);
                    } else {
                      setSelectedTagName([]);
                      setNewTagName("");
                      setNewTagColorCode("");
                      setOpenColorPicker(false);
                    }
                  }}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={titleCase(option?.tagName)}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      placeholder="Select"
                      className="autoCompleteInput"
                      onChange={(e) => {
                        setNewTagName(e?.target?.value);
                      }}
                    />
                  )}
                />
                <span className="noteText">
                  Note: You can add maximum of 2 tags
                </span>
              </div>
              {selectedTagName?.length < 2 && newTagName && (
                <div>
                  <div className="addCustomTag">
                    <div
                      className="tagDropdown"
                      onClick={() => setOpenColorPicker(!openColorPicker)}
                    >
                      <span
                        className="tagDropdown__colorFill"
                        style={{
                          backgroundColor: newTagColorCode
                            ? newTagColorCode
                            : "#28a0e8",
                        }}
                      ></span>
                      <ArrowDownTag />
                    </div>
                    <span className="newTaglabel">
                      <TagIcon />
                      New Tag :{" "}
                      <span className="newTaglabel__value">{newTagName}</span>
                    </span>
                  </div>
                  <ColorPickersDropdown
                    open={openColorPicker}
                    handleColorChange={handleNewTagColor}
                    close={() => setOpenColorPicker(false)}
                  />
                </div>
              )}
              <div className="btn__group">
                <button
                  className="btn btn__white"
                  onClick={() => {
                    closeModal();
                    setNewTagName("");
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn__primary"
                  disabled={checkAddTagBtnDisable()}
                  onClick={() => addCandidateTag()}
                >
                  Add Tag
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
      {(addTagData?.meta?.status || addJobTagsRes?.meta?.status) && (
        <SuccessModal
          closeModal={closeAddResModal}
          sucessfullMsg={
            fromJobSection ? addJobTagsRes?.meta?.msg : addTagData?.meta?.msg
          }
          Heading={"Tag Added!"}
        />
      )}
      {(addTagData?.meta?.status === false ||
        addJobTagsRes?.meta?.status === false) && (
        <ErrorModal
          errorMessage={
            fromJobSection ? addJobTagsRes?.meta?.msg : addTagData?.meta?.msg
          }
          closeModal={closeAddResModal}
          footerVisible={true}
        />
      )}
    </>
  );
};

export default AddTagsModal;
