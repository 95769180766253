import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import SideBar from "@/components/layout/SideBar";
import { resetSchema } from "@/components/schema/schema";
import AuthImage from "@/assets/images/auth-img.png";
import CloseEye from "@/assets/svgs/CloseEye";
import OpenEye from "@/assets/svgs/OpenEye";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Loader from "@/elements/Loader";
import changePassword from "@/rtk-apis/changePassword";
import { SuccessModal } from "@/components/modals/SuccessModal";
import { closeRtkModal } from "@/store/slices/changePasswordSlice";
import { GetUserDetails } from "@/utils/helper";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const user = GetUserDetails();
  let initialValues = {
    password: "",
    confirmPassword: "",
  };

  const [eyeForPassword, seteyeForPassword] = useState(false);
  const [eyeForConfirmPassword, seteyeForConfirmPassword] = useState(false);
  const [role, setRole] = useState(user?.usertype);

  const closeModal = () => {
    dispatch(closeRtkModal());
  };

  const { loading, meta } = useSelector((globalState) => {
    return globalState.changePasswordResponse;
  });

  const handleSubmit = (values) => {
    dispatch(
      changePassword({
        password: values?.password,
        oryId: user?.Oryid,
        role: role === "customer" ? "customer" : "recruiter",
      })
    );
  };

  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid layoutGrid--full">
          <div className="dataCol">
            <div className="page page__full">
              <div className="page__head">
                <h2 className="page__head--title">Change Password</h2>
              </div>

              <div className="page__body">
                <div className="auth__container">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={resetSchema}
                    onSubmit={(e, values) => handleSubmit(e, values)}
                  >
                    {({ errors, touched }) => (
                      <Form className="auth__form">
                        <img
                          src={AuthImage}
                          className="auth__form--img"
                          alt="Authentication"
                        />
                        <div
                          className={`formField formField__mb5 ${
                            errors?.password && touched?.password
                              ? "fieldError"
                              : ""
                          }`}
                        >
                          <label className="formField__label">
                            New Password
                          </label>
                          <div className="formField__group">
                            <Field
                              type={eyeForPassword ? "text" : "password"}
                              className="formField__input"
                              placeholder="Enter new password"
                              name="password"
                              autoComplete="off"
                            />
                            <div
                              onClick={() => seteyeForPassword(!eyeForPassword)}
                              className="formField__icon"
                            >
                              <span>
                                {eyeForPassword ? <OpenEye /> : <CloseEye />}
                              </span>
                            </div>
                          </div>
                          <span className="formField__error">
                            {errors?.password &&
                            touched?.password &&
                            errors?.password ? (
                              errors?.password &&
                              touched?.password &&
                              errors?.password
                            ) : (
                              <i>
                                * The password must contain 8 characters and a
                                combination of lowercase letters, uppercase
                                letters, numbers, and special characters.
                              </i>
                            )}
                          </span>
                        </div>
                        <div
                          className={`formField formField__mb5 ${
                            errors?.confirmPassword && touched?.confirmPassword
                              ? "fieldError"
                              : ""
                          }`}
                        >
                          <label className="formField__label">
                            Confirm New Password
                          </label>
                          <div className="formField__group">
                            <Field
                              type={eyeForConfirmPassword ? "text" : "password"}
                              className="formField__input"
                              placeholder="Confirm new password"
                              name="confirmPassword"
                              autoComplete="off"
                            />
                            <div
                              onClick={() =>
                                seteyeForConfirmPassword(!eyeForConfirmPassword)
                              }
                              className="formField__icon"
                            >
                              <span>
                                {eyeForConfirmPassword ? (
                                  <OpenEye />
                                ) : (
                                  <CloseEye />
                                )}
                              </span>
                            </div>
                          </div>
                          <span className="formField__error">
                            {errors?.confirmPassword &&
                              touched?.confirmPassword &&
                              errors?.confirmPassword}
                          </span>
                        </div>
                        <div className="btn__group w_100">
                          <button
                            type="submit"
                            className="btn btn__primary w_100"
                          >
                            Update
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
        {meta?.status && (
          <SuccessModal
            closeModal={closeModal}
            sucessfullMsg={meta?.msg}
            Heading={"Password Changed!"}
          />
        )}
        {loading && <Loader />}
      </div>
    </>
  );
};

export default ChangePassword;
