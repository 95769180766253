import React, { useState } from "react";
import logo from "@/assets/images/logo.svg";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ProfileSvg from "@/assets/svgs/ProfileSvg";
import PasswordLock from "@/assets/svgs/PasswordLock";
import USAFlag from "@/assets/svgs/USAFlag";
import CloseEye from "@/assets/svgs/CloseEye";
import OpenEye from "@/assets/svgs/OpenEye";
import { Formik, Form, Field } from "formik";
import { registerStepOneSchema } from "@/components/schema/schema";
import { firstLetterCapital, formatPhoneNumber } from "@/utils/helper";

function SignUpStepOne(props) {
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const signupStepOnevalues = JSON.parse(
    localStorage.getItem("signupSteponeValues")
  );

  let initialValues = {
    firstName: signupStepOnevalues
      ? signupStepOnevalues?.firstName
      : props?.firstname,
    lastName: props?.lastname ? props?.lastname : signupStepOnevalues?.lastName,
    phoneNumber: props?.phoneNumber
      ? props?.phoneNumber
      : signupStepOnevalues?.phoneNumber,
    email: props?.email ? props?.email : signupStepOnevalues?.email,
    password: signupStepOnevalues ? signupStepOnevalues?.password : "",
    confirmPassword: signupStepOnevalues
      ? signupStepOnevalues?.confirmPassword
      : "",
  };

  if (searchParams?.size) {
    initialValues.firstName = searchParams.get("firstName");
    initialValues.lastName = searchParams.get("lastName");
    initialValues.email = searchParams.get("email");
    initialValues.phoneNumber = searchParams.get("phoneNumber");
  }

  const [eyeForPassword, seteyeForPassword] = useState(false);
  const [eyeForConfirmPassword, seteyeForConfirmPassword] = useState(false);
  const [TnC, setTnC] = useState(false);

  const goToStepTwo = (values) => {
    localStorage.setItem("signupSteponeValues", JSON.stringify(values));
    props.goToStepTwo(values);
  };

  return (
    <div>
      <section className="signup">
        <div className="signup__flex">
          <div className="signup__item signup__item--bg">
            <div className="welcomeInfo welcomeInfo--center">
              <h1 className="title title--h1">Welcome to Laila</h1>
              <p className="text">The future of recruiting starts here.</p>
            </div>
          </div>
          <div className="signup__item signup__item--form">
            <Formik
              initialValues={initialValues}
              validationSchema={registerStepOneSchema}
              onSubmit={(values) => goToStepTwo(values)}
            >
              {({ errors, values, handleBlur, touched }) => (
                <Form autoComplete="off" className="signup__form">
                  <div className="branding">
                    <div className="branding__logo">
                      <img
                        src={logo}
                        alt="Logo | Laila"
                        className="branding__logo__img"
                      />
                      <p className="branding__subtitle">
                        Sign up as a{" "}
                        {searchParams?.size || state?.signUpAs == "recruiter"
                          ? "recruiter"
                          : "customer"}
                      </p>
                    </div>
                    <div className="branding__info">
                      <h1 className="title title--h1">Create Account</h1>
                    </div>
                  </div>
                  <div
                    className={`formField formField__mb5 ${
                      errors?.firstName && touched?.firstName
                        ? "fieldError"
                        : ""
                    }`}
                  >
                    <div className="formField__group bg--light">
                      <span className="formField__icon">
                        <ProfileSvg />
                      </span>
                      <Field
                        type="text"
                        className="formField__input bg--light"
                        onBlur={handleBlur}
                        placeholder="Enter first name"
                        name="firstName"
                        value={firstLetterCapital(values?.firstName)}
                      />
                    </div>
                    <span className="formField__error">
                      {errors?.firstName &&
                        touched?.firstName &&
                        errors?.firstName}
                    </span>
                  </div>
                  <div
                    className={`formField formField__mb5 ${
                      errors?.lastName && touched?.lastName ? "fieldError" : ""
                    }`}
                  >
                    <div className="formField__group bg--light">
                      <span className="formField__icon">
                        <ProfileSvg />
                      </span>
                      <Field
                        type="text"
                        className="formField__input bg--light"
                        onBlur={handleBlur}
                        placeholder="Enter last name"
                        value={firstLetterCapital(values?.lastName)}
                        name="lastName"
                      />
                    </div>
                    <span className="formField__error">
                      {errors?.lastName &&
                        touched?.lastName &&
                        errors?.lastName}
                    </span>
                  </div>
                  <div
                    className={`formField formField__mb5 ${
                      errors?.email && touched?.email ? "fieldError" : ""
                    }`}
                  >
                    <div className="formField__group bg--light">
                      <span className="formField__icon">
                        <PasswordLock />
                      </span>
                      <Field
                        type="text"
                        className="formField__input bg--light"
                        onBlur={handleBlur}
                        placeholder="Enter email address"
                        name="email"
                        disabled={props?.email}
                      />
                    </div>
                    <span className="formField__error">
                      {errors?.email && touched?.email && errors?.email}
                    </span>
                  </div>
                  <div
                    className={`formField formField__mb5 ${
                      errors?.phoneNumber &&
                      touched?.phoneNumber &&
                      errors?.phoneNumber
                        ? "fieldError"
                        : ""
                    }`}
                  >
                    <div className="formField__group formField__phoneNumber formField__phoneNumberFlag bg--light">
                      <FormControl
                        fullWidth
                        size="small"
                        className="formField__select bg--light"
                      >
                        <Select displayEmpty>
                          <MenuItem>
                            <USAFlag />
                            USA
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <Field
                        type="text"
                        className="formField__input sm"
                        placeholder="Phone Number"
                        name="phoneNumber"
                        autoComplete="off"
                        maxLength="10"
                        value={formatPhoneNumber(values?.phoneNumber)}
                      />
                    </div>
                    <span className="formField__error">
                      {errors?.phoneNumber &&
                        touched?.phoneNumber &&
                        errors?.phoneNumber}
                    </span>
                  </div>
                  <div
                    className={`formField formField__mb5 ${
                      errors?.password && touched?.password ? "fieldError" : ""
                    }`}
                  >
                    <div className="formField__group bg--light">
                      <span className="formField__icon">
                        <PasswordLock />
                      </span>
                      <Field
                        type={eyeForPassword ? "text" : "password"}
                        className="formField__input bg--light"
                        onBlur={handleBlur}
                        placeholder="Enter new password"
                        name="password"
                        autoComplete="off"
                      />
                      <div
                        onClick={() => seteyeForPassword(!eyeForPassword)}
                        className="formField__icon"
                      >
                        <span>
                          {eyeForPassword ? <OpenEye /> : <CloseEye />}
                        </span>
                      </div>
                    </div>
                    <span className="formField__error">
                      {errors?.password &&
                      touched?.password &&
                      errors?.password ? (
                        errors?.password &&
                        touched?.password &&
                        errors?.password
                      ) : (
                        <i>
                          * The password must contain 8 characters and a
                          combination of lowercase letters, uppercase letters,
                          numbers, and special characters.
                        </i>
                      )}
                    </span>
                  </div>
                  <div
                    className={`formField formField__mb5 ${
                      errors?.confirmPassword && touched?.confirmPassword
                        ? "fieldError"
                        : ""
                    }`}
                  >
                    <div className="formField__group bg--light">
                      <span className="formField__icon">
                        <PasswordLock />
                      </span>
                      <Field
                        type={eyeForConfirmPassword ? "text" : "password"}
                        className="formField__input bg--light"
                        onBlur={handleBlur}
                        placeholder="Confirm new password"
                        name="confirmPassword"
                        autoComplete="off"
                      />
                      <div
                        onClick={() =>
                          seteyeForConfirmPassword(!eyeForConfirmPassword)
                        }
                        className="formField__icon"
                      >
                        <span>
                          {eyeForConfirmPassword ? <OpenEye /> : <CloseEye />}
                        </span>
                      </div>
                    </div>
                    <span className="formField__error">
                      {errors?.confirmPassword &&
                        touched?.confirmPassword &&
                        errors?.confirmPassword}
                    </span>
                  </div>
                  <div className="form__flexrow">
                    <div className="formField">
                      <div className="formField__checkbox">
                        <FormControlLabel
                          onClick={() => setTnC(!TnC)}
                          control={<Checkbox />}
                        />
                        <span>
                          By creating account you are agree to the
                          <a className="label__link" href="#">
                            Terms & Conditions
                          </a>
                          and
                          <a className="label__link" href="#">
                            Privacy Policy
                          </a>
                          of Laila.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="formField formField__mb5 ">
                    <button
                      type="submit"
                      disabled={TnC ? false : true}
                      className="btn btn__primary btn--xl w_100"
                    >
                      Create Account
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="bottomText">
              <p className="para para--center">
                Already have an account?{" "}
                <Link to="/signin" className="link">
                  Sign in
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SignUpStepOne;
