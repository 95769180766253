import React, { useRef, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@/assets/svgs/EditIcon";
import LinkIcon from "@/assets/svgs/LinkIcon";
import DefaultImg from "@/assets/images/default-img.png";
import CopyIcon from "@/assets/svgs/CopyIcon";
import { Link } from "react-router-dom";
import {
  firstLetterCapital,
  formatPhoneNumber,
  getInitial,
} from "@/utils/helper";
import { ProfileDeactivateModal } from "@/components/modals/profileDeactive-modal";
import ActivateIcon from "@/assets/svgs/ActivateIcon";
import DeactivateIcon from "@/assets/svgs/DeactivateIcon";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import { useDispatch, useSelector } from "react-redux";
import cancelCompanyDeactivationRequest from "@/rtk-apis/customer/companyProfile/cancelCompanyDeactivationRequest";
import { SuccessModal } from "@/components/modals/SuccessModal";
import ErrorModal from "@/components/modals/error-modal";
import { closeReqModal } from "@/store/slices/customer/companyProfile/cancelCompanyDeactivateRequestSlice";
import { GetUserDetails } from "@/utils/helper";

export const ProfileDetailPage = ({
  userDetails,
  setEditMode,
  setRefreshDetails,
  refreshDetails,
}) => {
  const copyLinkedinText = useRef(null);
  const dispatch = useDispatch();
  const LoggedInUser = GetUserDetails();
  const [openProfileDectivateModal, setOpenProfileDectivateModal] = useState(
    false
  );
  const [openActivationModel, setOpenActivationModel] = useState(false);

  const cancelRequestResponse = useSelector((globalState) => {
    return globalState.cancelCompanyDeactivateReqResponse;
  });

  function copyToClipboard() {
    copyLinkedinText.current.select();
    document.execCommand("copy");
  }
  const CancelDeactivationRequest = () => {
    const companyid = LoggedInUser?.customer?.companyId;
    const employeeid = LoggedInUser?.customer?.employeeId;
    let body = {
      companyId: companyid,
      employeeId: employeeid,
      from: "Employee",
    };
    dispatch(cancelCompanyDeactivationRequest(body));
  };

  const closeSuccessModal = () => {
    dispatch(closeReqModal());
    setRefreshDetails(!refreshDetails);
    setOpenActivationModel(false);
  };

  return (
    <>
      <div className="layoutGrid layoutGrid--full">
        <div className="layoutGrid__col">
          <div className="userDetailsCol">
            <div className="page page__full">
              <div className="page__head">
                <h2 className="page__head--title">Profile</h2>
                <div className="listCol__head--actions">
                  <Tooltip title="Edit" placement="bottom" arrow>
                    <button
                      className="btn btn__white btn__edit p_0"
                      onClick={() => setEditMode(true)}
                    >
                      <EditIcon />
                    </button>
                  </Tooltip>
                  {userDetails?.cancelStatus ? (
                    <Tooltip title="Cancel request" placement="bottom" arrow>
                      <button
                        className="btn btn__white btn__edit p_0"
                        onClick={() => setOpenActivationModel(true)}
                      >
                        <ActivateIcon />
                      </button>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Disable User" placement="bottom" arrow>
                      <button
                        className="btn border-red btn__edit p_0"
                        onClick={() => setOpenProfileDectivateModal(true)}
                      >
                        <DeactivateIcon />
                      </button>
                    </Tooltip>
                  )}
                </div>
              </div>

              <div className="page__body">
                <div className="details">
                  <section className="details__section">
                    <div className="flex flex--between">
                      <div className="initials initials__lg">
                        {getInitial(userDetails?.firstName)}
                      </div>
                    </div>
                    <div className="details__section--name">
                      {firstLetterCapital(userDetails?.firstName)}{" "}
                      {firstLetterCapital(userDetails?.lastName)}
                    </div>
                    <ul className="tags__list mt_1">
                      {userDetails?.phoneNumber && (
                        <li className="tags__list--item">
                          <span>Phone:</span>
                          +1 {formatPhoneNumber(userDetails?.phoneNumber)}
                        </li>
                      )}
                      {userDetails?.email && (
                        <li className="tags__list--item">
                          <span>Email:</span>
                          {userDetails?.email}
                        </li>
                      )}
                      {userDetails?.role && (
                        <li className="tags__list--item">
                          <span>Role:</span>
                          {userDetails?.role}
                        </li>
                      )}
                    </ul>
                  </section>

                  <section className="form__section">
                    <h4 className="form__section--title">
                      Company Information
                    </h4>
                    <div className="formRow">
                      <div className="formCol">
                        <div className="formField">
                          <div className="uploadField">
                            <div className="uploadImage">
                              <img
                                src={
                                  userDetails?.company?.companyLogo ??
                                  DefaultImg
                                }
                                alt="Company"
                                width="140"
                                height="140"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fieldBox flexRow">
                      <div className="flexCol">
                        <label className="flexCol__label">Company Name</label>
                        <div className="flexCol__text">
                          {userDetails?.company?.companyName
                            ? userDetails?.company?.companyName
                            : "--"}
                        </div>
                      </div>
                      <div className="flexCol">
                        <label className="flexCol__label">
                          Company Website
                        </label>
                        <Link
                          to={
                            userDetails?.company?.websiteLink
                              ? userDetails?.company?.websiteLink
                              : "#"
                          }
                          className="flexCol__text text--primary"
                          target="_blank"
                        >
                          {userDetails?.company?.websiteLink
                            ? userDetails?.company?.websiteLink
                            : "--"}
                          {userDetails?.company?.websiteLink && <LinkIcon />}
                        </Link>
                      </div>
                    </div>
                    <div className="fieldBox flexRow">
                      <div className="flexCol">
                        <label className="flexCol__label">
                          Company Linkedin{" "}
                        </label>
                        {userDetails?.company?.linkedin ? (
                          <div
                            className="formField__group sm"
                            style={{ maxWidth: "475px" }}
                          >
                            <input
                              type="text"
                              ref={copyLinkedinText}
                              className="formField__input"
                              value={
                                userDetails?.company?.linkedin
                                  ? userDetails?.company?.linkedin
                                  : ""
                              }
                              name="companyLinkedIn"
                              placeholder="Company LinkedIn"
                              readOnly
                            />
                            <span
                              className={"formField__icon cursor--pointer"}
                              onClick={copyToClipboard}
                            >
                              <CopyIcon />
                            </span>
                          </div>
                        ) : (
                          <div className="flexCol__text">{"--"}</div>
                        )}
                      </div>
                      <div className="flexCol">
                        <label className="flexCol__label">Industry Type</label>
                        <div className="flexCol__text">
                          {userDetails?.company?.industryType
                            ? userDetails?.company?.industryType?.join(", ")
                            : "--"}
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="details__section">
                    <h4 className="details__section--title">Address</h4>
                    <div className="fieldBox flexRow">
                      <div className="flexCol">
                        <label className="flexCol__label">Country</label>
                        <div className="flexCol__text">
                          {userDetails?.company?.countryName
                            ? userDetails?.company?.countryName
                            : "--"}
                        </div>
                      </div>
                      <div className="flexCol">
                        <label className="flexCol__label">Zipcode</label>
                        <div className="flexCol__text">
                          {userDetails?.company?.zipcode
                            ? userDetails?.company?.zipcode
                            : "--"}
                        </div>
                      </div>
                    </div>
                    <div className="fieldBox flexRow">
                      <div className="flexCol">
                        <label className="flexCol__label">State</label>
                        <div className="flexCol__text">
                          {userDetails?.company?.stateName
                            ? userDetails?.company?.stateName
                            : "--"}
                        </div>
                      </div>
                      <div className="flexCol">
                        <label className="flexCol__label">City</label>
                        <div className="flexCol__text">
                          {userDetails?.company?.city
                            ? userDetails?.company?.city
                            : "--"}
                        </div>
                      </div>
                    </div>
                    <div className="fieldBox">
                      <label className="flexCol__label">Address</label>
                      <div className="flexCol__text">
                        {userDetails?.company?.address
                          ? userDetails?.company?.address
                          : "--"}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProfileDeactivateModal
        openModal={openProfileDectivateModal}
        closeModal={() => setOpenProfileDectivateModal(false)}
        employeeDetails={userDetails}
        setRefreshDetails={setRefreshDetails}
        refreshDetails={refreshDetails}
      />
      {openActivationModel && (
        <ConfirmationModal
          closeModal={() => setOpenActivationModel(false)}
          onSubmit={CancelDeactivationRequest}
          heading={"Cancel Request!"}
          confirmationMessage={
            "Are you sure want to cancel deactivation request?"
          }
        />
      )}
      {cancelRequestResponse?.meta?.status === true && (
        <SuccessModal
          closeModal={closeSuccessModal}
          sucessfullMsg={cancelRequestResponse?.meta?.msg}
          Heading={"Request Cancelled!"}
        />
      )}
      {cancelRequestResponse?.meta?.status === false && (
        <ErrorModal
          errorMessage={cancelRequestResponse?.meta?.msg}
          closeModal={closeSuccessModal}
          footerVisible={true}
        />
      )}
    </>
  );
};
