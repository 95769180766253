import React from "react";
import BackIcon from "@/assets/svgs/BackIcon";
import EditIcon from "@/assets/svgs/EditIcon";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowDown from "@/assets/svgs/ArrowDown";
import TickIcon from "@/assets/svgs/TickIcon";
import DotGreenIcon from "@/assets/svgs/DotGreenIcon";
import DotOrangeIcon from "@/assets/svgs/DotOrangeIcon";
import DotRedIcon from "@/assets/svgs/DotRedIcon";
import PlusIcon from "@/assets/svgs/PlusIcon";
import CloseIconGray from "@/assets/svgs/CloseIconGray";
import { MenuProps } from "@/utils/constants";
import {
  checkForAdminAndHiringLead,
  firstLetterCapital,
  GetUserDetails,
  getMyInterviewButtonAccess,
  titleCase,
  checkFor_CompanyAdmin_JobCreater_HiringLead,
} from "@/utils/helper";

function MyJobsHeader({
  handleEditJobPost,
  selectedJobData,
  setRejectedCandidatesTabShow,
  RejectedCandidatesTabShow,
  Tabvalue,
  setInterviewType,
  setGetShortlistScreen,
  getShortlistScreen,
  joblistlength,
  OpenRecruiterDetailPage,
  interviewType,
  setAddResponsiveness,
  handleOnPauseJobClick,
  jobStatus,
  openAddTagJobModal,
  OpenRemoveTagConfirmationModal,
  jobDetails,
}) {
  const userDetails = GetUserDetails();
  const role = userDetails?.customer?.role;
  let statusList = ["reopen", "close", "pause"];
  return (
    <>
      <div className="page__head">
        <h2 className="page__head--title gap16">
          <button
            className="btn btn__transparent btn__back border_0 p_0"
            type="button"
            onClick={() => setAddResponsiveness(false)}
          >
            <BackIcon />
          </button>
          {joblistlength > 0 ? firstLetterCapital(selectedJobData?.title) : ""}
          <div className="addTagButtons">
            {checkFor_CompanyAdmin_JobCreater_HiringLead(
              selectedJobData,
              role
            ) &&
            joblistlength > 0 &&
            jobDetails?.data?.tag?.length < 2 ? (
              <button
                className="btn btn__white w_fitContent"
                onClick={() => openAddTagJobModal(jobDetails?.data)}
              >
                <PlusIcon /> Add Tag
              </button>
            ) : (
              ""
            )}
            {joblistlength > 0
              ? jobDetails?.data?.tag?.length > 0 &&
                jobDetails?.data?.tag.map((item, index) => (
                  <span
                    key={index}
                    className="addTagBtn"
                    style={{ background: item?.colorCode }}
                  >
                    {titleCase(item?.tagName)}
                    {checkFor_CompanyAdmin_JobCreater_HiringLead(
                      selectedJobData,
                      role
                    ) && (
                      <button
                        class="closebtn"
                        onClick={() => OpenRemoveTagConfirmationModal(item)}
                      >
                        <CloseIconGray />
                      </button>
                    )}
                  </span>
                ))
              : ""}
          </div>
        </h2>
        {(checkForAdminAndHiringLead(selectedJobData, role) ||
          role === "Job Creator") &&
          joblistlength > 0 &&
          !OpenRecruiterDetailPage &&
          Tabvalue === "1" && (
            <div className="page__head--actions">
              {(selectedJobData?.status === "active" ||
                selectedJobData?.status === "reopen") && (
                <Tooltip title="Edit" placement="bottom" arrow>
                  <button
                    className="btn btn__white btn__edit btn--sm"
                    type="button"
                    onClick={() => handleEditJobPost()}
                  >
                    <EditIcon />
                  </button>
                </Tooltip>
              )}
              <div className="formField">
                <FormControl
                  sx={{ width: "100%" }}
                  size="small"
                  className="formField__select formField__select--myjobs"
                >
                  <Select
                    id="multiple-checkbox"
                    value={jobStatus ? jobStatus : ""}
                    onChange={(e) => handleOnPauseJobClick(e.target.value)}
                    name="jobStatus"
                    renderValue={(selected) => {
                      if (!selected) {
                        return <span style={{ opacity: "0.8" }}>Select</span>;
                      }
                      return (
                        <>
                          <span className="jobsDotIcons">
                            {selected === "pause" ? (
                              <DotOrangeIcon />
                            ) : selected === "reopen" ? (
                              <DotGreenIcon />
                            ) : (
                              <DotRedIcon />
                            )}
                          </span>
                          {selected === "pause"
                            ? "Paused"
                            : selected === "reopen"
                            ? "Open"
                            : "Closed"}
                        </>
                      );
                    }}
                    MenuProps={{
                      ...MenuProps,
                      PaperProps: {
                        ...MenuProps.PaperProps,
                        id: "MyJobMuiPopover-root",
                      },
                    }}
                    displayEmpty
                    IconComponent={() => <ArrowDown />}
                    classes={{ root: "multiSelect" }}
                  >
                    {statusList?.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        className={`dropdownItem ${
                          name === "pause" ? "pausedItem" : ""
                        }`}
                      >
                        {jobStatus?.indexOf(name) > -1 && (
                          <span className="tick">
                            <TickIcon />
                          </span>
                        )}
                        <span className="jobsDotIcons">
                          {name === "pause" ? (
                            <DotOrangeIcon />
                          ) : name === "reopen" ? (
                            <DotGreenIcon />
                          ) : (
                            <DotRedIcon />
                          )}
                        </span>
                        <ListItemText
                          primary={
                            name === "pause"
                              ? "Paused"
                              : name === "reopen"
                              ? "Open"
                              : "Closed"
                          }
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
        {Tabvalue == "5" && (
          <div className="page__head--actions">
            <button
              className="btn btn__primary"
              type="button"
              onClick={() => {
                setRejectedCandidatesTabShow(!RejectedCandidatesTabShow);
                setGetShortlistScreen(!getShortlistScreen);
              }}
            >
              {RejectedCandidatesTabShow
                ? "Approved Candidates"
                : " Rejected Candidates"}
            </button>
          </div>
        )}
        {Tabvalue == "3" &&
          getMyInterviewButtonAccess(selectedJobData) &&
          !interviewType && (
            <div className="page__head--actions">
              <button
                type="button"
                className="btn btn__primary"
                onClick={() => setInterviewType()}
              >
                My Interviews
              </button>
            </div>
          )}
        {Tabvalue == "3" &&
          getMyInterviewButtonAccess(selectedJobData) &&
          interviewType && (
            <div className="page__head--actions">
              <button
                type="button"
                className="btn btn__primary"
                onClick={() => setInterviewType()}
              >
                All Interviews
              </button>
            </div>
          )}
      </div>
    </>
  );
}

export default MyJobsHeader;
