import React, { useState } from "react";
import CloseIconGray from "@/assets/svgs/CloseIconGray";
import ArrowDownTag from "@/assets/svgs/ArrowDownTag";
import { Formik, Form, Field } from "formik";
import ColorPickersDropdown from "@/components/colorPicker/ColorPickersDropdown";
import { addTagSchema } from "../schema/customer-schema";
import { isButtonDisabled, GetUserDetails } from "../../utils/helper";
import addTagToCustomer from "@/rtk-apis/customer/settings/customerTags/addTagsToCustomer";
import { useSelector, useDispatch } from "react-redux";
import ErrorModal from "@/components/modals/error-modal";
import Loader from "@/elements/Loader";
import { SuccessModal } from "@/components/modals/SuccessModal";
import { closeUpdateTagsModal } from "@/store/slices/customer/settings/customerTags/updateCustomerTagsSlice";
import { closeAddCustomerTagsModal } from "../../store/slices/customer/settings/customerTags/addTagsToCustomerSlice";
import updateCustomerTags from "../../rtk-apis/customer/settings/customerTags/updateCustomerTags";

const AddEditCustomerTagsModal = ({
  openModal,
  closeModal,
  refreshPage,
  tagData,
}) => {
  const dispatch = useDispatch();
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const user = GetUserDetails();

  const initialValues = {
    tagName: tagData?.tagName ? tagData?.tagName : "",
    colorCode: tagData?.colorCode ? tagData?.colorCode : "",
  };

  const addTagsRes = useSelector((globalState) => {
    return globalState.addTagsToCustomerRes;
  });
  const updateTagsRes = useSelector((globalState) => {
    return globalState.updateCustomerTagsRes;
  });

  const clickToOpenColorPicker = () => {
    setOpenColorPicker(!openColorPicker);
  };

  const handleFormSubmit = async (values, resetForm) => {
    if (tagData?.id) {
      let body = {
        id: tagData?.id,
        tagName: values?.tagName,
        colorCode: values?.colorCode,
        role: "Customer",
        employeeId: user?.customer?.employeeId,
        companyId: user?.customer?.company?.id,
      };
      dispatch(updateCustomerTags(body));
    } else {
      let body = {
        tagName: values?.tagName,
        colorCode: values?.colorCode,
        role: "Customer",
        employeeId: user?.customer?.employeeId,
        companyId: user?.customer?.company?.id,
      };
      dispatch(addTagToCustomer(body));
    }
    resetForm();
  };

  const closeSuccessModal = () => {
    if (tagData?.id) {
      dispatch(closeUpdateTagsModal());
    } else {
      dispatch(closeAddCustomerTagsModal());
    }
    closeModal();
    refreshPage();
  };

  return (
    <>
      <div
        className={`modal modalAddNewTags ${openModal ? "modal--open" : ""}`}
      >
        <div className="modal__dialog">
          <div className="modal__content modal__content--xxxl">
            <div className="modal__header">
              <h2 className="modal__header--title">
                {tagData?.id ? "Update" : "Add"} Tag
              </h2>
              <button
                onClick={closeModal}
                className="btn btn__transparent btnClose"
              >
                <CloseIconGray />
              </button>
            </div>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={addTagSchema}
              onSubmit={(values, { resetForm }) =>
                handleFormSubmit(values, resetForm)
              }
            >
              {({ errors, values, handleChange, setFieldValue, resetForm }) => (
                <Form className="modal__body modal-body-left">
                  <div className="formRow formRow__2 w_100">
                    <div className="formCol">
                      <div className="formField">
                        <label className="formField__label">Tag Name</label>
                        <div className="formField__group sm">
                          <Field
                            type="text"
                            className="formField__input sm"
                            placeholder="Tag Name"
                            name="tagName"
                            onChange={(e) => {
                              const tagValue = e?.target?.value?.replace(
                                /^\s+/g,
                                ""
                              );
                              if (tagValue?.length <= 30) {
                                setFieldValue("tagName", tagValue);
                              }
                            }}
                          />
                        </div>
                        {/* <span className="formField__error">
                      Customer Support name is required.
                    </span> */}
                      </div>
                    </div>
                    <div className="formCol">
                      <div className="formField">
                        <label className="formField__label">Tag Color</label>
                        <div className="formField__formFieldTags">
                          <div className="formField__group sm">
                            <span
                              className="colorFill"
                              style={{
                                backgroundColor: values.colorCode
                                  ? values.colorCode
                                  : "#28a0e8",
                              }}
                            ></span>
                            <div className="colorCodeValueRow">
                              {/* <span className="colorCodeValue"># 28A0E8</span> */}
                              <Field
                                type="text"
                                className="formField__input sm colorCodeValue"
                                placeholder="Color Code"
                                name="colorCode"
                              />
                              <div onClick={() => clickToOpenColorPicker()}>
                                <ArrowDownTag />
                              </div>
                            </div>
                          </div>
                          <ColorPickersDropdown
                            open={openColorPicker}
                            handleColorChange={(color) =>
                              setFieldValue("colorCode", color)
                            }
                            close={() => setOpenColorPicker(false)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn__group">
                    <button
                      className="btn btn__white"
                      onClick={() => closeModal()}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn__primary"
                      disabled={isButtonDisabled(errors, values, initialValues)}
                    >
                      {tagData?.id ? "Update" : "Add"} Tag
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
      {(addTagsRes?.meta?.status || updateTagsRes?.meta?.status) && (
        <SuccessModal
          closeModal={closeSuccessModal}
          sucessfullMsg={
            tagData?.id ? updateTagsRes?.meta?.msg : addTagsRes?.meta?.msg
          }
          Heading={tagData?.id ? "Tag Updated!" : "Tag Added!"}
        />
      )}
      {(addTagsRes?.meta?.status === false ||
        updateTagsRes?.meta?.status === false) && (
        <ErrorModal
          errorMessage={
            tagData?.id ? updateTagsRes?.meta?.msg : addTagsRes?.meta?.msg
          }
          closeModal={closeSuccessModal}
          footerVisible={true}
        />
      )}
      {(addTagsRes?.loading || updateTagsRes?.loading) && <Loader />}
    </>
  );
};

export default AddEditCustomerTagsModal;
