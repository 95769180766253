import { createSlice } from "@reduxjs/toolkit";
import getJobData from "../../../../rtk-apis/customer/myJobs/getJobData";

const initialState = {
  message: "",
  data: {},
  meta: {},
  token: "",
  loading: false,
  error: "",
};

const getJobDataSlice = createSlice({
  name: "getJobDataSlice",
  initialState,
  reducers: {
    closeRtkModal(state, action) {
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getJobData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getJobData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.meta = payload?.meta;
      })
      .addCase(getJobData.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default getJobDataSlice.reducer;

export const { closeRtkModal } = getJobDataSlice.actions;
