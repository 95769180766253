import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { loginSchema } from "@/components/schema/schema";
import AuthImage from "@/assets/images/auth-img.png";
import { Link } from "react-router-dom";
import CloseEye from "@/assets/svgs/CloseEye";
import OpenEye from "@/assets/svgs/OpenEye";
import KeyLockSvg from "@/assets/svgs/KeyLockSvg";
import LockSvg from "@/assets/svgs/LockSvg";
import ErrorModal from "@/components/modals/error-modal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Loader from "@/elements/Loader";
import { closeRtkModal } from "@/store/slices/SignInSlice";
import signInRecruiter from "@/rtk-apis/signInRecruiter";
import { GetUserDetails } from "@/utils/helper";

const SignIn = () => {
  const dispatch = useDispatch();
  const user = GetUserDetails();

  let initialValues = {
    email: "",
    password: "",
  };

  const navigate = useNavigate();

  const [eyeForPassword, seteyeForPassword] = useState(false);

  const closeModal = () => {
    dispatch(closeRtkModal());
  };

  const { loading, meta } = useSelector((globalState) => {
    return globalState.signIn;
  });

  const handleSubmit = (values) => {
    dispatch(
      signInRecruiter({ email: values?.email, password: values?.password })
    );
  };

  useEffect(() => {
    if (meta?.status) {
      if (user?.usertype === "customer") {
        setUserInChatwoot();
        navigate("/customer-dashboard");
      } else {
        setUserInChatwoot();
        navigate("/dashboard");
      }
    }
  }, [meta]);
  const setUserInChatwoot = () => {
    if (window.$chatwoot && typeof window.$chatwoot.reset === "function") {
      let obj = {
        id:
          user?.usertype === "recruiter"
            ? user?.recruiter?.recruiterId
            : user?.customer?.employeeId,
        name:
          user?.usertype === "recruiter"
            ? `${user?.recruiter?.firstName} ${user?.recruiter?.lastName}`
            : `${user?.customer?.firstName} ${user?.customer?.lastName}`,
        email:
          user?.usertype === "recruiter"
            ? `${user?.recruiter?.email}`
            : user?.customer?.email,
        phone_number:
          user?.usertype === "recruiter"
            ? `${user?.recruiter?.countryCode}${user?.recruiter?.phoneNumber}`
            : `${user?.customer?.countryCode}${user?.customer?.phoneNumber}`,
        company_name:
          user?.usertype === "recruiter"
            ? null
            : user?.customer?.company?.companyName,
        account_role: user?.usertype,
      };
      window.$chatwoot.setUser(obj.id, {
        name: obj.name,
        email: obj.email,
        phone_number: obj.phone_number,
        company_name: obj.company_name,
      });
      window.$chatwoot.setCustomAttributes({
        account_role: obj.account_role,
      });
    }
  };
  return (
    <div className="container__fullScreen bg--light">
      <div className="auth__container">
        <Formik
          initialValues={initialValues}
          validationSchema={loginSchema}
          onSubmit={(e, values) => handleSubmit(e, values)}
        >
          {({ errors, touched }) => (
            <Form className="auth__form">
              <div className="auth__form--logo">
                <KeyLockSvg />
              </div>
              <img
                src={AuthImage}
                className="auth__form--img"
                alt="Authentication"
              />
              <h4 className="auth__form--heading">Sign In</h4>
              <div
                className={`formField formField__mb5 ${
                  errors?.email && touched?.email ? "fieldError" : ""
                }`}
              >
                <label className="formField__label">Email address</label>
                <div className="formField__group">
                  <span className="formField__icon">
                    <LockSvg />
                  </span>
                  <Field
                    type="text"
                    className="formField__input"
                    placeholder="Enter email address"
                    name="email"
                    autoComplete="off"
                  />
                </div>
                <span className="formField__error">
                  {errors?.email && touched?.email && errors?.email}
                </span>
              </div>

              <div
                className={`formField formField__mb5 ${
                  errors?.password && touched?.password ? "fieldError" : ""
                }`}
              >
                <label className="formField__label">Password</label>
                <div className="formField__group">
                  <span className="formField__icon">
                    <LockSvg />
                  </span>
                  <Field
                    type={eyeForPassword ? "text" : "password"}
                    className="formField__input"
                    placeholder="Enter password"
                    name="password"
                    autoComplete="off"
                  />
                  <div
                    onClick={() => seteyeForPassword(!eyeForPassword)}
                    className="formField__icon"
                  >
                    <span>{eyeForPassword ? <OpenEye /> : <CloseEye />}</span>
                  </div>
                </div>
                <span className="formField__error">
                  {errors?.password && touched?.password && errors?.password}
                </span>
              </div>

              <button
                type="submit"
                className="btn btn__primary auth__form--submit"
              >
                Sign In
              </button>

              <Link to="/forgot-password" className="link link__primary">
                Forgot password?
              </Link>
              <div className="auth__form--divider">
                <span>Or</span>
              </div>
              <div className="auth__form--footer">
                Don’t have an account?
                <Link
                  to="/signup"
                  state={{ signUpAs: "recruiter" }}
                  className="link link__primary"
                >
                  Sign up as a recruiter
                </Link>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {meta?.status === false && meta?.msg && (
        <ErrorModal
          errorMessage={meta?.msg}
          closeModal={closeModal}
          footerVisible={true}
        />
      )}
      {loading && <Loader />}
    </div>
  );
};

export default SignIn;
