import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SideBar from "@/components/layout/SideBar";
import JobsIcon from "@/assets/svgs/DashboardIcons/JobsIcon";
import AssignedIcon from "@/assets/svgs/DashboardIcons/AssignedIcon";
import UnAssignedIcon from "@/assets/svgs/DashboardIcons/UnAssignedIcon";
import CandidatesRecruitedIcon from "@/assets/svgs/DashboardIcons/CandidatesRecruitedIcon";
import UpcomingInterviewsIcon from "@/assets/svgs/DashboardIcons/UpcomingInterviewsIcon";
import BilledAmountIcon from "@/assets/svgs/DashboardIcons/BilledAmountIcon";
import PaidAmountIcon from "@/assets/svgs/DashboardIcons/PaidAmountIcon";
import dashboardData from "@/rtk-apis/customer/dashboard/getDashboardData";
import Loader from "@/elements/Loader";
import { useNavigate } from "react-router-dom";
import { Currency, GetUserDetails } from "@/utils/helper";

function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const companyId = GetUserDetails()?.customer?.companyId;
  const employeeId = GetUserDetails()?.customer?.employeeId;
  const { data, loading } = useSelector((globalState) => {
    return globalState.getDashboardData;
  });

  useEffect(() => {
    let obj = { companyId, employeeId };
    dispatch(dashboardData(obj));
  }, []);

  const getIcons = (iconName) => {
    if (iconName === "JobsIcon") {
      return <JobsIcon />;
    } else if (iconName === "AssignedIcon") {
      return <AssignedIcon />;
    } else if (iconName === "UnAssignedIcon") {
      return <UnAssignedIcon />;
    } else if (iconName === "CandidatesRecruitedIcon") {
      return <CandidatesRecruitedIcon />;
    } else if (iconName === "UpcomingInterviewsIcon") {
      return <UpcomingInterviewsIcon />;
    } else if (iconName === "PaidAmountIcon") {
      return <PaidAmountIcon />;
    } else if (iconName === "BilledAmountIcon") {
      return <BilledAmountIcon />;
    }
  };

  const getCount = (tabCount, name) => {
    let count = tabCount;
    if (name === "PaidAmountIcon" || name === "BilledAmountIcon") {
      return Currency(count);
    } else {
      return count;
    }
  };

  return (
    <div className="layoutContainer">
      <SideBar />
      <div className="layoutGrid layoutGrid--full">
        <div className="layoutGrid__col">
          <div className="page page__full">
            <div className="page__head">
              <h2 className="listCol__head--title">Home</h2>
            </div>
            <div className="page__body bg--white">
              <div className="cardContainer">
                <ul className="cardList">
                  {data?.length &&
                    data?.map((item, index) => {
                      return (
                        <li
                        key={item?.name}
                          className="cardList__item"
                          onClick={() => {
                            navigate(`/${item?.link}`, {
                              state: { activeTab: item?.activeTab },
                            });
                          }}
                        >
                          <div className="card--icon">
                            {getIcons(item?.icon)}
                          </div>
                          <div className="card--title">{item?.name}</div>
                          <div className="card--subtitle">
                            {getCount(item?.count, item?.icon)}
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
}

export default Dashboard;
