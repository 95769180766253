export const STATIC_DATA = "/laila/admin/staticdata";
export const SIGN_UP = "/laila/recruiterpanel/signup";
export const SIGN_IN = "/laila/recruiterpanel/login";
export const FORGOT_PASSWORD = "/laila/recruiterpanel/forgot-password";
export const RESET_PASSWORD = "/laila/recruiterpanel/reset-password";
export const JOB_REQUEST_LIST = "/laila/recruiterpanel/jobrequestlist";
export const JOB_ACCEPT_DECLINE = "/laila/recruiterpanel/jobaction";
export const CANDIDATE_LIST = "/laila/recruitercandidate/list";
export const ADD_CANDIDATE = "/laila/recruitercandidate/add";
export const DELETE_CANDIDATE = "/laila/recruitercandidate/delete";
export const UPDATE_CANDIDATE = "/laila/recruitercandidate/update";
export const UPDATE_RECRUITER = "/laila/recruiterpanel/editrecruiterprofile";
export const CHANGE_PASSWORD = "/laila/recruiterpanel/change-password";
export const MY_JOBS = "/laila/recruiterjob/list";
export const DOWNLOAD_JD = "/laila/recruiterjob/downsendjd";
export const SCHEDULED_INTERVIEWS = "/laila/recruiterjob/scheduleinterview";
export const APPROVED_CANDIDATES_LIST =
  "/laila/recruiterjob/approvedcandidatelist";
export const CANDIDATE_APPROVE_REJECT = "/laila/recruiterjob/statuschange";
export const FEEDBACK_QUESTIONS =
  "/laila/recruiterjob/feedbackquesiton/candidate";
export const INTERVIEW_CREATE_MEETING = "/laila/interview/createmeeting";
export const INTERVIEW_LIST = "/laila/recruitercandidate/interviewlist";
export const RECRUITER_DASHBOARD = "/laila/dashboard/recruiter/";
export const CANDIDATE_INTERVIEWS =
  "/laila/recruitercandidate/candidatelistforinterview";
export const CANDIDATE_RESUME_UPLOAD = "/laila/common/uploadresume";
export const RECRUITER_DETAILS_ON_SIGNUP =
  "/laila/recruiterpanel/recruiterdetail";
export const RECRUITER_DETAILS = "/laila/recruiterpanel/detail";
export const FETCH_RECORDING =
  "/laila/recruiterjob/interviewdetailfetchrecording";
export const FETCH_TAX_INFORMATION = "/laila/tax/information/";
export const UPADATE_TAX_RESIDENCE_INFORMATION = "/laila/tax/taxresidence";
export const UPADATE_TAX_IDENTIFICATION_INFORMATION =
  "/laila/tax/taxpayeridentification";
export const RECRUITER_DEACTIVATE_ACCOUNT =
  "/laila/recruiterpanel/deactivateaccount";
export const RECRUITER_CANCEL_DEACTIVATE_ACCOUNT =
  "/laila/recruiterpanel/canceldeactivaterequest/";
export const BILLING_RECRUITER_JOBLIST = "/laila/billing/recruiterjoblist";
export const BILLING_RECRUITER_CANDIDATE_LIST =
  "/laila/billing/candidaterecruiterlist";
export const FETCH_PAYMENT_INFORMATION =
  "/laila/recruiterpayment/fetchpaymentdetail/";
export const ADD_BANK_INFORMATION = "/laila/recruiterpayment/addachdetail";
export const UPDATE_BANK_INFORMATION =
  "/laila/recruiterpayment/updateachdetail";
export const DELETE_BANK_INFORMATION =
  "/laila/recruiterpayment/removewithdrawalmethod";
export const ADD_WITHDRAWL_DETAILS = "/laila/recruiterpayment/withdrawalmethod";
export const ADD_PAYPAL_DETAILS = "/laila/recruiterpayment/addpaypalmethod";

/** ******************* Customer end points start ***************************/
export const SIGN_UP_CUSTOMER = "/laila/recruiterpanel/signup";
export const MY_JOBS_LIST_CUSTOMER = "/laila/customerjob/list";
export const GET_CUSTOMERS_EMPLOYEE_LIST = "/laila/employee/list";
export const ADD_JOB_POST = "/laila/customerjob/addjob";
export const CUSTOMER_CANDIDATE_LIST = "/laila/customercandidate/list";
export const GET_CANDIDATE_DETAIL = "/laila/customercandidate/detail/";
export const CANDIDATE_RECORDING_LIST = "/laila/screening/getrecordinglist";
export const CUSTOMER_INTERVIEW_LIST = "/laila/customerinterview/list";
export const CUSTOMER_SHORTLISTED_CANDIDATE_LIST =
  "/laila/customerinterview/shortlistedcandidate";
export const APPROVE_REJECT_INTERVIEW_CANDIDATE =
  "/laila/customerinterview/approvedRejectCandidate";
export const CUSTOMER_APPROVED_CANDIDATE_LISTING =
  "/laila/customerinterview/shortlistedcandidate";
export const CUSTOMER_FEEDBACK_QUESTIONS =
  "/laila/recruiterjob/feedbackquesiton/candidate";
export const GET_CUSTOMER_COMPANY_PROFILE = "/laila/customercompany/detail/";
export const GET_STATE_CITY_DATA = "/laila/admin/getstatecity";
export const GET_CITY_DATA = "/laila/admin/getstatecity";
export const CUSTOMER_LOGO_UPLOAD = "/laila/admin/upload";
export const UPDATE_COMPANY_PROFILE = "/laila/customercompany/update";
export const CUSTOMER_INTERVIEW_CANDIDATE_LIST =
  "/laila/customerinterview/candidateinterviewlist";
export const CUSTOMER_INTERVIEW_CANDIDATE_DATA =
  "/laila/customerinterview/candidateinterviewlistdata";
export const CUSTOMER_DASHBOARD_DATA = "/laila/dashboard/customer/";
export const CUSTOMER_UPDATE_MY_PROFILE =
  "/laila/customercompany/updatemployeedetail";
export const CUSTOMER_EMPLOYEE_DETAIL = "/laila/customercompany/employee/";
export const CHECK_SCHEDUAL_PAGE =
  "/laila/customerinterview/checkschedulingpage";
export const INTERVIEW_CHECK_MEETING = "/laila/interview/checkmeeting";
export const INTERVIEW_JOIN_MEETING = "/laila/interview/checkparticipant";
export const CUSTOMER_BILLING_JOB_LISTING =
  "/laila/customerjob/joblistaccordingtobilling";
export const CUSTOMER_BILLING_CANDIDATE_LISTING =
  "/laila/customerjob/candidatelistforbilling";
export const CUSTOMER_BILLING_PAYMENT_SUCCESS =
  "/laila/billing/sendpaymentlink";
export const CUSTOMER_BILLING_CONFIRM_PAYMENT =
  "/laila/billing/confirmpayment/";
export const CUSTOMER_BILLING_CANCEL_PAYMENT = "/laila/billing/cancelpayment/";
export const CUSTOMER_GET_SEARCH_FIELD = "/laila/customerjob/fielddata";
export const MYJOB_INTERVIEW_DETAILPAGE =
  "/laila/customerinterview/interviewlistandfetchrecording";
export const CUSTOMER_EMPLOYEE_LIST = "/laila/customercompany/employee/list";
export const CUSTOMER_EMPLOYEE_JOBS = "/laila/customercompany/employee/job";
export const CUSTOMER_DEACTIVATE_ACCOUNT =
  "/laila/customercompany/deactivateaccount";
export const CANCEL_CUSTOMER_DEACTIVATE_ACCOUNT =
  "/laila/customercompany/canceldeactivaterequest/";
export const PROFILE_DEACTIVATE = "/laila/customercompany/deactivateaccount";
export const ADD_QUALIFICATION = "/laila/recruiterpanel/addqualification";
export const PAUSE_A_JOB = "/laila/customerjob/pausedjob";
export const REOPEN_A_JOB = "/laila/customerjob/reopenjob";
export const DELETE_JOB_POST = "/laila/customerjob/closedjob";
export const CUSTOMER_ADD_EMPLOYEE = "/laila/employee/add";
export const CUSTOMER_EDIT_EMPLOYEE = "/laila/employee/edit";
export const CUSTOMER_DEACTIVATE_EMPLOYEE = "/laila/employee/delete";
export const CUSTOMER_ACTIVATE_EMPLOYEE = "/laila/employee/activate";
export const ADD_ACH_DEBIT_DETAILS = "/laila/customerpayment/adddetail";
export const EDIT_ACH_DEBIT_DETAILS = "/laila/customerpayment/update";
export const REMOVE_ACH_DEBIT_DETAILS = "/laila/customerpayment/remove";
export const FETCH_DEBIT_PAYMENT_METHODS = "/laila/customerpayment/fetch/";
export const SET_PRIMARY_DEBIT_METHOD = "/laila/customerpayment/setdefault";
export const ADD_REVIEW_RATINGS_FOR_RECRUITER_BY_CUSTOMER = "/laila/rating/add";
export const UPDATE_REVIEW_RATINGS_FOR_RECRUITER_BY_CUSTOMER =
  "/laila/rating/update";
export const GET_RECRUITER_DETAILS = "/laila/customerjob/recruiterdetails/";
export const ADD_NPS_RATING = "/laila/rating/addnpsrating";
export const ADD_CREDIT_CARD_DETAILS = "/laila/customerpayment/addcard";
export const VERIFY_ACH_PAYMENT_METHOD = "/laila/customerpayment/verifymethod";
export const GET_CARD_TYPE = "/laila/customerpayment/validcard";
export const REMOVE_TAG_DETAILS = "/laila/tag/removetagfromcandidate";
export const FETCH_TAG_DETAILS = "/laila/tag/list";
export const ADD_TAG_TO_CANDIDATE = "/laila/tag/addtagtocandidate";
export const EDIT_JOB_NOTES = "/laila/job/editnotes";
export const DELETE_JOB_NOTES = "/laila/job/deletenotes";
export const ADD_JOB_NOTES = "/laila/job/addnotes";
export const GET_JOB_NOTES = "/laila/job/notes/";
export const ADD_JOB_TAGS = "/laila/tag/addtagtojob";
export const DELETE_JOB_TAGS = "/laila/tag/removetagtojob";
export const GET_JOB_DETAILS = "/laila/job/detail/";

export const GET_COMPANY_TAGS = "/laila/tag/customertag";
export const ADD_TAGS_TO_COMPANY = "/laila/tag/add";
export const UPDATE_COMPANY_TAGS = "/laila/tag/update";
export const ACTIVATE_DEACTIVATE_CUSTOMER_TAGS = "/laila/tag/changestatus";

/** ******************* Customer end points end ***************************/
