import React, { useState, useEffect } from "react";
import MyJobsListing from "@/customer-pages/MyJobs/MyJobsListing";
import SideBar from "@/components/layout/SideBar";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import JobDetails from "@/customer-pages/MyJobs/JobDetails/JobDetails";
import Candidates from "@/customer-pages/MyJobs/Candidates/Candidates";
import Interviews from "@/customer-pages/MyJobs/Interviews/Interviews";
import InterviewsDetails from "@/customer-pages/MyJobs/Interviews/InterviewsDetails";
import InterviewsFeedback from "@/customer-pages/MyJobs/Interviews/InterviewsFeedback";
import ShortlistedCandidates from "@/customer-pages/MyJobs/ShortlistedCandidates/ShortlistedCandidates";
import ApprovedCandidates from "@/customer-pages/MyJobs/ApprovedCandidates/ApprovedCandidates";
import JobPost from "@/customer-pages/MyJobs/PostJob/PostJob";
import MyJobsHeader from "@/customer-pages/MyJobs/RightPanalHeader/MyJobsHeader";
import CandidateDetailHeader from "@/customer-pages/MyJobs/RightPanalHeader/CandidateDetailHeader";
import InterviewDetailHeader from "@/customer-pages/MyJobs/RightPanalHeader/InterviewDetailHeader";
import CandidatesDetails from "@/customer-pages/MyJobs/Candidates/CandidatesDetails";
import CandidatesRecordings from "@/customer-pages/MyJobs/Candidates/CandidatesRecordings";
import AssignedRecruiterDetails from "@/customer-pages/MyJobs/JobDetails/AssignedRecruiterDetails";
import {
  GetUserDetails,
  checkForAdminAndHiringLead,
  checkForCompanyAdmin_HiringLead_JTM_JobCreater,
  checkForMyJobCandidate,
  checkForMyJobInterview,
  checkForApprovedTab,
} from "@/utils/helper";
import CandidatesFeedback from "@/customer-pages/MyJobs/Candidates/CandidatesFeedback";
import PauseStopReopenJobModal from "@/components/modals/PauseStopReopenJobModal";
import AddNotesModal from "@/components/modals/AddNotesModal";
import AddTagsModal from "@/components/modals/AddTagsModal";
import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import ErrorModal from "@/components/modals/error-modal";
import { SuccessModal } from "@/components/modals/SuccessModal";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import removeJobTags from "@/rtk-apis/customer/tags/jobTags/removeJobTags";
import { closeRemoveTagsModal } from "../../store/slices/customer/tags/jobTagsSlice/removeJobTagsSlice";
import getJobData from "../../rtk-apis/customer/myJobs/getJobData";

function CustomerMyJobs() {
  const userDetails = GetUserDetails();
  const role = userDetails?.customer?.role;
  const [value, setValue] = useState("1");
  const [selectedJobId, setSelectedJobId] = useState(0);
  const [selectedJob, setSelectedJob] = useState({});
  const [OpenAddPostJobScreen, setOpenAddPostJobScreen] = useState(false);
  const [EditJobPostMode, setEditJobPostMode] = useState(false);
  const [RefreshJobList, setRefreshJobList] = useState(false);
  const [OpenRecruiterDetailPage, setOpenRecruiterDetailPage] = useState(false);
  const [candidateViewType, setCandidateViewType] = useState("list");
  const [interviewViewType, setInterviewViewType] = useState("list");
  const [selectedCandidateData, setSelectedCandidateData] = useState({});
  const [RecruiterDetailPageCheck, setRecruiterDetailPageCheck] = useState({});
  const [RejectedCandidatesTabShow, setRejectedCandidatesTabShow] = useState(
    false
  );
  const [myInterview, setMyInterview] = useState(false);
  const [getShortlistScreen, setGetShortlistScreen] = useState(false);
  const [addResponsiveness, setAddResponsiveness] = useState(false);
  const [OpenActionOnJobModal, setOpenActionOnJobModal] = useState(false);
  const [resetJobModal, setResetJobModal] = useState(false);
  const [jobStatus, setJobStatus] = useState("");
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [selectedInterviewData, setSelectedInterviewData] = useState({});

  const handleTablistChange = (event, newValue) => {
    setCandidateViewType("list");
    setInterviewViewType("list");
    setValue(newValue);
  };
  useEffect(() => {
    if (selectedJob && selectedJob?.status === "reopen") {
      setJobStatus("reopen");
    } else if (selectedJob && selectedJob?.status === "Closed") {
      setJobStatus("close");
    } else if (selectedJob && selectedJob?.status === "active") {
      setJobStatus("reopen");
    } else if (selectedJob && selectedJob?.status === "paused") {
      setJobStatus("pause");
    }
  }, [selectedJob, resetJobModal]);

  const { data } = useSelector((globalState) => {
    return globalState.myJobsListCustomer;
  });

  const indexChanged = (index) => {
    setSelectedJob(data.rows[index]);
    setSelectedJobId(data?.rows[index]?.jobId);
    setValue("1");
    setOpenRecruiterDetailPage(false);
    setMyInterview(false);
    setNotesArray([]);
    setOpenAddPostJobScreen(false);
  };

  useEffect(() => {
    // selected job remains selected on any action
    if (data?.rows?.length > 0) {
      if (
        selectedJob?.jobId !== data?.rows[0]?.jobId &&
        Object.keys(selectedJob).length !== 0
      ) {
        const job = data?.rows?.filter(
          (job) => job?.jobId === selectedJob?.jobId
        );
        setSelectedJob(job[0]);
        setSelectedJobId(job[0]?.jobId);
      }
    }
  }, [RefreshJobList]);

  useEffect(() => {
    // on page reload
    if (data?.rows?.length && Object.keys(selectedJob).length === 0) {
      setSelectedJob(data?.rows[0]);
      setSelectedJobId(data?.rows[0]?.jobId);
    }
  }, [page, filtersApplied, search, RefreshJobList, data]);

  useEffect(() => {
    // on pagination change or other dependencies
    if (data?.rows?.length && Object.keys(selectedJob).length) {
      const isSelectedJobPresent = data?.rows?.some(
        (job) => job.jobId === selectedJob?.jobId
      );
      if (!isSelectedJobPresent) {
        setSelectedJob(data.rows[0]);
        setSelectedJobId(data?.rows[0]?.jobId);
      }
    }
  }, [page, filtersApplied, search, data]);

  useEffect(() => {
    const state = JSON.parse(localStorage.getItem("recruiterDetailPage"));
    setRecruiterDetailPageCheck(state);
    localStorage.removeItem("recruiterDetailPage");
  }, []);

  const handleEditJobPost = () => {
    setEditJobPostMode(true);
    setOpenAddPostJobScreen(true);
    setNotesArray(selectedJob?.notes);
  };

  const handleOnPauseJobClick = (status) => {
    setJobStatus(status);
    if (selectedJob?.status === "Closed" && status === "pause") {
      setOpenActionOnJobModal(false);
    } else {
      setOpenActionOnJobModal(true);
    }
  };

  const backToJobDetailpage = () => {
    setOpenAddPostJobScreen(false);
    setRefreshJobList(!RefreshJobList);
  };

  const changeCandidateViewType = (viewType, candidateData) => {
    setCandidateViewType(viewType);
    setSelectedCandidateData(candidateData);
  };

  const changeInterviewViewType = (viewType, interviewData) => {
    setInterviewViewType(viewType);
    setSelectedInterviewData(interviewData);
  };

  const refreshJobListing = () => {
    setRefreshJobList(!RefreshJobList);
  };

  // *********************** NOTES CODE ********************************************
  const dispatch = useDispatch();
  const [EditNotesMode, setEditNotesMode] = useState(false);
  const [OpenAddEditNotesModal, setOpenAddEditNotesModal] = useState(false);
  const [EditNoteId, setEditNoteId] = useState("");
  const [NotesTitle, setNotesTitle] = useState("");
  const [NotesText, setNotesText] = useState("");
  const [RefreshJobDetails, setRefreshJobDetails] = useState(false);
  const [ShareWith, setShareWith] = useState([]);
  const [SelectedShareWith, setSelectedShareWith] = useState([]);

  const jobDetails = useSelector((globalState) => {
    return globalState.getJobDataRes;
  });

  const [NotesArray, setNotesArray] = useState([]);

  useEffect(() => {
    if (EditJobPostMode) {
      setNotesArray(jobDetails?.data?.notes);
    } else {
      setNotesArray([]);
      setShareWith([]);
    }
  }, [EditJobPostMode]);

  useEffect(() => {
    getJobDetails(selectedJob?.jobId);
  }, [selectedJob, RefreshJobDetails]);

  const getJobDetails = (jobid) => {
    dispatch(getJobData(jobid));
  };

  const resetAddNoteModal = () => {
    setNotesTitle("");
    setNotesText("");
    setEditNotesMode(false);
  };

  const handleOpenAddEditNotesModal = () => {
    setOpenAddEditNotesModal(true);
  };

  const handleCloseModal = () => {
    setOpenAddEditNotesModal(false);
    resetAddNoteModal();
  };

  const handleOnAddEditNotes = () => {
    if (EditNotesMode) {
      setNotesArray((prevNotes) =>
        prevNotes.map((note) =>
          note.id === EditNoteId
            ? {
                ...note,
                title: NotesTitle,
                description: NotesText,
                createdTime: new Date().toISOString(),
                updatedById: userDetails?.customer?.employeeId,
                shareWith: SelectedShareWith,
              }
            : note
        )
      );
      setEditNotesMode(false);
      setEditNoteId(null);
      setOpenAddEditNotesModal(false);
      resetAddNoteModal();
    } else {
      const newNote = {
        id: uuidv4(),
        from: "Customer",
        createdTime: new Date().toISOString(),
        addedBy: userDetails?.customer?.employeeId,
        name: `${userDetails?.customer?.firstName} ${userDetails?.customer?.lastName}`,
        title: NotesTitle,
        description: NotesText,
        shareWith: SelectedShareWith,
      };
      setNotesArray((prevNotes) => [...prevNotes, newNote]);
      setOpenAddEditNotesModal(false);
      resetAddNoteModal();
    }
  };

  const handleDeleteNote = (DeleteNoteId) => {
    setNotesArray((prevNotes) =>
      prevNotes.filter((note) => note.id !== DeleteNoteId)
    );
  };

  const handleEditNotes = (note) => {
    setOpenAddEditNotesModal(true);
    setEditNotesMode(true);
    setNotesText(note?.description);
    setNotesTitle(note?.title);
    setEditNoteId(note.id);
  };

  // *************************** ADD TAGS CODE ************************************************
  const [OpenAddTagModal, setOpenAddTagModal] = useState(false);
  const [OpenRemoveTagConfModal, setOpenRemoveTagConfModal] = useState(false);
  const [fromJobSection, setFromJobSection] = useState(false);
  const [selectedTagDetails, setSelectedTagDetails] = useState({});

  const removeJobTagRes = useSelector((globalState) => {
    return globalState.removeJobTagsResponse;
  });

  useEffect(() => {
    setOpenRemoveTagConfModal(false);
  }, [removeJobTagRes?.meta?.status]);

  const openAddTagJobModal = (selectedJobDetails) => {
    setOpenAddTagModal(true);
    setFromJobSection(true);
    setSelectedTagDetails({
      jobId: selectedJobDetails?.jobId,
      tags: selectedJobDetails?.tag,
    });
  };

  const OpenRemoveTagConfirmationModal = (tagItem) => {
    setSelectedTagDetails({
      jobId: selectedJob?.jobId,
      tagId: tagItem?.id,
    });
    setOpenRemoveTagConfModal(true);
  };

  const removeJobTag = () => {
    dispatch(removeJobTags(selectedTagDetails));
  };

  const closeRemoveTagModal = () => {
    dispatch(closeRemoveTagsModal());
    setRefreshJobDetails(!RefreshJobDetails);
  };

  return (
    <>
      <div className="layoutContainer">
        <SideBar />

        <div className="layoutGrid">
          <MyJobsListing
            setOpenAddPostJobScreen={setOpenAddPostJobScreen}
            changeIndex={(i) => indexChanged(i)}
            EditJobPostMode={EditJobPostMode}
            RefreshJobList={RefreshJobList}
            setAddResponsiveness={setAddResponsiveness}
            setPage={setPage}
            page={page}
            setSearch={setSearch}
            search={search}
            setFiltersApplied={setFiltersApplied}
            filtersApplied={filtersApplied}
            setSelectedJobId={setSelectedJobId}
            selectedJobId={selectedJobId}
          />

          {OpenAddPostJobScreen ? (
            <JobPost
              setOpenAddPostJobScreen={setOpenAddPostJobScreen}
              EditJobPostMode={EditJobPostMode}
              selectedJobData={selectedJob}
              setEditJobPostMode={setEditJobPostMode}
              backToJobDetailpage={backToJobDetailpage}
              addResponsiveness={addResponsiveness}
              setAddResponsiveness={setAddResponsiveness}
              handleOpenAddEditNotesModal={handleOpenAddEditNotesModal}
              handleDeleteNote={handleDeleteNote}
              NotesArray={NotesArray}
              handleEditNotes={(note) => handleEditNotes(note)}
              setShareWith={setShareWith}
              ShareWith={ShareWith}
            />
          ) : (
            <div
              className={`layoutGrid__col layoutGrid--responsive  ${
                addResponsiveness ? "layoutGrid--show" : ""
              }`}
            >
              <div className="dataCol">
                <div className="page page__full">
                  {value == 2 && candidateViewType == "candidateDetail" ? (
                    <CandidateDetailHeader
                      candidateData={selectedCandidateData}
                      changeCandidateViewType={changeCandidateViewType}
                    />
                  ) : value == 3 &&
                    (interviewViewType == "interviewDetail" ||
                      interviewViewType == "interviewFeedback") ? (
                    <InterviewDetailHeader interviewType={interviewViewType} />
                  ) : value == 2 && candidateViewType == "candidateFeedBack" ? (
                    <InterviewDetailHeader interviewType={candidateViewType} />
                  ) : (
                    <MyJobsHeader
                      selectedJobData={selectedJob}
                      handleEditJobPost={handleEditJobPost}
                      RejectedCandidatesTabShow={RejectedCandidatesTabShow}
                      setRejectedCandidatesTabShow={
                        setRejectedCandidatesTabShow
                      }
                      Tabvalue={value}
                      setInterviewType={() => setMyInterview(!myInterview)}
                      setGetShortlistScreen={setGetShortlistScreen}
                      getShortlistScreen={getShortlistScreen}
                      joblistlength={data?.rows?.length}
                      OpenRecruiterDetailPage={OpenRecruiterDetailPage}
                      interviewType={myInterview}
                      setAddResponsiveness={setAddResponsiveness}
                      handleOnPauseJobClick={handleOnPauseJobClick}
                      jobStatus={jobStatus}
                      openAddTagJobModal={(data) => openAddTagJobModal(data)}
                      OpenRemoveTagConfirmationModal={
                        OpenRemoveTagConfirmationModal
                      }
                      jobDetails={jobDetails}
                    />
                  )}

                  <div className="page__body bg--white">
                    <Box className="tabContainer">
                      <TabContext value={value}>
                        <Box className="tabContainer__head tabContainer__head--fixed m_0">
                          <TabList
                            onChange={handleTablistChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                          >
                            {checkForCompanyAdmin_HiringLead_JTM_JobCreater(
                              selectedJob,
                              role
                            ) &&
                              data?.rows?.length && (
                                <Tab label="Job Details" value="1" />
                              )}
                            {checkForMyJobCandidate(selectedJob, role) &&
                              data?.rows?.length && (
                                <Tab label="Candidates" value="2" />
                              )}
                            {checkForMyJobInterview(selectedJob, role) &&
                              data?.rows?.length && (
                                <Tab label="Interviews" value="3" />
                              )}
                            {checkForAdminAndHiringLead(selectedJob, role) &&
                              data?.rows?.length && (
                                <Tab label="Shortlisted Candidates" value="4" />
                              )}
                            {checkForApprovedTab(selectedJob, role) &&
                              data?.rows?.length && (
                                <Tab
                                  label={
                                    RejectedCandidatesTabShow
                                      ? "Rejected Candidates"
                                      : "Approved Candidates"
                                  }
                                  value="5"
                                />
                              )}
                          </TabList>
                        </Box>

                        <TabPanel value="1" className="tabContainer__item">
                          {OpenRecruiterDetailPage ||
                          RecruiterDetailPageCheck?.recruiterPage === true ? (
                            <AssignedRecruiterDetails
                              setOpenRecruiterDetailPage={
                                setOpenRecruiterDetailPage
                              }
                              recruiterDetails={selectedJob.recruiter}
                              setRecruiterDetailPageCheck={
                                setRecruiterDetailPageCheck
                              }
                              selectedJob={selectedJob}
                            />
                          ) : (
                            <JobDetails
                              selectedJobData={selectedJob}
                              setOpenRecruiterDetailPage={
                                setOpenRecruiterDetailPage
                              }
                              joblistlength={data?.rows?.length}
                            />
                          )}
                        </TabPanel>

                        <TabPanel value="2" className="tabContainer__item">
                          {candidateViewType == "candidateDetail" ? (
                            <CandidatesDetails
                              candidateData={selectedCandidateData}
                            />
                          ) : candidateViewType == "candidateRecording" ? (
                            <CandidatesRecordings
                              candidateData={selectedCandidateData}
                              changeCandidateViewType={changeCandidateViewType}
                            />
                          ) : candidateViewType == "candidateFeedBack" ? (
                            <CandidatesFeedback
                              candidateData={selectedCandidateData}
                              changeCandidateViewType={changeCandidateViewType}
                            />
                          ) : (
                            <Candidates
                              changeCandidateViewType={changeCandidateViewType}
                              selectedJobData={selectedJob}
                            />
                          )}
                        </TabPanel>

                        <TabPanel value="3" className="tabContainer__item">
                          {interviewViewType == "interviewDetail" ? (
                            <InterviewsDetails
                              changeInterviewViewType={changeInterviewViewType}
                              interviewDetails={selectedInterviewData}
                            />
                          ) : interviewViewType == "interviewFeedback" ? (
                            <InterviewsFeedback
                              changeInterviewViewType={changeInterviewViewType}
                              interviewData={selectedInterviewData}
                              selectedJobData={selectedJob}
                            />
                          ) : (
                            <Interviews
                              jobData={selectedJob}
                              interviewType={myInterview}
                              changeInterviewViewType={changeInterviewViewType}
                            />
                          )}
                        </TabPanel>

                        <TabPanel value="4" className="tabContainer__item">
                          {candidateViewType == "candidateRecording" ? (
                            <CandidatesRecordings
                              candidateData={selectedCandidateData}
                              changeCandidateViewType={changeCandidateViewType}
                              fromShortlistedCandidates={true}
                            />
                          ) : (
                            <ShortlistedCandidates
                              selectedJob={selectedJob}
                              changeCandidateViewType={changeCandidateViewType}
                            />
                          )}
                        </TabPanel>

                        <TabPanel value="5" className="tabContainer__item">
                          <ApprovedCandidates
                            selectedJob={selectedJob}
                            RejectedCandidatesTabShow={
                              RejectedCandidatesTabShow
                            }
                            getShortlistScreen={getShortlistScreen}
                          />
                        </TabPanel>
                      </TabContext>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <PauseStopReopenJobModal
        openModal={OpenActionOnJobModal}
        closeModal={() => setOpenActionOnJobModal(false)}
        jobStatus={jobStatus}
        selectedJob={selectedJob}
        setOpenActionOnJobModal={setOpenActionOnJobModal}
        refreshJobListing={refreshJobListing}
        setOpenRecruiterDetailPage={setOpenRecruiterDetailPage}
        setResetJobModal={setResetJobModal}
        resetJobModal={resetJobModal}
      />
      {/* **************** Notes Code **************** */}
      <AddNotesModal
        openModal={OpenAddEditNotesModal}
        closeModal={handleCloseModal}
        setNotesTitle={setNotesTitle}
        NotesTitle={NotesTitle}
        setNotesText={setNotesText}
        NotesText={NotesText}
        handleOnAddEditNotes={handleOnAddEditNotes}
        EditNotesMode={EditNotesMode}
        shareWithArray={ShareWith}
        setSelectedShareWith={setSelectedShareWith}
        selectedShareWithArray={SelectedShareWith}
      />
      <AddTagsModal
        openModal={OpenAddTagModal}
        closeModal={() => setOpenAddTagModal(false)}
        fromJobSection={fromJobSection}
        tagData={selectedTagDetails}
        refreshPage={refreshJobListing}
        setRefreshJobDetails={setRefreshJobDetails}
        RefreshJobDetails={RefreshJobDetails}
      />
      {/* ************TAGS COMPONENTS****************** */}
      {OpenRemoveTagConfModal && (
        <ConfirmationModal
          closeModal={() => setOpenRemoveTagConfModal(false)}
          onSubmit={removeJobTag}
          heading={"Remove Tag!"}
          confirmationMessage={"Are you sure you want to remove this tag?"}
        />
      )}
      {removeJobTagRes?.meta?.status && (
        <SuccessModal
          closeModal={closeRemoveTagModal}
          sucessfullMsg={removeJobTagRes?.meta?.msg}
          Heading={"Tag Removed!"}
        />
      )}
      {removeJobTagRes?.meta?.status === false && (
        <ErrorModal
          errorMessage={removeJobTagRes?.meta?.msg}
          closeModal={closeRemovetagModal}
          footerVisible={true}
        />
      )}
    </>
  );
}

export default CustomerMyJobs;
