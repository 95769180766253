import React, { useState } from "react";
import SideBar from "@/components/layout/SideBar";
import MyProfileDetails from "@/pages/MyProfile/MyProfileDetails";
import MyProfileEdit from "@/pages/MyProfile/MyProfileEdit";

const MyProfile = () => {
  const [edit, setEdit] = useState(false);

  return (
    <>
      <div className="layoutContainer">
        <SideBar />
        <div className="layoutGrid layoutGrid--full">
          {!edit && (
            <MyProfileDetails
              editMode={() => {
                setEdit(true);
              }}
            />
          )}
          <div
            className={`layoutGrid__col layoutGrid--responsive ${
              edit ? "layoutGrid--show" : ""
            }`}
          >
            {edit && (
              <MyProfileEdit
                editMode={() => {
                  setEdit(false);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
