import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProtectedRoute from "@/routes/ProtectedRoute";
import DashboardRoute from "@/routes/DashboardRoute";
import SignUp from "@/components/signUp";
import SignIn from "@/components/SignIn";
import ForgotPassword from "@/components/ForgotPassword";
import ResetPassword from "@/components/ForgotPassword/ResetPassword";
import MyProfile from "@/pages/MyProfile";
import JobRequests from "@/pages/JobRequests";
import Candidates from "@/pages/Candidates";
import ChangePassword from "@/pages/ChangePassword";
import MyJobs from "@/pages/MyJobs";
import CustomerSignUp from "@/customer-pages/signUp/SignUp";
import CustomerMyJobs from "@/customer-pages/MyJobs/CustomerMyJobs";
import Interviews from "@/pages/Interviews";
import ThankYou from "@/pages/MyJobs/Interviews/ThankYou";
import { ScheduleMeeting } from "@/pages/MyJobs/Interviews/meeting";
import CustomerCompanyProfile from "@/customer-pages/CompanyProfile/CompanyProfile";
import CustomerInterviews from "@/customer-pages/Interviews";
import Dashboard from "@/customer-pages/Dashboard/Dashboard";
import RecruiterDashboard from "@/pages/Dashboard/Dashboard";
import Profile from "@/customer-pages/Profile/Profile";
import BillingPayments from "@/customer-pages/BillingPayments/BillingPayments";
import CustomerThankYou from "@/customer-pages/MyJobs/Candidates/ThankYou";
import BillingThankyouPage from "@/customer-pages/BillingPayments/Thankyou";
import EmployeeDetail from "@/customer-pages/Profile/EmployeeDetail";
import Settings from "@/pages/Settings";
//import SettingsIndex from "@/pages/Settings/settingIndex";
import PaymentIndex from "@/pages/Settings/Payments/";
import RecruiterBillingpayments from "@/pages/RecruiterBillingPayments/RecruiterBillingpayments";
import AddEmployee from "@/customer-pages/CompanyProfile/AddEmployee";
import CustomerPayment from "@/customer-pages/Payments";
import CustomerTags from "@/customer-pages/Tags/CustomerTags";

export default function MainRoutes() {
  return (
    <Router>
      <Routes>
        <Route
          exact={true}
          path="/"
          element={
            <DashboardRoute>
              <SignIn />
            </DashboardRoute>
          }
        />
        <Route
          exact={true}
          path="/signup"
          element={
            <DashboardRoute>
              <SignUp />
            </DashboardRoute>
          }
        />
        <Route
          exact={true}
          path="/signin"
          element={
            <DashboardRoute>
              <SignIn />
            </DashboardRoute>
          }
        />
        <Route
          exact={true}
          path="/forgot-password"
          element={
            <DashboardRoute>
              <ForgotPassword />
            </DashboardRoute>
          }
        />
        <Route
          exact={true}
          path="/create-password"
          element={
            <DashboardRoute>
              <ResetPassword />
            </DashboardRoute>
          }
        />
        <Route
          exact={true}
          path="/dashboard"
          element={
            <ProtectedRoute>
              <RecruiterDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/job-requests"
          element={
            <ProtectedRoute>
              <JobRequests />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/my-profile"
          element={
            <ProtectedRoute>
              <MyProfile />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/change-password"
          element={
            <ProtectedRoute>
              <ChangePassword />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/candidates"
          element={
            <ProtectedRoute>
              <Candidates />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/my-jobs"
          element={
            <ProtectedRoute>
              <MyJobs />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/interviews"
          element={
            <ProtectedRoute>
              <Interviews />
            </ProtectedRoute>
          }
        />
        <Route exact={true} path="/thankyou" element={<ThankYou />} />
        <Route
          exact={true}
          path="/meeting/:meetingId"
          element={<ScheduleMeeting />}
        />
        <Route
          exact={true}
          path="/settings/taxinfo"
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />

        <Route
          exact={true}
          path="/settings/payment"
          element={
            <ProtectedRoute>
              <PaymentIndex />
            </ProtectedRoute>
          }
        />

        {/* ******************* Customer start ******************* */}
        <Route
          exact={true}
          path="/customer-add-employee"
          element={
            <ProtectedRoute>
              <AddEmployee />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/customer/signup/:firstname/:lastname/:email/:role/:phonenumber"
          element={
            <DashboardRoute>
              <CustomerSignUp />
            </DashboardRoute>
          }
        />
        <Route
          exact={true}
          path="/customer-dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/customer-my-jobs"
          element={
            <ProtectedRoute>
              <CustomerMyJobs />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/customer-company-profile"
          element={
            <ProtectedRoute>
              <CustomerCompanyProfile />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/customer/detail/:candidateId"
          element={<CustomerMyJobs tabType="candidateDetail" />}
        />
        <Route
          exact={true}
          path="/customer/recording/:candidateId"
          element={<CustomerMyJobs tabType="candidateRecording" />}
        />
        <Route
          exact={true}
          path="/customer/feedback"
          element={<CustomerMyJobs tabType="candidateFeedBack" />}
        />
        <Route
          exact={true}
          path="/interview/detail"
          element={<CustomerMyJobs tabType="interviewDetail" />}
        />
        <Route
          exact={true}
          path="/interview/feedback"
          element={<CustomerMyJobs tabType="interviewFeedback" />}
        />
        <Route
          exact={true}
          path="/customer-interviews"
          element={
            <ProtectedRoute>
              <CustomerInterviews />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/customer-my-profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/customer-billing-payments"
          element={
            <ProtectedRoute>
              <BillingPayments />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/recruiter-billing-payments"
          element={
            <ProtectedRoute>
              <RecruiterBillingpayments />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/customer-thankyou"
          element={<CustomerThankYou />}
        />
        <Route
          exact={true}
          path="/payment-success"
          element={<BillingThankyouPage />}
        />
        <Route
          exact={true}
          path="/payment-cancel"
          element={<cancelPaymentPage />}
        />
        <Route
          exact={true}
          path="/customer-employee-detail/:employeeId"
          element={<EmployeeDetail />}
        />
        <Route
          exact={true}
          path="/settings/customer-payment"
          element={
            <ProtectedRoute>
              <CustomerPayment />
            </ProtectedRoute>
          }
        />
        <Route
          exact={true}
          path="/settings/tags"
          element={
            <ProtectedRoute>
              <CustomerTags />
            </ProtectedRoute>
          }
        />
        {/* ************************************* Customer end ********************************************* */}
      </Routes>
    </Router>
  );
}
